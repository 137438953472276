import { TextInput } from "components/forms/components"
import AddressP from "../components/AddressP"
import Alert from "../components/Alert"
import { useAddressStore } from "../state"
import { ChangeEvent, useState } from "react"
import useFetch from 'hooks/useFetch'
import { serializeToOriginalAddress, serializeVerificationFlags, serializeFromVerifiedAddress } from '../serializers'
import { VerifyAddressResponse } from '../types'
import { VERIFY_ADDRESS_ERROR } from "../constants"


export default function SubpremisePrompt() : JSX.Element {
  const [ address, setAddress ] = useAddressStore( state => [ state.address, state.setAddress ] )
  const { setCurrentView, setVerificationFlags, setVerifiedAddress, setSuggestionHighlights, setAddressWebConfirmed } = useAddressStore()

  const [ submitError, setSubmitError ] = useState<string>( `` )

  const {
    lazyFetch : submitAddress,
    isLoading : submitLoading
  } = useFetch<VerifyAddressResponse>( `${process.env.REACT_APP_VALIDATION_URL}/address`, {
    method: `POST`
  })

  const onContinue = async ( useWithoutApt : boolean | undefined ) => {
    setSubmitError( `` )
    const _address = {
      ...address,
      ...( useWithoutApt && {
        street2: ``
      })
    }
    setAddress( _address )

    const response = await submitAddress({ // Revalidate Address with Apt/Unit Number
      ...serializeToOriginalAddress( _address ),
      environment: process.env.REACT_APP_ENVIRONMENT,
      source: `aeroflowsleep`
    })

    if ( response?.meta?.status === `OK` && response?.data ) {
      const { data } = response
      setVerifiedAddress( serializeFromVerifiedAddress( data ) )
      // Save these variables to be used in the confirm view
      setVerificationFlags( serializeVerificationFlags( data ) )
      setSuggestionHighlights( data.suggestion_highlights )
      if ( data.prompt_for_subpremise ) return setCurrentView( `confirm` ) // Apt number still wasn't valid, send to confirm
      if ( data.suggest_validated_address ) return setCurrentView( `verify` )
      setAddressWebConfirmed( true ) // This lets internal users know that the address was verified by the verification api
      return setCurrentView( `complete` )
    }

    setSubmitError( VERIFY_ADDRESS_ERROR )
    setCurrentView( `invalid` )
  }

  const handleAptChange = ( e: ChangeEvent<HTMLInputElement> ) => {
    setAddress({
      ...address,
      street2: e.target.value
    })
  }


  return (
    <div className="border-4 p-3 rounded-xl flex flex-col items-center gap-6 max-w-md mx-auto">
      <Alert text={`Our records indicate this address might require an apt/unit number`} />
      <div>
        <AddressP address={address} className="text-center" shouldHideSubpremise />
        <p className="underline text-deepSleepBlue cursor-pointer" onClick={() => setCurrentView( `edit` )}>{`Change Address`}</p>
      </div>
      <div className="max-w-xs">
        <TextInput
          name="apt"
          label="Add Apt/Unit Number"
          labelClassName="text-sm font-light pb-1"
          required
          value={address.street2}
          onChange={handleAptChange}
        />
      </div>
      { submitError && <p className="text-error text-center">{submitError}</p> }
      <div className="w-full">
        {
          address?.street2?.length > 0 ? (
            <button className="btn btn-secondary" onClick={() => onContinue( false )} disabled={submitLoading}>{`Continue`}</button>
          ) : (
            <button className="btn btn-primary" onClick={() => onContinue( true )} disabled={submitLoading}>{`Continue without Apt/Unit`}</button>
          )
        }
      </div>
    </div>
  )
}