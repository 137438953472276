import {Alert, AddressP, Highlight} from "../components"
import { useAddressStore } from "../state"

export default function ErrorConfirmPrompt() {
  const { address, setCurrentView, setUserConfirmedAddress, setAddressWebConfirmed } = useAddressStore()

  const handleConfirm = () => {
    setUserConfirmedAddress( `original` )
    setAddressWebConfirmed( false ) // This lets internal users know that the address was unable to be verified by the verification api
    setCurrentView( `complete` )
  }

  return (
    <div className="border-4 p-3 rounded-xl flex flex-col items-start gap-6 max-w-md mx-auto">
      <Alert />
      <button className="btn btn-primary" onClick={() => setCurrentView( `edit` )}>{`Go Back and Change Address`}</button>
      <p className="uppercase -mb-4"><Highlight>{`You entered:`}</Highlight></p>
      <AddressP address={address} />
      <button className="btn btn-secondary" onClick={handleConfirm}>{`Use this Address`}</button>
    </div>
  )
}