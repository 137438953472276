import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from
} from '@apollo/client'
import { RestLink } from 'apollo-link-rest'
import { onError } from "@apollo/client/link/error"
import { RetryLink } from '@apollo/client/link/retry'
import * as Sentry from "@sentry/react"

import { filterCache } from 'utils/filterCache'
import { networkErrorVar } from 'apollo'
import { reportToSentry } from 'utils/reportToSentry'
import { clearToken, getToken } from 'stores/tokenStore'

// @NOTE RestLink package above is still in beta, some of the rest links configured below have been switched over to plain old vanilla fetch requests at the level of component usage.

// In Memory Cache
// https://www.apollographql.com/docs/react/caching/cache-configuration/
const cache = new InMemoryCache({
  typePolicies: {
    Menu: {
      keyFields: [ `menu_id` ]
    },
    Customer: {
      keyFields: [ `email` ]
    },
    CustomerAddressRegion: {
      keyFields: [ `region_id` ]
    },
    CmsPage: {
      keyFields: [ `identifier` ]
    },
    MagentoPatientData: {
      keyFields: [ `patient_id` ]
    }
  }
})

// Apollo links

// Apollo Rest Link - https://www.apollographql.com/docs/react/api/link/apollo-link-rest
const restLink = new RestLink({
  mode: `cors`,
  endpoints: {
    appServer: {
      uri: process.env.REACT_APP_APP_SERVER_API_URL
    },
    magentoV1: {
      uri: `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/rest/V1`,
      // Having to transform responses from magento because
      // the format it returns data in cannot be queried through graphql
      responseTransformer: async ( response, typeName ) => {
        const json = await response.json()

        if ( !json ) return response

        const statusCode = json.find( element => { return typeof element === `number` })
        const responseBody = json.find( element => { return typeof element === `object` })

        // This endpoint doesn't return a status code in the response data array like the others
        if ( typeName === `MagentoPatientDataPayload` ) return {
          data: responseBody
        }

        // The Mangeto endpoints hit the app server, and some of them return the response status codes
        // from the app server in their response bodies.
        // Reporting errors to Sentry if the app server gives a bad repsonse, but Magento doesn't
        if ( ![ 200, 201, 400 ].includes( statusCode ) ) {
          Sentry.withScope( scope => {
            scope.setContext( `Request Details`, {
              url: response.url,
              appServerResponseBody: json,
              response
            })
            scope.setContext( `Apollo Cache`, JSON.stringify( filterCache( cache?.data?.data ) ) )
            Sentry.captureException( new Error( `Bad response from appServer api: ${response.url}` ) )
          })
        }

        if ( statusCode === 400 ) {
          return {
            errors: responseBody
          }
        }

        return responseBody
      }
    },
    lambdaAuthnetPayments: {
      uri: process.env.REACT_APP_LAMBDA_AUTHNET_PAYMENTS_URL
    }
  },
  customFetch: async ( uri, options ) => {
    const response = await fetch( uri, options )

    // If the response from Magento was a 401 Authentication error clear the token from local storage
    if ( uri.includes( process.env.REACT_APP_MAGENTO_2_BASE_URL ) && response?.status === 401 ) Storage.clearToken()

    // If the response was any status code other than the ones listed below, notify Sentry
    if ( ![ 200, 201, 400, 401 ].includes( response?.status ) ) {
      Sentry.withScope( scope => {
        scope.setContext( `Request Details`, {
          url: response.url,
          response
        })
        scope.setContext( `Apollo Cache`, JSON.stringify( filterCache( cache?.data?.data ) ) )
        Sentry.captureException( new Error( `Bad response from rest api: ${response.url}` ) )
      })
    }

    // If response from the api is a 400, change the status code to 200 and return anyway
    // so that errors can be read from the body without Apollo throwing Exceptions
    if ( response.status === 400 ) {
      const body = await response.json()

      return new Response( JSON.stringify({
        errors: body?.data
      }), {
        status: 200,
        ok: true,
        headers: response.headers
      })
    }

    return response
  },
  uri: process.env.REACT_APP_APP_SERVER_API_URL // Fallback uri
})

// https://www.apollographql.com/docs/react/api/link/introduction/
const authLink = new ApolloLink( ( operation, forward ) => {
  const token = getToken()
  const headers = {
    Store: `aeroflowsleep_english`,
    ...( token && {
      Authorization: `Bearer ${token}`
    })
  }

  operation.setContext({
    headers
  })

  return forward( operation )
})

// Error Link, universal handling of network/graphql errors here
// https://www.apollographql.com/docs/react/api/link/apollo-link-error/
const errorLink = onError( ({graphQLErrors, networkError, operation, response}) => {
  if ( graphQLErrors ) {
    graphQLErrors.forEach( ({message, locations, path, extensions}) => {
      const graphQLErrorMessage = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify( locations )}, Path: ${path}, Category: ${extensions.category}`

      if ( message !== `The current customer isn't authorized.` && !message.includes( `The account sign-in was incorrect or your account is disabled temporarily` ) ){
        // we revoke tokens as part of normal server-side configs, so only report to sentry if it's not unauth request
        reportToSentry( `GraphQL Error - ${message}`, {
          graphQLErrorMessage
        })
      }

      if ( extensions.category === `graphql-authentication` || extensions.category === `graphql-authorization` ) {
        clearToken()
      }
    })
  }

  if ( networkError ) {
    let extendedNetworkError = new Error( `[Network error]: ${networkError}` )
    extendedNetworkError.response = response
    extendedNetworkError.operation = operation

    networkErrorVar( extendedNetworkError )
  }
})

// Retry Link
// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = new RetryLink({
  attempts: {
    max: 3 // default was 5,
  }
})

// Terminating Link, must be last in the link chain
// https://www.apollographql.com/docs/react/api/link/apollo-link-http/
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/graphql`
})

export const apolloClient = new ApolloClient({
  uri: `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/graphql`,
  fetchOptions: {
    mode: `cors`
  },
  cache,
  link: from( [ restLink, authLink, errorLink, retryLink, httpLink ] )
})
