import { doctorStepImg, maskStepImg, machineStepImg, suppliesStepImg } from "resources/images"
import { SNL_MACHINE_PATH, SNL_MASK_PATH, SNL_TUBING_PATH, pageNames, SNL_COLLECTION_PATH } from "../constants"

export const stepLauncherCardContent = [
  {
    "cardTitle": `Help us identify your current machine`,
    "stepCardButton": `machine`,
    "cardDetails": `Brand, model, humidifier preference`,
    "cardImg": machineStepImg,
    "clickPath": SNL_MACHINE_PATH
  },
  {
    "cardTitle": `Help us identify your current mask`,
    "stepCardButton": `mask`,
    "cardDetails": `Preferred brand, style, model, fit`,
    "clickPath": SNL_MASK_PATH,
    "cardImg": maskStepImg
  },
  {
    "cardTitle": `Identify your supplies`,
    "stepCardButton": `accessories`,
    "cardDetails": `Preferred tubing and other supplies`,
    "cardImg": suppliesStepImg,
    "clickPath": SNL_TUBING_PATH
  },
  {
    "cardTitle": `Identify your doctor`,
    "stepCardButton": `doctor's`,
    "cardDetails": `Enter your doctor's last name and zip code`,
    "clickPath": SNL_COLLECTION_PATH,
    "cardImg": doctorStepImg,
    "finishLineInput":  [ `addressAcceptedFlag`, `doctorAcceptedFlag` ]
  }
]

/** Returns an array of all the pathNames used by the stepLauncherCards
 * @static
 * @param {Array} StepLauncherCardContent : Array<Object<{clickPath: string}>
 * @returns {Array<String>} StepLaunchPaths : Array<string>
 */
export const getStepLaunchPaths = ( stepLauncherCards ) => {
  return stepLauncherCards.map( card => {
    return card.clickPath
  })
}

/**
 *
 * @returns {Array<number>} the location of each stepLauncher path within the pageName array.
 */
export const getStepIndexes = ( ) => {
  const stepArray = getStepLaunchPaths( stepLauncherCardContent )

  return stepArray.map( stepLaunch => {
    return pageNames.findIndex( p => { return p === stepLaunch })
  })
}
