import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useState, useEffect } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import DoctorResult from '../types/DoctorResult'
import { searchDoctorFetch } from '../utils/utils'
import { useDoctorInput } from './state'
import { useDebounce } from './useDebounce'

type DoctorSearchReturn ={
  searchResults: DoctorResult[] | null;
  searchLoading: boolean;
  searchError: string;
}

function useDoctorSearch( searchTerm: string ) : DoctorSearchReturn {
  const [ searchResults, setSearchResults ] = useState<DoctorResult[] | null>( null )
  const [ searchLoading, setSearchLoading ] = useState<boolean>( false )
  const [ searchError, setSearchError ] = useState<string>( `` )
  const { range, zipCode } = useDoctorInput()[0]


  const defaultError = `Oops... we encountered an issue searching for your doctor. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if this issue persists.`

  useEffect( () => {
    setSearchError( `` )
    setSearchResults( null )

    if ( searchTerm.length >= 2 ) {
      setSearchLoading( true )
      debounce({
        searchTerm,
        zipCode,
        range
      })
    } else {
      setSearchError( `` )
      setSearchLoading( false )
    }
  }, [ searchTerm, range, zipCode ] )

  const searchDoctor = ({ searchTerm, zipCode, range } : { searchTerm: string; zipCode: string; range: number }) => {
    searchDoctorFetch( zipCode, searchTerm, range )
      .then( data => {
        setSearchLoading( false )
        if ( data?.meta?.status === `OK` && data?.data ) return setSearchResults( data.data )
        setSearchError( defaultError )

        return reportToSentry( new Error( `Doctor Search: Error looking up doctor` ), {
          searchTerm,
          range,
          zipCode
        })

      })
      .catch( ( error: Error ) => {
        if ( error.name !== `AbortError` ) {
          setSearchLoading( false )
          setSearchError( defaultError )
          reportToSentry( new Error( `Doctor Search: Error looking up doctor`, {
            cause: error
          }), {
            searchTerm,
            range,
            zipCode
          })
        }
      })
  }

  const debounce = useDebounce<{ searchTerm: string; zipCode: string; range: number }>({
    executeFunction: searchDoctor
  })

  return {
    searchResults,
    searchLoading,
    searchError
  }
}

export default useDoctorSearch