import React, { useState } from 'react'
import { Builder } from '@builder.io/react'
import { HeroLinkItem } from './HeroLinkItem'
import { SlideToggleContent } from 'components/SlideToggle'
import { icoPlusBlue, icoMinusBlue } from 'resources/images'
import { NavBlockTypes } from '../../types'

export const CollapsibleBar = ({blockTitle, mobileNavBarIcon, blockUrl, blockLinks} : NavBlockTypes ) : JSX.Element => {
  const [ isOpen, setIsOpen ] = useState( false )

  const handleNavigate = () => {
    // If there is a blockUrl and no blockLinks, navigate to the blockUrl
    if ( blockUrl && blockLinks?.length < 1 ) {
      window.location.href = blockUrl
    }
  }

  const handleSlideToggle = () : void => {
    setIsOpen( !isOpen )
  }

  return (
    <div className="bg-white rounded-2xl mt-4 overflow-hidden" onClick={handleNavigate}>
      <div onClick={handleSlideToggle} className="p-[18px] relative cursor-pointer">
        <div className="flex items-center ml-[39px]">
          {mobileNavBarIcon &&
            <div className="bg-[#bbebfc] rounded-tl-2xl min-h-full max-w-14 w-full flex justify-center items-center py-[19px] absolute left-0">
              <img src={mobileNavBarIcon} alt={blockTitle}></img>
            </div>}
          {blockTitle &&
            <div className="flex grow px-2">
              <h3 className="text-aeroflowNavy text-xl font-normal font-inter">{blockTitle}</h3>
            </div>}
          {blockLinks?.length > 0 &&
            ( <div>
              <img
                src={isOpen ? icoMinusBlue : icoPlusBlue}
                width="20"
                height={isOpen ? `100` : `1`}
                alt="Open/Close Menu"
              />
            </div>
            )}
        </div>
      </div>
      {blockLinks?.length > 0 && (
        <SlideToggleContent isVisible={isOpen}>
          <div className="flex flex-col gap-1">
            {blockLinks?.map( ( blockLink ) => {
              return (
                <HeroLinkItem
                  key={blockLink.ctaName}
                  ctaName={blockLink.ctaName}
                  mobileCtaImage={blockLink.mobileCtaImage}
                  ctaDetails={blockLink.ctaDetails}
                  buttonText={blockLink.buttonText}
                  url={blockLink.url}
                />
              )
            })}
          </div>
        </SlideToggleContent>
      )}
    </div>
  )
}

Builder.registerComponent( CollapsibleBar, {
  name: `CollapsibleBar`
})

export default CollapsibleBar