import React from 'react'
import PropTypes from 'prop-types'
// import { SelectInput } from 'components/forms/components'
import CheckIcon from './CheckIcon'
import { maskImages } from 'modules/sleepNewLeads/constants'

const MaskModelSelector = ({
  heading,
  name,
  maskNames,
  selectedValue,
  onChange,
  // defaultLabel = `Select a Mask`,
  showOnMobile
}) => {
  const availableImages = maskImages.filter( ({ mask }) => { return ( maskNames || [] ).includes( mask ) })

  if ( !availableImages || !availableImages.length ) return null

  return (
    <>
      {showOnMobile && <div className="mt-4">
        <div className="text-center text-lg mb-6">{heading}</div>
        <div className="grid grid-cols-2 gap-6">
          {availableImages.map( ({ mask, imgSrc }) => {
            return (
              <div key={mask}>
                <div
                  className={`cursor-pointer border flex items-center relative ${mask === selectedValue ? `border-deepSleepBlue` : `border-lightGray`}`}
                  onClick={() => {
                    return onChange({
                      target: {
                        name,
                        value: mask
                      }
                    })
                  }}
                >
                  {mask === selectedValue && <CheckIcon />}
                  <img
                    src={imgSrc}
                    className="w-full h-auto"
                    alt={mask}
                  />
                </div>
                <p
                  onClick={() => {
                    return onChange({
                      target: {
                        name,
                        value: mask
                      }
                    })
                  }}
                  className="cursor-pointer text-sm font-light"
                >
                  {mask}
                </p>
              </div>
            )
          })}
        </div>
      </div>}
    </>
  )
}

MaskModelSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  maskNames: PropTypes.array,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  defaultLabel : PropTypes.string,
  showOnMobile: PropTypes.bool
}

export default MaskModelSelector
