import React from 'react'
import { snlBackButtonImg } from 'resources/images'

function SNLBackButton({ handleBack, hide = false } : { hide?: boolean, handleBack: () => void }) : JSX.Element | null {

  if ( hide ) return null

  return (
    <div className="fixed bottom-5 left-5">
      <button onClick={handleBack}>
        <img
          src={snlBackButtonImg}
          alt="snl back button"
          width="25px"
          height="25px"
        />
      </button>
    </div>
  )
}

export default SNLBackButton