import { useEffect, useState } from 'react'
import { getPatientDoctor } from 'utils/patientInfoApi'
import { reportToSentry } from 'utils/reportToSentry'
import { Formatters } from 'components/forms/components'
import { useDoctor } from './state'
import DoctorInfo from '../types/DoctorInfo'
import { getAuthHeader } from 'utils/auth/helpers'
import { getNewDoctorSessionStorage } from '../utils/utils'

function useRetrieveDoctor( existingDoctor?: DoctorInfo | null, onExistingDoctor?: () => void ) : boolean {

  const [ loading, setLoading ] = useState<boolean>( false )

  const setDoctor = useDoctor()[1]

  useEffect( () => {
    const controller = new AbortController()

    /*
      temp fix -- updating doctor info takes a while to update in the db,
      using getNewDoctorSessionStorage to make sure the doctor info persist throughout the session
    */
    if ( existingDoctor ) setDoctor( getNewDoctorSessionStorage() || existingDoctor )
    else {
      setLoading( true )
      getPatientDoctor( getAuthHeader() ).then( currentDoctor => {
        if ( currentDoctor ) {
          if ( onExistingDoctor && currentDoctor?.first_name && currentDoctor?.last_name && currentDoctor?.phone ) onExistingDoctor()
          setDoctor( getNewDoctorSessionStorage() || {
            firstName: currentDoctor?.first_name ?? ``,
            lastName: currentDoctor?.last_name ?? ``,
            phone: currentDoctor?.phone ? Formatters.phone( currentDoctor.phone ) : ``,
            npi: currentDoctor?.npi ?? ``
          })
        }
        setLoading( false )
      })
        .catch( error => {
          if ( error.name !== `AbortError` ) {
            setLoading( false )
            reportToSentry( new Error( `error getting patient doctor`, {
              cause: JSON.stringify( error ?? `` )
            }) )
          }
        })
    }

    return () => { controller.abort() }
  }, [] )

  return loading
}

export default useRetrieveDoctor