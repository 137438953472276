import { useState, useEffect, useRef } from 'react'
import { StatePayerKey, StatePayer, InsurancePayersReturn } from './useInsurancePayers.d'
import { fetchInsurancePayersByState } from './api'
import { reportToSentry } from 'utils/reportToSentry'

export default function useInsurancePayers( state: string, isSiteOffline: boolean ) : InsurancePayersReturn {

  const shouldRefetchRef = useRef( true )

  const [ errors, setErrors ] = useState<string[]>( [] )
  const [ payers, setPayers ] = useState<StatePayer[]>( [] )

  const fetchPayers = async () => {
    if ( shouldRefetchRef.current && state ) {
      try {
        if ( isSiteOffline ) {
          const backupPayersJSON = await import( `./payers.json` )
          const backupPayers: Record<StatePayerKey, StatePayer[]> = {
            ...backupPayersJSON.default
          }
          setPayers( [ ...backupPayers[ state ] ] )
        } else {
          fetchInsurancePayersByState( state ).then( response => {
            setErrors( response?.errors?.map( ( error : Record<string, unknown> ) => { return error.message }) as string[] ?? [] )
            setPayers( [ ...( response?.data ?? [] ) ] )
          })
        }
      } catch ( error ) {
        console.error( `Payers failed to fetch`, error )
        reportToSentry( new Error( `Payers failed to fetch` ), {
          errorMessage: JSON.stringify( error )
        })
      }

      shouldRefetchRef.current = false
    }
  }

  useEffect( () => {
    fetchPayers()
  }, [ state, isSiteOffline ] )

  return {
    payers,
    payersErrors: errors.length ? errors : null,
    payersLoading: shouldRefetchRef.current,
    refetchPayers: () => { shouldRefetchRef.current = true }
  }
}