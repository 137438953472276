import tailwindColors from 'config/tailwindColors'
import React from 'react'

export const Frame = () => {
  return (
    <svg viewBox="0 0 100 100" width="300px" className="absolute">
      <path
        d="M23,5 L13,5 L13,15" fill="none" stroke={tailwindColors.deepSleepBlue}
        strokeWidth="0.8"
      />
      <path
        d="M13,65 L13,75 L23,75" fill="none" stroke={tailwindColors.deepSleepBlue}
        strokeWidth="0.8"
      />
      <path
        d="M85,65 L85,75 L75,75" fill="none" stroke={tailwindColors.deepSleepBlue}
        strokeWidth="0.8"
      />
      <path
        d="M87,15 L87,5 L77,5" fill="none" stroke={tailwindColors.deepSleepBlue}
        strokeWidth="0.8"
      />
    </svg>
  )
}

export default Frame