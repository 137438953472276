/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect, useRef } from 'react'
import { useSpring, animated as a } from "react-spring"
import { icoCheckAob } from 'resources/images'
import styled from 'styled-components'

type LabelText = {
  left: string,
  right: string
}

interface IPercentageBar {
  percentFilled: number,
  labelText?: LabelText,
  showPercentageLabels?: boolean,
  showLabelText?: boolean
}

function PercentageBar({percentFilled, labelText, showPercentageLabels, showLabelText = true} : IPercentageBar ) : JSX.Element {

  const [ fullBarWidth, setFullBarWidth ] = useState<number>( 0 )
  const [ filledWidth, setFilledWidth ] = useState<number>( 0 )
  const [ displayCheck, setDisplayCheck ] = useState<boolean>( false )

  const fullBarRef = useRef<HTMLDivElement>( null! )
  const paidRef = useRef<HTMLParagraphElement>( null! )

  const filledBarStyling = useSpring({
    to: {
      width: fullBarWidth !== 0 ? `${filledWidth}px` : `0px`,
      height: `32px`
    },
    config: {
      duration: 1500
    },
    onRest: () => {
      if ( !displayCheck && percentFilled === 100 ) {
        setDisplayCheck( true )
      }
    }
  })

  const checkIconStyling = useSpring({
    opacity: displayCheck ? 1 : 0,
    config: {
      duration: 300
    }
  })

  useEffect( () => {
    window.addEventListener( `resize`, handleWindowResize )
    setFullBarWidth( fullBarRef?.current?.offsetWidth ?? 0 )

    return () => {
      window.removeEventListener( `resize`, handleWindowResize )
    }
  }, [] )

  useEffect( () => {
    setFilledWidth( Math.round( fullBarWidth * ( percentFilled / 100 ) ) )
    setDisplayCheck( percentFilled === 100 )
  }, [ fullBarWidth, percentFilled ] )

  const handleWindowResize = () => {
    setDisplayCheck( false )
    setFullBarWidth( fullBarRef?.current?.offsetWidth ?? 0 )
  }

  const RightLabelStyle = styled.p`
    position: absolute;
    left: ${paidRef?.current?.clientWidth > filledWidth - 20 ? paidRef?.current?.clientWidth + 5 : filledWidth - 20}px;
    top: 0px;
    font-weight: 300;
  `

  return (
    <>
      <div className="bg-lightGray rounded-lg w-full overflow-hidden" ref={fullBarRef}>
        <a.div
          style={filledBarStyling}
          className={`bg-deepSleepBlue rounded-l-md flex relative ${displayCheck && `justify-end`}`}
        >
          {
            percentFilled !== 100 && showPercentageLabels &&
            <div className="flex justify-center items-center">
              <p className="absolute text-white right-2">{`${percentFilled}%`}</p>
              {
                percentFilled < 90 &&
                <p className="absolute -right-10 text-deepSleepBlue">{`${100 - percentFilled}%`}</p>
              }
            </div>
          }
          {
            displayCheck &&
            <a.img
              src={icoCheckAob}
              className="object-contain rounded-full bg-white m-auto mr-1"
              style={checkIconStyling}
              width={26}
              height={26}
            />
          }
        </a.div>
      </div>
      {
        percentFilled !== 100 && showLabelText && labelText &&
        <div className="w-full relative text-graphite mb-5">
          <p
            className="absolute left-0 font-light"
            ref={paidRef}
          >
            {labelText.left}
          </p>
          <RightLabelStyle>{labelText.right}</RightLabelStyle>
        </div>
      }
    </>
  )
}

export default PercentageBar