import React, { useEffect, useState } from 'react'
import { Heading } from 'components/resupply2'
import { InsuranceBlock, reducePayerObject } from 'components/account/Insurance'
import { PhoneLink } from 'components/ALinks'
import { PHONE } from 'components/footerBar/constants'
import { CheckboxInput } from 'components/forms/components'
import { icoAlert } from 'resources/images'
import { useInsuranceInfoState, useResupplySetting } from '../hooks'
import { PagePropsAbstract } from 'components/pageNavigator'
import { clearedPayer } from 'components/account/Insurance/utils'
import { useAccountStore } from '../../accountV2/state'

type InsuranceProps = PagePropsAbstract
export default function Insurance( props : InsuranceProps ) : JSX.Element {

  const [ resupplySettings, setResupplySettings ] = useResupplySetting()
  const { insurance } = useAccountStore()
  const [ insuranceInfo, setInsuranceInfo ] = useInsuranceInfoState()

  const [ confirmed, setConfirmed ] = useState( false )
  const [ primaryCleared, setPrimaryCleared ] = useState( false )

  useEffect( () => {
    if ( !insurance?.data?.payers ) {
      // we did not get payer data back
      setInsuranceInfo( Object.assign({}, insuranceInfo, {
        primary: clearedPayer,
        secondary: clearedPayer
      }) )
    } else if ( insurance?.data?.payers && ( !insuranceInfo.primary || !insuranceInfo.secondary ) ) {
      // make sure payer data is object
      if ( typeof insurance?.data?.payers !== `object` || !Object.keys( insurance?.data?.payers ).length ) {
        setInsuranceInfo( Object.assign({}, insuranceInfo, {
          primary: clearedPayer,
          secondary: clearedPayer
        }) )
      } else {
        setInsuranceInfo( Object.assign({}, insuranceInfo, {
          primary: reducePayerObject( insurance?.data?.payers, 1 ) as any,
          secondary: reducePayerObject( insurance?.data?.payers, 2 ) as any
        }) )
      }
    }

  }, [ insurance?.data?.payers ] )

  useEffect( () => {
    if ( primaryCleared && insuranceInfo.secondary?.name ) {
      setInsuranceInfo( Object.assign({}, insuranceInfo, {
        primary: insuranceInfo.secondary,
        secondary: clearedPayer
      }) )
      setPrimaryCleared( false )
    }
    if ( primaryCleared && !insuranceInfo.secondary?.name ) setInsuranceInfo( Object.assign({}, insuranceInfo, {
      primary: clearedPayer
    }) )
  }, [ primaryCleared ] )


  const handleCheck = () => {
    // TODO: ensure that at least one insurance is complete
    setConfirmed( !confirmed )
  }

  const handleNoSecondary = () => {
    setInsuranceInfo( Object.assign({}, insuranceInfo, {
      secondary: clearedPayer
    }) )
    setResupplySettings( Object.assign({}, resupplySettings, {
      updateSecondaryPayerStatus: false
    }) )
  }

  const primaryUpdateRequired = resupplySettings.updatePrimaryPayerStatus === `Required` && !props.myAccount
  const secondaryUpdateRequired = resupplySettings.updateSecondaryPayerStatus === `Required` && !props.myAccount

  const headingTitle = primaryUpdateRequired || secondaryUpdateRequired ? `Update Insurance Information Required` : `Insurance Details`
  const headingDescription = primaryUpdateRequired|| secondaryUpdateRequired ? `In order to process your supplies through insurance, new insurance information is needed. Please provide your current insurance information below.` : `Please confirm your insurance information.`

  return (
    <div className={`mx-auto max-w-sm mb-${props.myAccount ? `5` : `20`}`}>
      {
        !props.myAccount &&
        <Heading title={headingTitle} description={headingDescription} />
      }

      {primaryUpdateRequired && (
        <div className="bg-lightGray p-4 flex gap-2 items-start">
          <img
            className="w-7 h-7" src={icoAlert}
            height="28px" width="28px"
            alt="alert icon"
          />
          <div className="font-extralight w-64">
            <p>{`Your current primary insurance needs to be updated`}</p>
            <p>{`${insurance?.data?.payers?.payer1_name ?? ``} ${insurance?.data?.payers?.payer1_hic ?? ``}`}</p>
          </div>
        </div>
      )}

      { insurance?.data?.payers ? (
        <><div>
          <InsuranceBlock
            key="primary"
            payerType="Primary"
            payer={insuranceInfo.primary}
            regions={insurance?.data?.regions}
            useModal={!primaryUpdateRequired}
            initialEditView={primaryUpdateRequired}
            setPrimaryCleared={setPrimaryCleared}
            myAccount={props.myAccount}
          />
        </div>

        {secondaryUpdateRequired && (
          <>
            <div className="bg-lightGray p-4 flex gap-2 items-start">
              <img
                className="w-7 h-7" src={icoAlert}
                height="28px" width="28px"
                alt="alert icon"
              />
              <div className="font-extralight w-64">
                <p>{`Your current secondary insurance needs to be updated`}</p>
                <p>{`${insurance?.data?.payers?.payer2_name ?? ``} ${insurance?.data?.payers?.payer2_hic ?? ``}`}</p>
              </div>
            </div>
            <div
              className="flex cursor-pointer bg-lightGray p-5"
              onClick={handleNoSecondary}
            >
              <CheckboxInput
                name="no_secondary"
                className="cursor-pointer mr-3"
                value={!secondaryUpdateRequired}
                onChange={() => { return }}
                id="no_secondary"
                reference={undefined}
                label={undefined}
              />
              <p>{`I no longer have a secondary insurance`}</p>
            </div>
          </>
        )}

        <InsuranceBlock
          key="secondary"
          payerType="Secondary"
          payer={insuranceInfo.secondary}
          regions={insurance?.data?.regions}
          useModal={!secondaryUpdateRequired}
          initialEditView={secondaryUpdateRequired}
          myAccount={props.myAccount}
        />
        </>
      ) : (
        <p className="text-error">
          {`There was an error retrieving your insurance details... Please give customer support a call at `}
          <PhoneLink displayNumber={PHONE} textClassName="inline-block whitespace-nowrap" />
          {`.`}
        </p>
      )}
      {
        !props.myAccount &&
        <>
          <br></br>
          <div
            className="flex cursor-pointer mx-auto justify-center"
            onClick={handleCheck}
          >
            <CheckboxInput
              name="confirmation"
              label=""
              value={confirmed}
              onChange={() => { return }}
              id={`confirmationCheckbox`}
              reference={undefined}
            />
            <p className="font-light text-lg ml-3">{`I confirm that this information is correct.`}</p>
          </div>
          <br></br>
          <div className="max-w-xs w-full mx-auto mt-10 mb-5 flex flex-col items-center gap-4">
            <button className="btn-secondary" disabled={!confirmed || primaryUpdateRequired || secondaryUpdateRequired} onClick={() => { return props.nextPage() }}>{`Next`}</button>
            <button className="btn-primary" onClick={props.prevPage}>{`Back`}</button>
          </div>
          {
            ( primaryUpdateRequired && secondaryUpdateRequired ) || ( primaryUpdateRequired && !insuranceInfo.secondary?.name ) &&
              <p
                className="text-lg underline cursor-pointer text-deepSleepBlue text-center mt-10 font-light hover:opacity-80"
                onClick={props.nextPage}
              >
                {`I no longer have insurance ${ props.myAccount ? `, remove from my account.` : `.`}`}
              </p>
          }
        </>
      }

    </div>
  )
}