import React from 'react'
import { useGetInstallmentOptions } from "modules/aobPayments/hooks/useGetAobSegmentedCopy"
import ToggleContent from 'utils/ToggleContent'
import Tooltip from 'components/tooltip'
import { icoInfoAob } from 'resources/images'

const PapIntakePriceEstimateDetails = () : JSX.Element => {
  const { installmentAmount, numberOfMonths } = useGetInstallmentOptions()
  return (
    <div className="py-5 text-left">
      <p className="pb-3"><strong>{`READ ME - Transparency with billing and other important information`}</strong></p>
      <p>{`At Aeroflow, we provide transparent billing information based on your insurance. Below is your personalized and`} <strong>{`estimated`}</strong> {`breakdown of how your insurance pays for your new PAP machine and supplies.`}</p>
      <ol className="pt-2 pb-6 list-decimal text-base font-normal pl-10">
        <li className="pb-6 text-graphite">
          <div>
            <u>{`PAP Machine`}</u>{` - Aeroflow is required by your Insurance company to bill your new PAP machine as a “rental." `}
            {installmentAmount && numberOfMonths ?
              <>
                {` Based on your insurance plan, your`} <strong>{`estimated`}</strong> {`rental payment will be $${installmentAmount} per month over a ${numberOfMonths} month period. This amount is calculated based 
                  on what is left over after your insurance has provided their maximum coverage. Your first payment today will include the cost of your first rental month for the machine. `
                }
              </> :
              `Please contact us or check back later for details on the installment terms and amounts. Your first payment today will include the cost of your first rental month for the machine. `
            }
            <span className="inline-block">
              <Tooltip
                tooltipContent={
                  <p className="text-graphite">
                    {`Insurance does it this way to manage costs and ensure patient compliance. This method spreads out expenses through installments and gives you 90 days to verify usage and have a follow-up appointment with your physician.`}
                  </p>
                }
              >
                <div className="flex items-center">
                  <p>{`Why is it done this way?`}</p>
                  <img
                    src={icoInfoAob}
                    alt="ico-info"
                    className="px-2 hover:opacity-80 cursor-pointer object-contain"
                    width={37}
                  />
                </div>
              </Tooltip>
            </span>
          </div>
        </li>
        <li>
          <u>{`PAP Supplies`}</u>{` - Aeroflow is required by your Insurance company to bill your new PAP supplies as purchase items (not a rental). Insurance coverage for PAP supplies varies.`}
          <ToggleContent>
            <p>{`Some plans cover the full cost, while others require the patient to pay a portion of the cost (copayments or deductibles). You can see your full`} <strong>{`estimated`}</strong> {`breakdown of your first supply order in the “Order Summary Details” section below.`}</p>
          </ToggleContent>
        </li>
      </ol>

      <div className="pb-6">
        <div className="text-base font-normal">
          {`To ensure ongoing claim processing, you must achieve`} <strong>{`“compliance”`}</strong> {`within 90 days of receiving your machine and maintain it thereafter. `}
          <span className="inline-block">
            <Tooltip
              tooltipContent={
                <div className="text-graphite">
                  <p className="mb-3">{`To pass pap compliance insurance requires 2 things:`}</p>
                  <ol className="list-decimal pl-10">
                    <li className="pb-3"><p>{`You must use your PAP machine for 21 out of 30 consecutive nights for a minimum of 4 hours per night.`}</p></li>
                    <li className="pb-3"><p>{`Obtaining notes from your doctor in a follow up appointment that show you are using your machine and benefiting from it.`}</p></li>
                  </ol>
                </div>
              }
            >
              <div className="flex items-center text-center">
                <p className="text-light">{`What does “compliance” entail?`}</p>
                <img
                  src={icoInfoAob}
                  alt="ico-info"
                  className="px-2 hover:opacity-80 cursor-pointer object-contain"
                  width={37}
                />
              </div>
            </Tooltip>
          </span>
        </div>
      </div>
      <p>{`IMPORTANT NOTE: `}{`Please be aware that if your deductible resets or insurance coverage changes, you may be susceptible to copayment or deductible costs in the future.`}</p>
    </div>
  )
}

const PapIntakePaymentDetails = () : JSX.Element => {
  return (
    <>
      <p className="md:text-lg text-center font-light max-w-2xl">{`Your payment amounts displayed below are the estimated costs for your order based on how your plan usually covers supplies.`}</p>
      <p className="md:text-lg text-center font-light max-w-2xl">{`Your items will be scheduled to ship once payment is received and your clinical setup appointment is scheduled. Upon completion, your order will be scheduled to ship 7-10 days prior to the date of your clinical setup appointment.`}</p>
    </>
  )
}

const PapIntakeUpfrontPaymentDetails = () : JSX.Element => {
  return (
    <>
      <p className="md:text-lg text-center font-light max-w-2xl">{`This upfront payment covers the estimated out of pocket cost you owe through insurance for your first round of supplies AND your first machine rental payment.`}</p>
      <Tooltip
        tooltipContent={
          <p className="text-graphite">
            {`Insurance does it this way to manage costs and ensure patient compliance. This method spreads out expenses through installments and gives you time to verify usage and have a follow-up appointment with your physician. Once all rental payments are complete and compliance is passed, the machine is owned by you.`}
          </p>
        }
      >
        <div className="flex items-center">
          <p>{`Why do I pay a rental payment?`}</p>
          <img
            src={icoInfoAob}
            alt="ico-info"
            className="px-2 hover:opacity-80 cursor-pointer object-contain"
            width={37}
          />
        </div>
      </Tooltip>
    </>
  )
}

export { PapIntakePriceEstimateDetails, PapIntakePaymentDetails, PapIntakeUpfrontPaymentDetails }

