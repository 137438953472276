import { useState, useEffect } from 'react'
import { fetchSnlData } from '../fetch'
import { reportToSentry } from 'utils/reportToSentry'

export const useFetchBaseEquipment = () => {

  const [ baseEquipment, setBaseEquipment ] = useState( [] )
  const [ isLoading, setIsLoading ] = useState( false )

  useEffect( () => {
    setIsLoading( true )
    const controller = new AbortController()

    fetchSnlData( `/mask-data`, controller.signal ).then( res => {
      setIsLoading( false )
      if ( !res ) {
        reportToSentry( `Failed to load mask data`, {
          route: `/mask-data`
        })
      }
      setBaseEquipment( res.response )
    })
      .catch( err => {
        if ( err.name !== `AbortError` ) {
          reportToSentry( err )
          setIsLoading( false )
        }
      })

    return () => {
      controller.abort()
    }

  }, [] )

  return {
    baseEquipment,
    isLoading
  }
}