import * as React from "react"
import { TextInput } from "components/forms/components"
import { TextInputProps } from "components/forms/types"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { GooglePlace, GooglePlaceDetails, useAddressStore } from "components/addressBlock"
import { serializeFromGoogleTypeAhead, removeStreet2FromStreet } from "components/addressBlock/serializers"
import { DropdownContainer, DropdownContent } from "components/dropdown"
import { forwardRef } from "react"

const TypeAheadTextInput = forwardRef( ( textInputProps : Partial<TextInputProps>, ref ) => {

  const { onChange, onBlur, className } = textInputProps
  const { address, setAddress, setAddressPatientConfirmed } = useAddressStore()
  const [ showPredictions, setShowPredictions ] = React.useState( false )

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const handlePlaceClick = ( place : GooglePlace ) => {
    // eslint-disable-next-line object-curly-newline
    placesService?.getDetails({ placeId: place.place_id }, ( placeDetails : GooglePlaceDetails ) => {
      const { address_components } = placeDetails
      const {
        street,
        street2,
        city,
        state,
        zipCode
      } = serializeFromGoogleTypeAhead( address_components )

      const { structured_formatting } = place // There is a case where the place getDetails address_components doesn't return a street number for some addresses
      const { main_text } = structured_formatting // This is a hacky way to get around that
      const serializedStreet = removeStreet2FromStreet( main_text || street, street2 )
      const { firstName, lastName } = address
      setAddress({
        firstName,
        lastName,
        street : serializedStreet,
        street2,
        city,
        state,
        zipCode
      })
      setShowPredictions( false )
      setAddressPatientConfirmed( true ) // Means the autocomplete api was used to verify the address
    })
  }

  const handleChange = ( e : React.ChangeEvent<HTMLInputElement> ) => {
    setAddressPatientConfirmed( false ) // reset the patient confirmed flag (This is used to track if the address is verified by the autocomplete api)
    if ( e.currentTarget.value.length > 3 ) {
      getPlacePredictions({
        input: e.currentTarget.value,
        types: [ `address` ],
        componentRestrictions: {
          country: `us`
        },
        debounce: 500
      })
      setShowPredictions( true )
    }
    onChange && onChange( e )
  }

  const handleBlur = ( e : React.FocusEvent<HTMLDivElement> ) => {
    // hide predictions if the user clicks outside of the Dropdown container
    if ( e.relatedTarget === null || !e.currentTarget.contains( e.relatedTarget as Node ) ) {
      setShowPredictions( false )
    }
  }

  return (
    <DropdownContainer onBlur={handleBlur} tabIndex={-1}>
      <TextInput
        {...textInputProps}
        onChange={handleChange}
        className={`input ${className}`}
        onBlur={onBlur}
        reference={ref}
      />
      <DropdownContent shouldShow={showPredictions} isLoading={isPlacePredictionsLoading}>
        <ul>
          {placePredictions.map( ( prediction, index ) => (
            <li
              id="place-prediction"
              tabIndex={0}
              key={prediction.description}
              className="cursor-pointer p-4 hover:bg-secondaryBlue-tint2 hover:text-deepSleepBlue"
              onClick={() => handlePlaceClick( placePredictions[index] )}
            >{prediction.description}</li>
          ) )}
        </ul>
      </DropdownContent>
    </DropdownContainer>
  )
})

export default TypeAheadTextInput