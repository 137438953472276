import React from 'react'
import { stepLauncherCardContent } from './constants'
import StepCard from './StepCard'
import PropTypes from 'prop-types'
import { pageNames } from '../constants'
const screenTitle = `Complete Your Sleep Apnea Profile`
const screenSubheading = `Complete this profile in order to see your estimated supply cost through insurance.`

const StepLauncher = ({highestStep, activeStep, formValues, ...pageProps}) => {

  const onHandleClick = ( path ) => {
    pageProps.setPage( path )
  }

  return (
    <div>
      {
        highestStep === 1 &&
        <>
          <h2 className="mb-6 text-center font-medium">{screenTitle}</h2>
          <p className="mb-4 text-center">{screenSubheading}</p>
        </>
      }
      {
        stepLauncherCardContent.map( ( card ) => {
          const { cardImg, cardTitle, cardDetails, clickPath, stepCardButton } = card
          const thisStep = pageNames.findIndex( ( p ) => { return p === clickPath })
          const isCompleted = thisStep < highestStep
          const finishLineInput = card?.finishLineInput

          return (
            <StepCard
              key={thisStep}
              clickPath={clickPath}
              cardImg={cardImg}
              title={cardTitle}
              detail={cardDetails}
              isActive={activeStep === thisStep}
              isCompleted={isCompleted}
              stepCardButton={stepCardButton}
              handleClick={onHandleClick}
              finishLineInput={finishLineInput}
              formValues={formValues}
            />
          )
        })
      }
    </div>
  )
}

StepLauncher.propTypes = {
  highestStep: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  handleSetCompletedStep: PropTypes.func,
  formValues: PropTypes.object
}

export default StepLauncher
