import React from 'react'
import PropTypes from 'prop-types'
import { accountImg } from 'resources/images'

const AccountMenuButton = ({toggleMenuIsActive}) => {
  return (
    <button type="button" onClick={toggleMenuIsActive} className="focus:outline-none cursor-pointer">
      <img
        src={accountImg}
        width="20"
        height="20"
        className="object-cover w-5" alt="Account Menu Button"
      />
    </button>
  )
}
AccountMenuButton.propTypes = {
  toggleMenuIsActive: PropTypes.func.isRequired
}

export default AccountMenuButton
