import React from 'react'
import { Builder } from '@builder.io/react'
import { ImageTileTypes } from './types'
import { ImageTile } from './components/ImageTile'

type ImageTileProps = {
    imageTiles: ImageTileTypes[];
    header: string;
    borderTop?: boolean;
    borderBottom?: boolean;
}

export const ImageTileDisplay = ( props : ImageTileProps ) : JSX.Element => {
  const borderClasses = `bg-gray-100 border-gray-300 ${props.borderTop ? `border-t ` : ``}${props.borderBottom ? `border-b` : ``}`

  return (
    <div className={`bg-gray-100 border-gray-300 ${borderClasses}`}>
      <div className="font-family--inter sm:px-5 lg:px-7 lg:max-w-3xl mx-auto sm:pt-4 sm:pb-6 lg:pt-7 lg:pb-9">
        <h2 className="sm:pb-4 lg:pb-7 text-[#004C97] font-inter sm:text-lg lg:text-2xl text-center leading-4 font-normal">{props?.header}</h2>
        <ul className="flex flex-wrap justify-between sm:gap-y-6 lg:gap-y-12">
          {props?.imageTiles?.map( ( imageTile ) => {
            return (
              <li
                key={imageTile.title}
                className={`rounded-md overflow-hidden ${imageTile.fullWidth ? `basis-full` : `sm:w-[calc(50%-.75rem)] lg:w-[calc(50%-1.5rem)]`}`} // create 48px gap between tiles
              >
                <ImageTile
                  fullWidth={imageTile.fullWidth}
                  title={imageTile.title}
                  link={imageTile.link}
                  tileImage={imageTile.tileImage}
                  altText={imageTile.altText}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

Builder.registerComponent( ImageTileDisplay, {
  name: `ImageTileDisplay`,
  inputs: [
    {
      name: `header`,
      type: `text`
    },
    {
      name: `imageTiles`,
      type: `list`,
      required: true,
      subFields: [
        {
          name: `fullWidth`,
          type: `boolean`,
          helperText: `If checked, this tile will take up the full width of the container.`
        },
        {
          name: `title`,
          type: `text`,
          required: true
        },
        {
          name: `tileImage`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ],
          required: true,
          helperText: `Upload .jpeg, .jpg, .png, .svg, or .webp`
        },
        {
          name: `link`,
          type: `text`,
          required: true
        },
        {
          name: `altText`,
          type: `text`,
          required: true
        }
      ]
    },
    {
      name: `borderTop`,
      type: `boolean`
    },
    {
      name: `borderBottom`,
      type: `boolean`
    }
  ]
})


export default ImageTileDisplay