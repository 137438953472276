import React from 'react'
import PropTypes from 'prop-types'
import { SOCIAL_MEDIA } from '../constants'

export const SocialMediaIcon = ({href, imgSrc, title}) => {
  return (
    <a
      href={href}
      key={title}
      className="mx-1 flex items-center"
      target="_blank" rel="noreferrer"
    >
      <img
        height="21"
        src={imgSrc} alt={title}
      />
    </a>
  )
}

SocialMediaIcon.propTypes = {
  href: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export const SocialIcons = () => {
  return (
    <div className="flex flex-row justify-between align-middle my-2 mx-auto">
      {SOCIAL_MEDIA.map( social => {
        return <SocialMediaIcon {...social} key={social.title} />
      })}
    </div>
  )
}

export default SocialIcons
