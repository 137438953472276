import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'

export const ACCOUNT_EXISTS_MSG = `We have detected you already have an account. Please login to continue.`
export const UNEXPECTED_ACCOUNT_STATUS_MSG = `Error receiving magento_customer_status : unexpected status passed`
export const GENERIC_REQUEST_ERR_MSG = `An error occurred when submitting your request. If this issue persists, please contact customer support at ${AF_SUPPORT_PHONE_NUMBER}`
export const SUBMIT_NEW_LEAD_ERR_MSG = `Error submitting new lead, AWS error Response magento customer not created at time of return`

export const SELECT_YOUR_NEED_OPTIONS = {
  firstMachineAndSupplies: `I was recently diagnosed and need my first machine and supplies`,
  newSuppliesOrReplacement: `I'm a current CPAP user and need new supplies or a replacement machine`,
  neverDiagnosedWithSleepApnea: `I have never been diagnosed with Sleep Apnea`
}