import { useState } from "react"
import { Heading } from "components/resupply2"
import { isValidDoctor } from "./utils"
import { Modal } from "components/modal"
import { icoX } from "resources/images"
import { useDoctorSelectionState, useResupplySetting } from "../hooks"
import { Formatters } from "components/forms/components"
import copy from './copy.json'
import DoctorInfo from "components/doctorBlock/types/DoctorInfo"
import { DoctorBlock } from "components/doctorBlock"

export default function NewDoctorRequired({setDoctorComplete, doctorOnLoad, nextPage } : {setDoctorComplete: React.Dispatch<React.SetStateAction<boolean>>, doctorOnLoad: DoctorInfo | null, nextPage: () => void}) : JSX.Element {

  const [ error, setError ] = useState( `` )

  const [ resupplySettings, setResupplySettings ] = useResupplySetting()
  const setDoctorSelection = useDoctorSelectionState()[1]

  const handleSuccess = ( d : DoctorInfo ) => {
    const isSameDoctorNpi = d?.npi && doctorOnLoad?.npi
    const isSameDoctorContact = d?.firstName?.toLowerCase() === doctorOnLoad?.firstName?.toLowerCase() && d?.lastName?.toLowerCase() === doctorOnLoad?.lastName?.toLowerCase() && Formatters.phone( doctorOnLoad?.phone ) === Formatters.phone( d?.phone )

    if ( isSameDoctorNpi || isSameDoctorContact ) {
      setError( copy.sameDoctorAsCurrent )

      return setDoctorComplete( true )
    }

    if ( isValidDoctor( d ) ) {
      setDoctorComplete( true )
      setResupplySettings( Object.assign({}, resupplySettings, {
        updateDoctorRequired: `Optional`
      }) )
      setDoctorSelection( true )
    }
  }

  const closeModal = () => { return setError( `` ) }

  return (
    <div className="mx-auto">
      <Modal
        isOpen={Boolean( error )}
        onClose={closeModal}
        defaultCloseButton={false}
      >
        <div className="py-10 px-5 relative">
          <button
            className="absolute top-1 right-1 rounded-full p-3 hover:opacity-80"
            onClick={closeModal}
          >
            <img
              src={icoX}
              alt="Resupply Close Doctor Error"
              width={20}
              height={20}
              loading="lazy"
            />
          </button>
          <p className="text-error font-light text-lg my-4">{error}</p>
          <button className="btn bnt-secondary" onClick={() => { setError( `` ); nextPage() }}>{`Continue`}</button>
        </div>
      </Modal>
      <Heading
        title="New Doctor Required"
        description="We will send a request to your new doctor to approve your medical supplies." // TODO: Add the "our records..." logic
      />
      <DoctorBlock
        onSuccess={handleSuccess}
        existingDoctor={doctorOnLoad}
        forceUpdate
      />
    </div>
  )
}