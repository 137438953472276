const liStyle = `mb-3 font-light`
const headerStyle = `text-xl md:text-3xl mt-10 text-deepSleepBlue`
const strongStyle = `text-deepSleepBlue font-bold`
const ulStyle = `list-disc text-center w-10/12 mx-auto my-5 list-inside text-sm md:text-base`
const pStyle = `my-5 font-light text-sm md:text-base`
const boldPStyle = `my-7 font-bold`

export const additionalInfoBlock = (
  <>
    <h3 className={headerStyle}>{`Additional Information:`}</h3>
    <p className={pStyle}>{` Additional documentation may be required by your insurance company. If we need additional documentation, we will reach out to you directly and let you know.`}</p>
    <p className={pStyle}>{`Please contact your doctor to help get all the additional documents needed above and let us know if you have any questions!`}</p>
  </>
)

export const replacementMachineBlock = [
  <>
    <h3 className={headerStyle}>{`Replacement PAP Machine`}</h3>
    <p className={boldPStyle}>{`You indicated that you were in need of a replacement PAP machine.`}</p>
    <p className={boldPStyle}>{`Before we can assist you with a replacement PAP machine through your insurance, please read the following information regarding coverage and documentation requirements:`}</p>
  </>,
  <>
    <ul className={ulStyle}>
      <li className={liStyle}>
        {` Insurance companies typically only cover a replacement CPAP/BiPAP machine every 5 years.`}
      </li>
    </ul>
  </>,
  <>
    <ul className={ulStyle}>
      <li className={liStyle}>
        <strong className={strongStyle}>{`Required:`}</strong>
        {` A prescription for a CPAP machine (detailed written order) that includes your pressure settings, diagnostic codes, and the HCPCs codes for the CPAP machine and supplies. Your doctor should know what those codes are.`}
      </li>
    </ul>
  </>,
  <>
    <ul className={ulStyle}>
      <li className={liStyle}>
        <strong className={strongStyle}>{`Required:`}</strong>
        {` Copy of your sleep study`}
      </li>
    </ul>
  </>,
  <>
    <ul className={ulStyle}>
      <li className={liStyle}>
        <strong className={strongStyle}>{`Required:`}</strong>
        {` Clinical office visit notes that cite patient is using and benefiting from a machine and is in need of a replacement. The office visit notes must be within the past 6 months.`}
      </li>
    </ul>
  </>,
  <>
    <p className={pStyle}>{`We will fax the need for those documents to the doctor you just provided. If you have not been seen within the past 6 months to discuss your CPAP therapy, your first step would be to schedule an appointment. 
    If your current doctor does not have a copy of your sleep study, you will want to sign a release of information from your previous doctor so your current doctor can have the necessary documents.`}</p>
  </>
]