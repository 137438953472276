import React from 'react'
import styled from 'styled-components'
import { icoCheck } from 'resources/images'

const CheckSvg = styled.div`
    width: 30px;
    height: 30px;
    background: #0057a8;
`

const CheckIcon = () => {
  return (
    <CheckSvg className="absolute top-0 flex justify-center items-center"><img src={icoCheck} alt={`Check`} /></CheckSvg>
  )
}

export default CheckIcon
