import React, { useState, useEffect, useRef } from 'react'
import { submitEmergencyContactInfo, submitAobAcceptance } from 'modules/aobPayments/fetchPromises'
import { initialEmergencyContactFields, defaultErrorMessage } from 'modules/aobPayments/constants'
import { EmergencyContactBlock, SummaryFooter, PayButtonBlock, PayLaterModal } from './components'
import PropTypes, { number, string, bool, func } from 'prop-types'
import PricingEstimateBlockWrapper from '../PricingEstimateBlockWrapper'
import { Deductible, OutOfPocket, CoInsurance, InsurerNames } from '../insuranceItems'
import { reportToSentry } from 'utils/reportToSentry'
import { Validators } from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { getHash } from 'modules/clinicalScheduling/utils'
import AddressBlock, { overrideAddressBlockStyle, useAddressStore } from 'components/addressBlock'
import { useAobSegmentStore } from 'stores'

function HealthInsuranceInfoBlock({
  invoice,
  aobContent,
  setAobIsCompleted,
  setPayLater,
  nextPage,
  setError,
  blockProceed,
  setNotInterested
}) {

  const refs = {
    emergency_contact_name: useRef( null ),
    emergency_contact_phone: useRef( null ),
    emergency_contact_relationship_to_patient: useRef( null )
  }

  const [ openModal, setOpenModal ] = useState( false )
  const [ submittingAob, setSubmittingAob ] = useState( false )
  const [ emergencyContactError, setEmergencyContactError ] = useState( `` )
  const [ emergencyContactFormFields, setEmergencyContactFormFields ] = useState( initialEmergencyContactFields )
  const [ fieldErrors, setFieldErrors ] = useState( initialEmergencyContactFields )
  const inboundFromShippingCollectedPath = sessionStorage.getItem( `patientShippingAddressCollectedPriorToPaymentPortalMount` )
  const { isAddressComplete } = useAddressStore()
  const { aobSegmentType } = useAobSegmentStore()

  const hash = getHash()

  useEffect( () => {
    const urlParams = new URLSearchParams( window.location.search )

    setEmergencyContactFormFields({
      ...emergencyContactFormFields,
      patient_hash: urlParams.get( `sgh` ) ?? urlParams.get( `txt` )
    })
  }, [] )

  const emergencyContactSubmission = async ( payLater ) => {
    setEmergencyContactError( `` )
    // validate emergencyContactFormFields
    if ( !Validators.name( emergencyContactFormFields.emergency_contact_name ) ) {
      setEmergencyContactError( `Please fill out emergency contact name...` )
      setSubmittingAob( false )

      return refs.emergency_contact_name.current.focus()
    }
    if ( emergencyContactFormFields.emergency_contact_relationship_to_patient?.length === 0 ) {
      setSubmittingAob( false )
      setEmergencyContactError( `Please fill out relationship to patient...` )

      return refs.emergency_contact_relationship_to_patient.current.focus()
    }
    if ( !Validators.phone( emergencyContactFormFields.emergency_contact_phone ) ) {
      setSubmittingAob( false )
      setEmergencyContactError( `Please fill out a valid emergency contact phone number...` )

      return refs.emergency_contact_phone.current.focus()
    }
    if ( aobSegmentType === `Intake` && !emergencyContactFormFields?.permission_checked_flag ) {
      setSubmittingAob( false )
      setEmergencyContactError( `Permission required: Aeroflow requires your consent to contact the emergency contact person in order to proceed.` )

      return refs.emergency_contact_name.current.focus()
    }
    // Submit Emergency Contact Info
    try {
      const emergencyContactResponseData = await submitEmergencyContactInfo( emergencyContactFormFields )

      if ( emergencyContactResponseData?.errors?.length && emergencyContactResponseData?.errors[0]?.path?.length && emergencyContactResponseData?.errors[0]?.path[0] === `emergency_contact_phone` ) {
        return setEmergencyContactError( `The phone number entered is not a valid phone number. Please enter a valid phone number and try again...` )
      }

      if ( !emergencyContactResponseData?.meta?.status || emergencyContactResponseData.meta.status !== `OK` || emergencyContactResponseData?.errors ) {
        reportToSentry( new Error( `AOB: submit emergency contact failed` ), {
          hash,
          response: JSON.stringify( emergencyContactResponseData )
        })

        return setEmergencyContactError( defaultErrorMessage )
      }

      return await aobAcceptanceSubmission( payLater )
    } catch ( error ) {
      reportToSentry( new Error( `AOB: submit emergency contact failed`, {
        cause: error
      }), {
        hash
      })
      setError( defaultErrorMessage )

      return setSubmittingAob( false )
    }
  }

  const aobAcceptanceSubmission = async ( payLaterSelected ) => {
    try {
      const acceptAobResponseData = await submitAobAcceptance()
      if ( !acceptAobResponseData?.meta?.status || acceptAobResponseData.meta.status !== `Accepted` ) {
        setSubmittingAob( false )

        reportToSentry( new Error( `AOB: accept aob submission failed` ), {
          response: JSON.stringify( acceptAobResponseData ),
          hash
        })

        return setError( defaultErrorMessage )
      }

      setPayLater( payLaterSelected )
      setAobIsCompleted( true )

      return nextPage()
    } catch ( error ) {
      reportToSentry( new Error( `AOB: accept aob submission failed`, {
        cause: error
      }), {
        hash
      })
      setError( defaultErrorMessage )

      return setSubmittingAob( false )
    }
  }


  const handleSubmitAob = async ( payLaterSelected = false ) => {
    setFieldErrors( initialEmergencyContactFields )
    setError( null )
    setSubmittingAob( true )

    // only submit emergency contact if there is a rental item
    if ( invoice?.emergency_contact_info_required_flag ) await emergencyContactSubmission( payLaterSelected )
    // Submit AOB Acceptance
    else await aobAcceptanceSubmission( payLaterSelected )
    setSubmittingAob( false )

  }

  const headingClassName = `text-xl tracking-wide font-bold text-center my-4`
  overrideAddressBlockStyle({
    headerStyle: headingClassName,
    wrapperStyle: `bg-lightGray rounded-xl max-w-xl md:px-8 md:py-2 sm:p-2 my-4 w-full`,
    containerStyle: `text-center`,
    view: {
      inputWrapperClassName: `flex flex-row justify-center max-w-sm mx-auto mt-2  themed-checkboxes`
    }
  })

  return (
    <PricingEstimateBlockWrapper>
      <p className={headingClassName}>{`Health Insurance Information`}</p>
      <InsurerNames invoice={invoice} />
      <Deductible deductible={invoice?.deductible} deductibleRemaining={invoice?.deductible_remaining} />
      <OutOfPocket oop={invoice?.oop} oopRemaining={invoice?.oop_remaining} />
      <CoInsurance coIns={invoice?.coinsurance_percentage} />
      {
        invoice?.emergency_contact_info_required_flag &&
        <div className="mb-5 max-w-lg w-full">
          <EmergencyContactBlock
            aobContent={aobContent}
            refs={refs}
            emergencyContactFormFields={emergencyContactFormFields}
            setEmergencyContactFormFields={setEmergencyContactFormFields}
            setEmergencyContactError={setEmergencyContactError}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
          />
          {
            emergencyContactError &&
            <p className="text-error text-center font-light">{emergencyContactError}</p>
          }
        </div>
      }
      {( !inboundFromShippingCollectedPath || inboundFromShippingCollectedPath !== `true` ) &&
        <AddressBlock
          addressType="Shipping"
          shouldSkipValidation
        />}
      {invoice?.total_patient_owes === 0 ?
        <button
          className="btn-secondary max-w-xs"
          onClick={() => { return handleSubmitAob( false ) }}
          disabled={submittingAob || blockProceed || ( ( !inboundFromShippingCollectedPath || inboundFromShippingCollectedPath !== `true` ) && !isAddressComplete )}
        >
          {submittingAob ? <LoadingSpinner height="2.5rem" width="2.5rem" rings={3} /> : `Confirm Order`}
        </button>
        :
        <PayButtonBlock
          payLaterEligible={invoice?.pay_later_eligible_flag}
          setOpenModal={setOpenModal}
          submittingAob={submittingAob}
          submitAob={() => { return handleSubmitAob( false ) }}
          blockProceed={blockProceed || ( ( !inboundFromShippingCollectedPath || inboundFromShippingCollectedPath !== `true` ) && !isAddressComplete )}
        />}
      <SummaryFooter aobContent={aobContent} setNotInterested={setNotInterested} />
      {invoice?.pay_later_eligible_flag && invoice?.total_patient_owes !== 0 &&
        <PayLaterModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          submittingAob={submittingAob}
          handleConfirm={async () => { return await handleSubmitAob( true ) }}
        />}
    </PricingEstimateBlockWrapper>
  )
}

HealthInsuranceInfoBlock.propTypes = {
  invoice: PropTypes.shape({
    oop: number,
    oop_remaining: number,
    deductible: number,
    deductible_remaining: number,
    coinsurance_percentage: number,
    emergency_contact_info_required_flag: bool,
    total_patient_owes: number,
    pay_later_eligible_flag: bool,
    scheduling_required_flag: bool,
    details: PropTypes.arrayOf( PropTypes.shape({
      hpcp_code: string,
      item_description: string,
      quantity: number,
      ins_payment: number,
      patient_owes: number,
      rental_flag: bool
    }) )
  }),
  aobContent: string,
  setAobIsCompleted: func,
  nextPage: func,
  setError: func,
  setNotInterested: func,
  setPayLater: func,
  blockProceed: bool,
  customerDataRefetch: PropTypes.func,
  customerData: PropTypes.object
}

export default HealthInsuranceInfoBlock