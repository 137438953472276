/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"

export const promoContentTransform = (
  node: any,
  heroSrc: string,
  setHeroSrc: React.Dispatch<React.SetStateAction<string>>,
  handleSetHeroContent: ( _key: string, _content: string ) => void
) : void | null => {
  if ( node.type === `tag` && node?.attribs[`data-element`] === `mobile_image` ) return null
  if ( node?.attribs?.class ) {
    if ( node.attribs.class.includes( `lg-` ) ) node.attribs.class = node.attribs.class.replaceAll( `lg-`, `lg:` )
    if ( node.attribs.class.includes( `md-` ) ) node.attribs.class = node.attribs.class.replaceAll( `md-`, `md:` )
    if ( node.attribs.class.includes( `sm-` ) ) node.attribs.class = node.attribs.class.replaceAll( `sm-`, `sm:` )
    if ( node.attribs.class.includes( `ratio` ) ) node.attribs.class = node.attribs.class.replaceAll( `ratio`, `/` )
    if ( node.attribs.class.includes( `hero-background-img` ) && node?.children?.length ) {
      if ( node.children[0]?.attribs?.src && heroSrc !== node.children[0].attribs.src ) setHeroSrc( node.children[0].attribs.src )

      return null
    } else if ( node.attribs.class.includes( `hero-heading` ) && node?.children?.length && node.children[0]?.data ) {
      handleSetHeroContent( `heroHeading`, node.children[0]?.data )

      return null
    } else if ( node.attribs.class.includes( `qualify-heading` ) && node?.children?.length && node.children[0]?.data ) {
      handleSetHeroContent( `qualifyHeading`, node.children[0]?.data )

      return null
    }
  }

  if ( node.type === `tag` && node?.name === `img` ) {
    // change media folder from sleep to breastpumps ( should default to breastpumps but not the case in prod )
    node.attribs.src = node.attribs.src.replaceAll( process.env.REACT_APP_MAGENTO_2_BASE_URL, process.env.REACT_APP_CMS_IMAGE_URL )
    node.attribs.loading = `lazy`
  }
}