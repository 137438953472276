import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MachineShortageStacker from './MachineShortageStacker'
import AnimatedHeightDiv from 'components/animatedHeightDiv/AnimatedHeightDiv'
function MachineShortage({setDisplayProgress, machineEligible, formValues, ...pageProps}) {
  const [ heightChange, setHeightChange ] = useState( false )

  useEffect( () => {
    if ( !machineEligible || formValues.intentOrderNewEquipment === `false` ) return pageProps.nextPage()
    setDisplayProgress( false )

    return () => {
      setDisplayProgress( true )
    }
  }, [] )

  const triggerHeightChange = () => {
    setHeightChange( !heightChange )
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: `smooth`
    })
  }

  return (
    <div className="flex flex-col gap-5 items-center text-center max-w-2xl w-full mx-auto h-full">
      <AnimatedHeightDiv
        display
        heightDependencies={heightChange}
      >
        <MachineShortageStacker
          triggerHeightChange={triggerHeightChange}
          endContent={
            <>
              <h3 className="text-xl md:text-3xl mt-10 mb-5">{`Next Steps:`}</h3>
              <div className="flex flex-col gap-3 items-center">
                <button onClick={() => { return pageProps.nextPage() }} className="btn-secondary max-w-xs">
                  {`Order Fresh CPAP Supplies`}
                </button>
                <button onClick={() => { pageProps.handleNoLongerInterested( `machine shortage` ) }} className="btn-secondary max-w-xs">
                  {`Not Interested in Supply Order`}
                </button>
              </div>
            </>

          }
        />
      </AnimatedHeightDiv>
    </div>
  )
}

MachineShortage.propTypes = {
  setInterested: PropTypes.func,
  machineShortageContent: PropTypes.object,
  setDisplayProgress: PropTypes.func,
  machineEligible: PropTypes.bool,
  formValues: PropTypes.object
}

export default MachineShortage