import React from 'react'
import PropTypes from 'prop-types'
import { maskHeadgearImg } from 'resources/images'

const LastHeadgearSelector = ({
  heading,
  name,
  options,
  onChange,
  selectedValue,
  // defaultLabel = `Select an Option`,
  showOnMobile
}) => {

  return (
    <>
      {showOnMobile && <div className="mt-4">
        <div className="text-center text-xl mb-6">{heading}</div>
        <img
          src={maskHeadgearImg}
          className=" w-1/2 md:w-2/5 h-auto m-auto"
          alt={`Headgear Mask Img`}
        />
        <div className="flex flex-col items-center justify-center mt-4 gap-4 max-w-xs mx-auto">
          {
            options.map( ( item ) => {
              return (
                <button
                  name={name}
                  value={item.value}
                  onClick={onChange}
                  key={item.value}
                  label={item.label}
                  className={selectedValue === item.value ? `btn-secondary-selected` : `btn-secondary`}
                >
                  { item.label }
                </button>
              )
            })
          }
        </div>

      </div>}
    </>
  )
}

LastHeadgearSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  selectedModel: PropTypes.string,
  onChange: PropTypes.func,
  defaultLabel: PropTypes.string,
  showOnMobile: PropTypes.bool
}

export default LastHeadgearSelector
