import React from 'react'
import PropTypes from 'prop-types'
import { CheckIcon } from 'components/SNLSelectors'
import { heatedTubingImg, standardTubingImg } from 'resources/images'

const DEFAULT_OPTIONS = [
  {
    name: `Heated tubing`,
    description: `Usually has clips on one end that attach to the humidifier.`,
    imgSrc: heatedTubingImg,
    value: 12804
  },
  {
    name: `Standard tubing`,
    description: `Has interchangeable ends that have no visual difference.`,
    imgSrc: standardTubingImg,
    value: 18077
  }
]

const TubingSelector = ({
  heading,
  options = DEFAULT_OPTIONS,
  selectedValue,
  onChange,
  showOnMobile
}) => {
  return (
    <>
      {showOnMobile && <div>
        <p className="mb-6 text-lg text-center">{heading}</p>
        <div className="grid grid-cols-2 gap-2 mb-4">
          {
            options.map( item => {
              const { name, value, description, imgSrc } = item

              return (
                <div key={value} className="h-80">
                  <div
                    className={`h-60 border flex relative ${selectedValue === value ? `border-deepSleepBlue` : `border-lightGray`}`}
                    onClick={() => {
                      return onChange({
                        target: {
                          name: `tubingType`,
                          value,
                          label: name
                        }
                      })
                    }}
                  >
                    {selectedValue === value && <CheckIcon />}
                    <img
                      src={imgSrc}
                      className="cursor-pointer max-h-full m-auto"
                      alt={name}
                    />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      return onChange({
                        target: {
                          name: `tubingType`,
                          value,
                          label: name
                        }
                      })
                    }}
                  >
                    <p className="text-sm font-light">{name}</p>
                    <p className="text-sm font-light text-graphite">{description}</p>
                  </div>
                </div>
              )
            })
          }
        </div>

      </div>}
    </>

  )
}

TubingSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  selectedValue: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
  onChange: PropTypes.func,
  showOnMobile: PropTypes.bool
}

export default TubingSelector
