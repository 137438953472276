import React from 'react'
import { ColWrapper, ColHeader } from './tailwindWrappers'
import { SocialIcons } from './SocialIcons'

const SocialsCol = () => {
  return (
    <ColWrapper>
      <div>
        <ColHeader>{`Social Media`}</ColHeader>
        <div className="max-w-56">
          <SocialIcons />
        </div>
      </div>
    </ColWrapper>
  )
}

export default SocialsCol
