import { useFetch } from 'hooks'
import { useAddressStore } from '../state'
import { reportToSentry } from 'utils/reportToSentry'
import { serializeToPatientsUpdateAddress } from '../serializers'

export function useUpdateAddress() {
  const { address, addressWebConfirmed, addressPatientConfirmed, setIsAddressComplete } = useAddressStore()

  const { lazyFetch : updatePatientAddress, isLoading: isUpdatingPatientAddress } = useFetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/update-address`, {
    method: `POST`,
    body: JSON.stringify({
      addressType: `shipping`,
      addressWebConfirmed: addressWebConfirmed,
      addressPatientConfirmed: addressPatientConfirmed,
      ...serializeToPatientsUpdateAddress( address )
    })
  }, {
    onSuccess: () => {
      setIsAddressComplete( true )
    },
    onErrorOverride: ( err ) => {
      console.error( `Something happened updating patient address` )
      reportToSentry( new Error( `Something happened updating patient address` ), {
        err
      })

      setIsAddressComplete( true )
    }
  })

  return {
    updatePatientAddress,
    isUpdatingPatientAddress
  }
}