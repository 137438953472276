import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { rxIconWhite } from 'resources/images'
import { prescriptionNeededCopy } from '../constants'
import StepContentRenderer from '../../StepContentRenderer'

type PrescriptionNeededStepProps = {
  stepStatus: boolean;
  isCurrentStep?: boolean;
}

function PrescriptionNeededStep({ stepStatus, isCurrentStep } : PrescriptionNeededStepProps ) : JSX.Element {
  return (
    <div className="flex">
      <TrackerStepIcon imageSrc={rxIconWhite} stepStatus={stepStatus} isCurrentStep={isCurrentStep} />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={prescriptionNeededCopy}
      />
    </div>
  )
}

export default PrescriptionNeededStep
