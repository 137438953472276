import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components/tooltip'
import { icoInfoAob } from 'resources/images'
import EstimateTooltipTemplate from '../tooltips/EstimateTooltipTemplate'
import { useGetAobSegmentedCopy } from 'modules/aobPayments/hooks/useGetAobSegmentedCopy'

function TotalCost({ invoice }) {

  const pricingEstimateTooltips = useGetAobSegmentedCopy()

  return (
    <div className="w-full py-2 mb-5 px-10 bg-white flex flex-col items-center justify-center relative">
      <p className="text-xl tracking-wide font-bold text-center py-3">{`Total Cost`}</p>
      <div className="flex items-center text-center w-fit">
        <p className="font-light text-lg whitespace-nowrap">{`Insurance Pays: $${invoice?.total_ins_payment.toFixed( 2 )}`}</p>
        {pricingEstimateTooltips && (
          <Tooltip
            id="total-cost-ins"
            tooltipContent={<EstimateTooltipTemplate {...pricingEstimateTooltips.totalCostIns} />}
          >
            <img
              src={icoInfoAob}
              alt="ico-info"
              className="inline px-2 hover:opacity-80 cursor-pointer object-contain"
              name="totalCostIns"
              width={37}
            />
          </Tooltip>
        )}
      </div>
      <div className="flex items-center text-center">
        <p className="font-light text-lg whitespace-nowrap">{`What You Owe: $${invoice?.total_patient_owes.toFixed( 2 )}`}</p>
        {pricingEstimateTooltips && (
          <Tooltip
            id="total-cost-youn"
            tooltipContent={<EstimateTooltipTemplate {...pricingEstimateTooltips.totalCostYou} />}
          >
            <img
              src={icoInfoAob}
              alt="ico-info"
              className="inline px-2 hover:opacity-80 cursor-pointer object-contain m-auto"
              name="totalCostYou"
              width={37}
            />
          </Tooltip>
        )}
      </div>
    </div>
  )
}

TotalCost.propTypes = {
  invoice: PropTypes.shape({
    total_ins_payment: PropTypes.number,
    total_patient_owes: PropTypes.number
  })
}

export default TotalCost