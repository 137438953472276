import React from 'react'

const SleepNewLeadsPriceEstimateDetails = () : JSX.Element => {
  return (
    <div className="py-5 text-left">
      <p className="pb-3"><strong>{`READ ME - Transparency with billing and other important information`}</strong></p>
      <p>{`At Aeroflow, we provide transparent billing information based on your insurance. Below is your personalized and `}<strong>{`estimated`}</strong> {`breakdown of how your insurance pays for your new PAP supplies.`}</p>
      <ol className="py-6 list-decimal text-base font-normal pl-10">
        <li>
          <p><u>{`PAP Supplies`}</u>{` - Insurance coverage for PAP supplies varies. Some plans cover the full cost, while others require the patient to pay a portion of the cost (copayments or deductibles). 
              You can see your full `}<strong>{`estimated`}</strong> {`breakdown of your supply order in the “Order Summary Details” section below.`}</p>
        </li>
      </ol>
      <p className="pb-6">{`Certain insurance plans require prior authorization before shipping orders. To always secure approval, consistently use your machine for at least 4 hours each night.`}</p>
      <p>{`IMPORTANT NOTE: `}{`Please be aware that if your deductible resets or insurance coverage changes, you may be susceptible to different copayment or deductible costs in the future.`}</p>
    </div>
  )
}

const SleepNewLeadsPaymentDetails = () : JSX.Element => {
  return (
    <>
      <p className="md:text-lg text-center font-light max-w-2xl">{`Your payment amounts displayed below are the estimated costs for your order based on how your plan traditionally covers supplies. `}</p>
      <p className="md:text-lg text-center font-light max-w-2xl">{`Your item(s) will be shipped upon receipt of payment and required medical documents, including your prescription (and possibly your sleep study), as mandated by your health insurance provider.`}</p>
    </>
  )
}

const SleepNewLeadsUpfrontPaymentDetails = () : JSX.Element => {
  return (
    <>
      <p className="md:text-lg text-center font-light max-w-2xl"><u>{`Aeroflow Advantage:`}</u> {`You'll receive reminders from Aeroflow for future supply replenishment based on your insurance provider's schedule.`}</p>
    </>
  )
}

export { SleepNewLeadsPriceEstimateDetails, SleepNewLeadsPaymentDetails, SleepNewLeadsUpfrontPaymentDetails }

