import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icoFormCheck, icoCheckSm } from 'resources/images'

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 20px 32px;
`

const CheckDiv = styled.div`
  background-color: #22652C;
  position: absolute;
  z-index: 40;
  border-top-left-radius: inherit;
  left: -1px;
  top: -1px;
  padding: 10px 7px 10px 7px;
`

const CheckImg = styled.img`
  height: 13px;
`


const StepCard = props => {
  const { title, detail, isActive, isCompleted, clickPath, stepCardButton, handleClick, cardImg } = props
  const [ finishLineStatus, setFinishLineStatus ] = React.useState( false )

  React.useEffect( () => {
    // if the step card is the last step it will have finish line inputs that we need to check have been accepted or not
    if ( props?.finishLineInput ) {
      const formVals = props?.formValues
      const checkInputs = props?.finishLineInput
      checkInputs.every( ( input, i ) => {
        // handle last input types dynamically (incase you are handed a string value or integer)
        if ( formVals?.[input] || ( typeof formVals?.[input] === `string` && formVals?.[input]?.length ) ) {
          // if one of the last inputs is a string containing a boolean flag make sure it is not false
          // eslint-disable-next-line no-extra-boolean-cast
          if ( input.includes( `Flag` ) && !Boolean( formVals?.[input] ) ) {
            return false
          }
          if ( i === checkInputs.length - 1 ) setFinishLineStatus( true )

          return true
        } else {
          return false
        }
      })
    }
  })

  const handleOnClick = () => {
    if ( isCompleted || isActive ) {
      return handleClick( clickPath )
    }
  }

  return (
    <div className={`sm:w-80 md:w-96 ${ ( isActive || isCompleted ) && `cursor-pointer`} mb-5 mx-auto p-5 border-lightGray border rounded-lg relative`} onClick={handleOnClick}>
      {
        ( isCompleted || finishLineStatus ) &&
          <CheckDiv>
            <CheckImg src={icoCheckSm} alt={icoFormCheck} />
          </CheckDiv>
      }
      <CardContent>
        <div className="relative w-28">
          <img src={cardImg} alt={detail} />
        </div>
        <div>
          <h6 className="font-semibold tracking-wide">{title}{`:`}</h6>
          <p className="text-base font-light">{detail}</p>
        </div>
      </CardContent>
      <div className="flex justify-center">
        <button
          className="btn-secondary sm:text-sm md:text-base mt-3"
          disabled={!isActive && !isCompleted}
        >
          {`${isCompleted || finishLineStatus ? `Edit` : `Confirm`} your ${stepCardButton} information`}
        </button>
      </div>
    </div>
  )
}

StepCard.propTypes = {
  cardImg: PropTypes.string,
  title: PropTypes.string,
  detail: PropTypes.string,
  isActive: PropTypes.bool,
  isCompleted: PropTypes.bool,
  clickPath: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  stepCardButton: PropTypes.string,
  handleClick: PropTypes.func,
  formValues: PropTypes.object,
  finishLineInput: PropTypes.array
}

export default StepCard