import { PagePropsAbstract } from 'components/pageNavigator'
import AddressBlock2, { useAddressStore, overrideAddressBlockStyle } from 'components/addressBlock'

type AddressProps = PagePropsAbstract
export default function Address( props : AddressProps ) : JSX.Element {

  const isComplete = useAddressStore( state => state.isAddressComplete )

  overrideAddressBlockStyle({
    headerStyle: `font-bold mb-1 text-center`,
    view: {
      textStyle: `font-normal capitalize`,
      editActionClassName: `underline decoration-deepSleepBlue text-deepSleepBlue capitalize mb-2 cursor-pointer`
    }
  })

  return (
    <div className="mx-auto w-full max-w-xl text-center">
      <div>
        <div className="mt-10 mb-16 max-w-sm mx-auto">
          <AddressBlock2
            onSuccessfulConfirm={() => {
              window.scrollTo({
                top: 0,
                behavior: `smooth`
              })
            }}
            shouldSkipMountFetch={isComplete}
          />
        </div>

        <div className="max-w-xs w-full mx-auto mt-16 mb-5 flex flex-col items-center gap-4">
          <button className="btn-secondary" onClick={props.nextPage} disabled={!isComplete}>{`Next`}</button>
          <button className="btn-primary" onClick={props.prevPage} >{`Back`}</button>
        </div>
      </div>
    </div>
  )
}