import { Tile } from 'components/tile'
import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { formatDate } from '../utils'
import { myOrdersId } from '../constants'
import { accountNavOptions } from 'apollo'
import OrderDetails from '../components/OrderDetails'
import { useAccountStore } from '../state'
import Error from '../components/Error'

type Order = {
  order: {
    order_date: string;
    order_number: number;
    items: {
      item_name: string;
      status: string;
      url: string;
    }[]
  }
}

function Orders() : JSX.Element | null {

  const { orders, customer } = useAccountStore()
  const [ expanded, setExpanded ] = useState<boolean>( false )
  const [ orderDetails, setOrderDetails ] = useState<Order | null>( null )
  const currentNavOptions = useReactiveVar( accountNavOptions )
  const [ sortedOrderHistory, setSortedOrderHistory ] = useState<Order[]>( [] )

  useEffect( () => {
    if ( orders?.data ) {
      setSortedOrderHistory( orders.data.sort( ( a: Order, b: Order ) => {
        const dateOne = new Date( a.order.order_date )
        const dateTwo = new Date( b.order.order_date )

        return dateTwo < dateOne ? -1 : 1
      }) )
    }
  }, [ orders ] )

  const getOrderStatus = () => {
    const orderItems = sortedOrderHistory[0]?.order?.items
    if ( !orderItems?.length ) return

    const isShipped = orderItems.some( item => item.status === `Shipped` )
    if ( isShipped ) return {
      status: `Shipped`,
      className: `text-success`
    }

    const isAwaitingFulfillment = orderItems.some( item => item.status === `Awaiting Fulfillment` )
    if ( isAwaitingFulfillment ) return {
      status: `Awaiting Fulfillment`,
      className: `text-deepSleepBlue`
    }

    const isDelivered = orderItems.every( item => item.status === `Delivered` )
    if ( isDelivered ) return {
      status: `Delivered`,
      className: `text-success`
    }

    const isCancelled = orderItems.every( item => item.status === `Cancelled` )
    if ( isCancelled ) return {
      status: `Cancelled`,
      className: `text-error`
    }

    // default to awaiting processing
    return {
      status: `Awaiting Processing`,
      className: `text-deepSleepBlue`
    }
  }

  useEffect( () => {
    const myOrdersNavOption = currentNavOptions.find( ( option ) => { return option.id === myOrdersId })
    // add Orders to navigate dropdown
    if ( sortedOrderHistory.length && !myOrdersNavOption ) accountNavOptions( [{
      label: `Orders`,
      id: myOrdersId
    }, ...currentNavOptions ] )
  }, [ sortedOrderHistory ] )

  if ( orders?.error ) return (
    <Error title="Orders" id={myOrdersId} fillInTheBlank="order" />
  )

  // patient has no order history
  if ( !sortedOrderHistory?.length ) return (
    <Tile headerContent="Orders" id={myOrdersId}>
      <p className="text-deepSleepBlue text-center my-8">{`You have no order history associated with this account.`}</p>
    </Tile>
  )

  return (
    <Tile headerContent="Orders" id={myOrdersId}>
      <OrderDetails orderDetails={orderDetails} setOrderDetails={setOrderDetails} />
      { customer.error && <p className="text-error">{customer.error?.message}</p> }
      { customer.data &&
          <div className="max-w-xs">
            <div className="flex flex-col">
              <p className="mb-2 font-bold">{`Current Order`}</p>
              <div className="flex items-center gap-2">
                <p className="text-sm">
                  {`You placed an order on `}
                  <span className="font-bold">{formatDate( sortedOrderHistory[0]?.order?.order_date )}</span>
                </p>
                <p
                  className="cursor-pointer text-deepSleepBlue underline text-sm"
                  onClick={() => { setOrderDetails( sortedOrderHistory[0] ) }}
                >
                  {`View Order`}
                </p>
              </div>
              {
                sortedOrderHistory[0]?.order?.items?.length > 0 &&
                  <div className="flex gap-2">
                    <p className="capitalize text-sm">{`Status: `}</p>
                    <p className={`text-sm ${getOrderStatus()?.className}`}>{getOrderStatus()?.status}</p>
                  </div>
              }
            </div>
            <p className="mt-5 mb-2 font-bold">{`Order History`}</p>
            {
              ( orders?.data?.length || 0 ) > 0 &&
                <>
                  {
                    expanded &&
                    <>
                      <p
                        className="cursor-pointer text-deepSleepBlue underline mb-5"
                        onClick={() => { setExpanded( !expanded ) }}
                      >
                        {`Show Less`}
                      </p>
                      {
                        sortedOrderHistory.slice( 1 ).map( ( order: Order ) => {
                          return (
                            <div key={order.order.order_number} className="flex mb-4 capitalize justify-evenly gap-3">
                              <p className="text-sm">{formatDate( order.order.order_date )}</p>
                              <p className="text-center w-24 text-sm">{order.order.items[0].status}</p>
                              <p
                                className="cursor-pointer text-deepSleepBlue underline text-sm"
                                onClick={() => { setOrderDetails( order ) }}
                              >
                                {`View Details`}
                              </p>
                            </div>
                          )
                        })
                      }
                    </>
                  }
                  <p
                    className="cursor-pointer text-deepSleepBlue underline"
                    onClick={() => { setExpanded( !expanded ) }}
                  >
                    {expanded ? `Show Less` : `See All`}
                  </p>
                </>
            }
          </div>
      }
    </Tile>
  )
}

export default Orders