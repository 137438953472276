import React from 'react'
import { Helmet } from 'react-helmet'

type HeadTypes = {
  title: string,
  meta: {
    name: string,
    content: string
  }
}

function Head( props: HeadTypes ) : JSX.Element {

  const { location } = window
  const { origin, pathname } = location
  const sameOrigin = origin === `https://aeroflowsleep.com` || origin === `http://aeroflowsleep.com`
  const noPathname = pathname === `/` || pathname === ``

  return (
    <Helmet>
      <title>{props.title}</title>
      <meta
        name={props.meta.name}
        content={props.meta.content}
      />
      {sameOrigin && noPathname && <link rel="canonical" href="https://aeroflowsleep.com/" /> }
    </Helmet>
  )
}

export default Head