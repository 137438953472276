import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { regionsQuery } from 'graphql/queries'
import { usaStates } from 'components/forms/constants'
import { getAuthHeader } from 'utils/auth/helpers'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'

export const usePayersPatientInfo = () => {
  const [ regions, setRegions ] = useState( [] )
  const [ patientData, setPatientData ] = useState({})
  const [ patientLoading, setPatientLoading ] = useState( true )
  const [ patientError, setPatientError ] = useState( undefined )

  const { data: regionsData } = useQuery( regionsQuery )

  useEffect( () => {
    let mounted = true

    async function fetchPatientData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/product-pricing`,
          {
            method: `GET`,
            headers: {
              ...addMagentoEnvAuthHeaders(),
              Authorization: getAuthHeader()
            }
          }
        )

        if ( response.status !== 200 ) {
          throw new Error( `Error Fetching Patient Details: ${response.status} - ${response.statusText}` )
        }

        const {data: {patient}} = await response.json()

        if ( mounted ) setPatientData( patient )
      } catch ( error ) {
        setPatientError( error )
      }

      if ( mounted ) setPatientLoading( false )
    }

    if ( patientLoading ) fetchPatientData()

    return () => {
      mounted = false
    }
  }, [ patientLoading ] )

  useEffect( () => {
    let mounted = true

    if ( regionsData?.countries?.length ) {
      // filter and set regions data
      let availableRegions = regionsData.countries.find( country => {
        return country.id === `US`
      })?.available_regions

      const stateCodes = usaStates.map( usaState => { return usaState.value })

      availableRegions = availableRegions.filter( region => {
        return stateCodes.includes( region.code )
      })

      if ( mounted ) setRegions( availableRegions )
    }

    return () => { mounted = false }
  }, [ regionsData ] )

  return {
    payers: patientData?.payers,
    regions,
    patientLoading,
    patientRegion: regions.find( region => { return region.code === patientData?.address?.state }) ?? {},
    patientRefetch: () => { return setPatientLoading( true ) },
    patientId: patientData?.patient_id,
    patientError
  }
}
