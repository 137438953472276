import { Formatters, TextInput } from 'components/forms/components'
import { regexErrors, usaSelectOptions } from 'components/forms/constants'
import * as React from 'react'
import { useReactiveVar } from '@apollo/client'
import { Validate } from '../validation'
import { FormPageProps } from '../types.d'
import { addressFromQualifyForm } from 'apollo'
import { trackAnalyticEvent } from 'utils/analytic'
import { getCookies } from 'utils/cookie'
import { reportToSentry } from 'utils/reportToSentry'
import { LOGIN_M2_PATH, PAP_INTAKE_MACHINE_SETUP, SLEEP_APNEA_DIAGNOSIS_PATH } from 'routes'
import LoadingSpinner from 'components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import { postLeadsQualify } from '../api'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useInsurancePayers } from 'hooks/Insurance'
import { useQualifyFormStore } from '../state'
import { useHdyhau } from 'hooks/HdyhauDropdown'
import { TermsCheckbox } from 'components/terms'
import {
  ACCOUNT_EXISTS_MSG,
  GENERIC_REQUEST_ERR_MSG,
  SELECT_YOUR_NEED_OPTIONS,
  SUBMIT_NEW_LEAD_ERR_MSG
} from '../constants'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'
import Select, { components } from 'react-select'
import { createChangeEvent, getStylesConfig, SelectInputContainer, themeConfig } from 'components/react-select'
import { capitalize } from 'lodash'
import PropTypes from 'prop-types'
import HeightTransition from 'components/heightTransition/heightTransition'

export default function Lead({ isEmbedded, ...pageProps }) {

  const { formFields, formErrors, setFormError, setFormField } = useQualifyFieldsStore()
  const [ displayErrors, setDisplayErrors ] = React.useState( false )
  const [ submitLoading, setSubmitLoading ] = React.useState( false )
  const [ displaySecondary, setDisplaySecondary ] = React.useState( false )
  const [ submitError, setSubmitError ] = React.useState( `` )
  const [ selectYourNeed, setSelectYourNeed ] = React.useState( `` )
  const {
    payers,
    payersLoading,
    payersErrors,
    refetchPayers
  } = useInsurancePayers( formFields.state, pageProps?.isSiteOffline )
  const { hdyhauData, hdyhauLoading, hdyhauError } = useHdyhau()
  // Used to pre-populate SNL address fields
  const addressBackup = useReactiveVar( addressFromQualifyForm )
  const navigate = useNavigate()

  const secondaryAnimation = useSpring({
    to: {
      maxHeight: displaySecondary ? 400 : 0,
      overflow: `hidden`
    },
    config: {
      duration: 700
    }
  })

  const inputRefs = {
    firstName: React.useRef( null ),
    lastName: React.useRef( null ),
    phoneNumber: React.useRef( null ),
    state: React.useRef( null ),
    zipCode: React.useRef( null ),
    insuranceType: React.useRef( null ),
    memberID: React.useRef( null ),
    secondaryInsuranceType: React.useRef( null ),
    secondaryMemberID: React.useRef( null ),
    hasSleepApnea: React.useRef( null ),
    hasReceivedCpapThroughInsurance: React.useRef( null ),
    howDidYouHearAboutUs: React.useRef( null )
  }

  const { setQualifyLeadResponseVars } = useQualifyFormStore()

  React.useEffect( () => {
    inputRefs.firstName.current.focus()
    window.scrollBy( 0, -50 )
  }, [] )

  const handleChange = ( event ) => {
    const { name, value } = event.target

    setSubmitError( `` )
    setDisplayErrors( false ) // hide error when user is typing
    const isRequired = getIsRequired( name )
    const errorMessage = isRequired ? Validate( name, value ) : ``
    setFormError( name, errorMessage )
    setFormField( name, value )
  }


  const handleAddressBackup = ( event ) => {
    addressFromQualifyForm({
      ...addressBackup,
      [event.target.name]: event.target.value
    })
  }


  const handleNext = ( event ) => {
    event.preventDefault()

    setDisplayErrors( true )
    if ( !formFields.terms ) {
      return setSubmitError( regexErrors.terms )
    } else {
      setSubmitError( `` )
    }

    const inputWithError = Object.keys( inputRefs ).find( name => {
      const isRequired = getIsRequired( name )
      const errorMessage = isRequired ? Validate( name, formFields[name] ) : ``
      setFormError( name, errorMessage ) // Update error messages for the untouched case of '' if required
      return Boolean( errorMessage )
    })

    if ( inputWithError ) return inputRefs[inputWithError]?.current.focus()

    handleLeadSubmit()
  }

  const handleLeadSubmit = async () => {
    setSubmitLoading( true )
    const cookieData = getCookies()

    const leadsQualifyBody = {
      form_code: `sleep_qualify_through_insurance`,
      email: formFields.username,
      first_name: formFields.firstName,
      last_name: formFields.lastName,
      phone: formFields.phoneNumber.replace( /\(|\)/g, `` ).replace( /\s/, `-` ),
      dob: `${formFields.dobYear}-${formFields.dobMonth}-${formFields.dobDay}`,
      state: formFields.state,
      zipcode: formFields.zipCode,
      insurance_primary_provider: formFields.insuranceType,
      insurance_primary_policy_number: formFields.memberID,
      has_sleep_apnea: formFields.hasSleepApnea === `yes`,
      how_did_you_hear_about_us: formFields.howDidYouHearAboutUs,
      // The referral URL is used to create the magento customer account in the correct store
      referral_url: process.env.REACT_APP_ENVIRONMENT !== `production` ? process.env.REACT_APP_MAGENTO_2_BASE_URL : window.location.href,
      utm_campaign: cookieData.utm_campaign,
      fbc: cookieData.fbclid,
      fbp: cookieData.fbp,
      fbclid: cookieData.fbclid,
      gclid: cookieData.gclid,
      msclkid: cookieData.msclkid,
      ttclid: cookieData.ttclid,
      has_received_cpap_through_insurance: formFields.hasReceivedCpapThroughInsurance,
      ...( displaySecondary && formFields.secondaryInsuranceType ) && {
        insurance_secondary_provider: formFields.secondaryInsuranceType
      },
      ...( displaySecondary && formFields.secondaryMemberID ) && {
        insurance_secondary_policy_number: formFields.secondaryMemberID
      }
    }

    const awsResponse = await postLeadsQualify( leadsQualifyBody )
      .catch( error => {
        reportToSentry( `Leads Qualify Form Request Failed`, error )
      })
      .finally( () => setSubmitLoading( false ) )

    // Due to the many different error cases, we handle the errors in a separate function
    const submitErrorString = handleSubmitErrors( awsResponse )
    if ( submitErrorString?.length ) {
      console.error( submitErrorString )
      return setSubmitError( submitErrorString )
    }

    const { qualify_process_id, form_code, reset_password_token } = awsResponse.data

    // Saving these values to the client state so we can use them in the next step of the qualify form
    setQualifyLeadResponseVars( qualify_process_id, form_code, reset_password_token )
    trackAnalyticEvent( `qualifyLeadSubmit` )

    if ( formFields.hasSleepApnea === `no` ) {
      if ( isEmbedded ) return window?.parent?.postMessage( `Lead Submit Not Eligible`, `*` )
      return navigate( SLEEP_APNEA_DIAGNOSIS_PATH )
    }

    // this would indicate a pap-intake patient
    if ( !formFields.hasReceivedCpapThroughInsurance && formFields.hasSleepApnea === `yes` ) {
      return navigate( PAP_INTAKE_MACHINE_SETUP )
    }
    return pageProps.nextPage()
  }

  const handleSubmitErrors = ( awsResponse, additionalDetails ) => {
    if ( !awsResponse ) {
      reportToSentry( `Leads Qualify Form Request Failed`, additionalDetails )
      return GENERIC_REQUEST_ERR_MSG
    }

    if ( awsResponse?.errors?.length ) {
      reportToSentry( `Error submitting new lead, AWS error Response`, {
        responseMeta: JSON.stringify( awsResponse?.meta ),
        responseErrors: [ ...awsResponse.errors ]
      })

      // phone number is validated on the backend. If validation fails for phone give more helpful message
      const isPhoneError = ( awsResponse.errors[0]?.message && awsResponse.errors[0]?.context?.key === `phone` )
      if ( isPhoneError ) {
        return `The phone number you entered is invalid. Please enter a valid phone number and try again...`
      }
      return `An error occurred when submitting your request. If this issue persists, please contact customer support at ${AF_SUPPORT_PHONE_NUMBER}`
    }

    // This happens when we already have their account in the system, so we need to redirect to login
    if ( awsResponse?.data?.magento_customer_status === `UPDATED` ) {
      window.alert( ACCOUNT_EXISTS_MSG )

      navigate( LOGIN_M2_PATH )
      return ACCOUNT_EXISTS_MSG
    }

    const keyResponseValuesExist = awsResponse?.data?.qualify_process_id && awsResponse?.data?.form_code && awsResponse?.data?.reset_password_token && awsResponse.data?.magento_customer_status
    const invalidResponseStatus = ( awsResponse?.data?.magento_customer_status !== `CREATED` && awsResponse?.data?.magento_customer_status !== `UPDATED` )
    if ( !keyResponseValuesExist || invalidResponseStatus ) {
      reportToSentry( new Error( SUBMIT_NEW_LEAD_ERR_MSG ), {
        requestDetails: {
          awsResponseData: JSON.stringify( awsResponse.data )
        }
      })

      // if offline but lead was successfully sent OR there is a successful lead generation on internal, but SQL/AC are not talking to each other
      if ( ( pageProps.isSiteOffline || invalidResponseStatus ) && awsResponse.meta.status === `OK` ) {
        trackAnalyticEvent( `qualifyLeadSubmit` )
        return pageProps.onHandleOfflineSubmit()
      }

      return GENERIC_REQUEST_ERR_MSG
    }

    return ``
  }

  const getIsRequired = ( name ) => {
    const selectRef = inputRefs[name]?.current?.props
    return inputRefs[name]?.current?.required || ( Boolean( selectRef ) && selectRef?.required )
  }

  const handleStateChange = ( newValue, actionMeta ) => {
    if ( formFields?.insuranceType ) setFormField( `insuranceType`, `` )
    if ( formFields?.secondaryInsuranceType ) setFormField( `secondaryInsuranceType`, `` )
    refetchPayers()
    const isClearEvent = actionMeta.action === `clear` || !newValue
    const e = createChangeEvent( `state`, isClearEvent ? `` : newValue.value )
    handleChange( e )
    handleAddressBackup( e )
  }

  const handleBlur = () => {
    setDisplayErrors( true )
  }

  const canRenderStatePayerList = payers?.length && !payersErrors && !payersLoading
  const shouldHideHdyhau = pageProps.isSiteOffline ? false : ( getCookies().length || !hdyhauData?.length || hdyhauError )

  // get placeholder text for primary and secondary insurance types and ids
  const getInsurancePlaceholder = ( insuranceType, insuranceField ) => {
    if ( !formFields.state ) return `Please Select a State`
    else if ( payersLoading ) return `Loading ${insuranceType}...`
    else if ( payersErrors?.length ) return `Error Retrieving ${insuranceType}...`
    return `${insuranceType} ${insuranceField}`
  }

  // custom input component for react-select, using to add data-testid attributes for lead gen automated tests
  const DataAttributeInput = ({ testId, ...props }) => {
    return (
      <components.Input
        {...props}
        data-testid={testId}
      />
    )
  }

  const getCustomDropdownStyle = () => {
    return {
      dropdownIndicator: ( displayErrors, base ) => ({
        ...base,
        color: displayErrors ? `text-error` : `#B2B2B2`
      })
    }
  }

  DataAttributeInput.propTypes = {
    testId: PropTypes.string
  }

  const labelClassNames = `input-label text-left sm:font-medium md:font-normal sm:text-sm md:text-xl text-graphite !p-0 text-left sm:mb-1 md:mb-3 mr-2 block`
  const inputContainerClassNames = `basis-[calc(50%-48px)] sm:pb-6 md:pb-14`

  return (
    <HeightTransition>
      <div className="md:flex md:justify-between flex-wrap sm:px-0 md:px-2 lg:px-1 pt-6">
        <div className={inputContainerClassNames}>
          <TextInput
            name="firstName"
            value={formFields.firstName}
            errorMessage={displayErrors && formErrors.firstName}
            reference={inputRefs.firstName}
            placeholder="First Name"
            label="First Name"
            labelClassName={labelClassNames}
            autoComplete="given-name"
            required
            onBlur={handleBlur}
            onChange={handleChange}
            data-testid="first_name"
          />
        </div>
        <div className={inputContainerClassNames}>
          <TextInput
            name="lastName"
            value={formFields.lastName}
            errorMessage={displayErrors && formErrors.lastName}
            reference={inputRefs.lastName}
            placeholder="Last Name"
            label="Last Name"
            labelClassName={labelClassNames}
            autoComplete="family-name"
            required
            onBlur={handleBlur}
            onChange={handleChange}
            data-testid="last_name"
          />
        </div>
        <div className={inputContainerClassNames}>
          <TextInput
            name="phoneNumber"
            label="Phone Number"
            type="tel"
            labelClassName={labelClassNames}
            placeholder="Phone Number"
            value={formFields.phoneNumber}
            errorMessage={displayErrors && formErrors.phoneNumber}
            formatter={{
              function: Formatters.phone
            }}
            reference={inputRefs.phoneNumber}
            autoComplete="tel"
            required
            onBlur={handleBlur}
            onChange={handleChange}
            data-testid="phone"
            onKeyDown={( event ) => {
              if ( event.key === `Tab` && !event.shiftKey ) {
                event.preventDefault()
                inputRefs.state.current.focus()
              }
            }}
          />
        </div>
        <div className={inputContainerClassNames}>
          <SelectInputContainer
            label="State"
            errorMessage={displayErrors && formErrors.state}
            required
            labelClassName={labelClassNames}
          >
            <Select
              name="state"
              value={usaSelectOptions.find( o => o.value === formFields.state )}
              onInputChange={( inputValue ) => { // We need to account for the case the user types in a full state
                const foundSelectOption = usaSelectOptions.find( o => o.label.toLowerCase() === inputValue.toLowerCase() )
                if ( foundSelectOption ) {
                  handleStateChange( foundSelectOption, {
                    action: `select-option`
                  })
                  inputRefs.zipCode.current.focus()
                  setDisplayErrors( false )
                }
              }}
              onChange={handleStateChange}
              onKeyDown={( event ) => {
                setDisplayErrors( false )
                if ( event.key === `Tab` && !event.shiftKey ) {
                  event.preventDefault()
                  inputRefs.zipCode.current.focus()
                }
              }}
              onBlur={handleBlur}
              options={usaSelectOptions}
              isClearable
              backspaceRemovesValue
              isSearchable
              required
              placeholder="Select a state..."
              ref={inputRefs.state}
              styles={{
                ...getStylesConfig( displayErrors && formErrors.state ),
                ...getCustomDropdownStyle( displayErrors )
              }}
              theme={themeConfig}
              autoComplete="address-level1"
              components={{
                Input: ( props ) => <DataAttributeInput {...props} testId="state" />
              }}
            />
          </SelectInputContainer>
        </div>
        <div className={inputContainerClassNames}>
          <TextInput
            name="zipCode"
            type="number"
            value={formFields.zipCode}
            errorMessage={displayErrors && formErrors.zipCode}
            reference={inputRefs.zipCode}
            placeholder="Zip Code"
            label="Zip Code"
            labelClassName={labelClassNames}
            autoComplete="postal-code"
            required
            onBlur={handleBlur}
            onChange={( event ) => {
              handleAddressBackup( event )
              handleChange( event )
            }}
            formatter={{
              function: Formatters.zipCode
            }}
            data-testid="zipcode"
            onKeyDown={( event ) => {
              if ( event.key === `Tab` && !event.shiftKey ) {
                event.preventDefault()
                inputRefs.insuranceType.current.focus()
              }
            }}
          />
        </div>
        <div className={`${inputContainerClassNames} relative`}>
          <SelectInputContainer
            label="Insurance Type"
            errorMessage={displayErrors && formErrors.insuranceType}
            required
            labelClassName={labelClassNames}
          >
            <span className="sm:hidden lg:block absolute left-44">{`Based on state selection`}</span>
            <Select
              key={`${formFields.state}-primary`} // A key is needed to reset the select options when the state changes
              aria-label="Insurance Type"
              name="insuranceType"
              disabled={!canRenderStatePayerList}
              placeholder={getInsurancePlaceholder( `Insurance`, `Type` )}
              value={formFields.insuranceType && {
                value: formFields.insuranceType,
                label: formFields.insuranceType
              }}
              isLoading={formFields.state && payersLoading}
              onChange={( c ) => handleChange( createChangeEvent( `insuranceType`, c.value ) )}
              required
              ref={inputRefs.insuranceType}
              onClick={() => {
                setDisplayErrors( false )
              }}
              options={!formFields.state ? [] : payers?.map( o => ({
                value: o.friendly_name,
                label: o.friendly_name
              }) )}
              noOptionsMessage={() => !formFields.state ? `Select a State to Load Options...` : `No options available...`}
              menuPosition="fixed"
              styles={getStylesConfig( displayErrors && formErrors.insuranceType )}
              theme={themeConfig}
              components={{
                Input: ( props ) => <DataAttributeInput {...props} testId="insurance_type" />
              }}
              onKeyDown={( event ) => {
                if ( event.key === `Tab` && !event.shiftKey ) {
                  event.preventDefault()
                  inputRefs.memberID.current.focus()
                }
              }}
            />
          </SelectInputContainer>
        </div>
        <div className={inputContainerClassNames}>
          <TextInput
            name="memberID"
            value={formFields.memberID}
            errorMessage={displayErrors && formErrors.memberID}
            reference={inputRefs.memberID}
            placeholder={getInsurancePlaceholder( `Insurance`, `ID` )}
            label="Insurance ID"
            labelClassName={labelClassNames}
            required
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={!canRenderStatePayerList}
            data-testid="member_id"
          />
        </div>
        <div className={inputContainerClassNames}>
          <div
            className="flex items-center cursor-pointer hover:opacity-70 mb-3"
            onClick={() => {
              setDisplaySecondary( !displaySecondary )
            }}
          >
            <p className="text-left text-deepSleepBlue !font-semibold sm:text-lg lg:text-2xl input-label">
              {displaySecondary ? `Remove Secondary Insurance Type` : `Add Secondary Insurance Type`}
            </p>
            <span
              className="input-label self-start text-deepSleepBlue !font-semibold sm:text-lg lg:text-2xl"
            >{displaySecondary ? `-` : `+`}</span>
          </div>
          <animated.div style={secondaryAnimation}>
            <SelectInputContainer
              label="Secondary Insurance Type"
              errorMessage={displayErrors && formErrors.secondaryInsuranceType}
              required
              labelClassName={labelClassNames}
            >
              <Select
                key={`${formFields.state}-secondary`} // A key is needed to reset the select options when the state changes
                aria-label="Secondary Insurance Type"
                name="secondaryInsuranceType"
                disabled={!canRenderStatePayerList}
                placeholder={getInsurancePlaceholder( `Secondary Insurance`, `Type` )}
                onChange={( c ) => handleChange( createChangeEvent( `secondaryInsuranceType`, c.value ) )}
                required={displaySecondary}
                isLoading={formFields.state && payersLoading}
                ref={inputRefs.secondaryInsuranceType}
                onClick={() => {
                  setDisplayErrors( false )
                }}
                onFocus={() => {
                  setDisplaySecondary( true )
                }}
                value={formFields.secondaryInsuranceType && {
                  value: formFields.secondaryInsuranceType,
                  label: formFields.secondaryInsuranceType
                }}
                options={!formFields.state ? [] : payers?.map( o => ({
                  value: o.friendly_name,
                  label: o.friendly_name
                }) )}
                noOptionsMessage={() => !formFields.state ? `Select a State to Load Options...` : `No options available...`}
                menuPosition="fixed"
                styles={getStylesConfig( displayErrors && formErrors.secondaryInsuranceType )}
                theme={themeConfig}
                components={{
                  Input: ( props ) => <DataAttributeInput {...props} testId="insurance_type" />
                }}
                className="mb-8"
                onKeyDown={( event ) => {
                  if ( event.key === `Tab` && !event.shiftKey ) {
                    event.preventDefault()
                    inputRefs.secondaryMemberID.current.focus()
                  }
                }}
              />
            </SelectInputContainer>

            <TextInput
              name="secondaryMemberID"
              value={formFields.secondaryMemberID}
              errorMessage={displayErrors && formErrors.secondaryMemberID}
              reference={inputRefs.secondaryMemberID}
              placeholder={getInsurancePlaceholder( `Secondary Insurance`, `ID` )}
              label="Secondary Insurance ID"
              labelClassName={labelClassNames}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={!canRenderStatePayerList}
              onFocus={() => {
                return setDisplaySecondary( true )
              }}
              required={displaySecondary}
              data-testid="member_id"
              onKeyDown={( event ) => {
                // collapse secondary insurance fields if tabbed through with no values
                if ( event.key === `Tab` && !event.shiftKey && ( !formFields.secondaryInsuranceType && !formFields.secondaryMemberID ) ) {
                  event.preventDefault()
                  setDisplaySecondary( false )
                }
              }}
            />
          </animated.div>
        </div>
        <div className={inputContainerClassNames}>
          {/* select your need dropdown will control the has_sleep_apnea and has_received_cpap_through_insurance properties in leadsQualifyBody */}
          <SelectInputContainer
            label="Select Your Need"
            errorMessage={displayErrors && formErrors.hasSleepApnea}
            required
            labelClassName={labelClassNames}
          >
            <Select
              aria-label="Select Your Need"
              name="selectYourNeed"
              value={selectYourNeed && {
                value: selectYourNeed,
                label: capitalize( selectYourNeed )
              }}
              onChange={( c ) => {
                setSelectYourNeed( c.value )

                const diagnosedWithSleepApnea = c.value !== SELECT_YOUR_NEED_OPTIONS.neverDiagnosedWithSleepApnea
                const hasReceivedCpap = c.value === SELECT_YOUR_NEED_OPTIONS.newSuppliesOrReplacement

                handleChange( createChangeEvent( `hasSleepApnea`, diagnosedWithSleepApnea ? `yes` : `no` ) )
                handleChange( createChangeEvent( `hasReceivedCpapThroughInsurance`, hasReceivedCpap ) )

              }}
              required
              ref={inputRefs.hasSleepApnea}
              onClick={() => setDisplayErrors( false )}
              options={
                Object.values( SELECT_YOUR_NEED_OPTIONS ).map( s => ({
                  value: s,
                  label: s
                })
                )
              }
              styles={getStylesConfig( displayErrors && formErrors.hasSleepApnea )}
              theme={themeConfig}
              components={{
                Input: ( props ) => <DataAttributeInput {...props} testId="sleep_apnea" />
              }}
              onKeyDown={( event ) => {
                if ( event.key === `Tab` && !event.shiftKey ) {
                  event.preventDefault()
                  inputRefs.howDidYouHearAboutUs.current.focus()
                }
              }}
            />
          </SelectInputContainer>
        </div>
        <div className={inputContainerClassNames}>
          {shouldHideHdyhau ? null : (
            <SelectInputContainer
              label="How did you hear about us?"
              errorMessage={displayErrors && formErrors.howDidYouHearAboutUs}
              required
              labelClassName={labelClassNames}
            >
              <Select
                name="howDidYouHearAboutUs"
                options={hdyhauData?.map( o => ({
                  value: o,
                  label: o
                }) )}
                value={formFields.howDidYouHearAboutUs && {
                  value: formFields.howDidYouHearAboutUs,
                  label: formFields.howDidYouHearAboutUs
                }}
                isLoading={hdyhauLoading}
                ref={inputRefs.howDidYouHearAboutUs}
                onClick={() => {
                  setDisplayErrors( false )
                }}
                onChange={( c ) => handleChange( createChangeEvent( `howDidYouHearAboutUs`, c.value ) )}
                required
                styles={getStylesConfig( displayErrors && formErrors.howDidYouHearAboutUs )}
                theme={themeConfig}
                components={{
                  Input: ( props ) => <DataAttributeInput {...props} testId="how_did_you_hear" />
                }}
              />
            </SelectInputContainer>
          )}
        </div>
        <div className="mx-auto bg-[#cce9f44d] rounded-md w-full max-w-[546px] px-6">
          <TermsCheckbox
            checked={formFields.terms}
            onChange={( event ) => {
              setFormField( `terms`, event.target.checked )
            }}
          />
        </div>

        <p className="text-error text-sm w-full text-center pt-4">{submitError}</p>

        <div className="mx-auto basis-full sm:mt-5 md:mt-14 mb-12 flex flex-col justify-center items-center">
          <button
            className="btn-primary text-center sm:max-w-72 md:max-w-[420px]"
            onClick={handleNext}
            disabled={submitLoading}
            data-testid="submit"
          >
            {submitLoading ? <LoadingSpinner height="2.5rem" width="2.5rem" rings={3} /> : `Submit`}
          </button>
        </div>
      </div>
    </HeightTransition>
  )
}

Lead.propTypes = FormPageProps