import { SNL_BASE_PATH } from 'modules/sleepNewLeads/constants'

export const sleepApneaProfileCopy = {
  notCompletedStepHeading: `Sleep Apnea Profile Incomplete`,
  currentStepDetails: `In order to provider you an estimated cost through insurance, complete your sleep apnea profile so that we know exactly which CPAP Supplies you'd like to order.`,
  completedStepHeading: `Sleep Apnea Profile Complete`,
  stepLinkText: `Complete profile`,
  stepLinkUrl: SNL_BASE_PATH
}

export const insuranceInformationCopy = {
  notCompletedStepHeading: `Insurance Information Not Verified`,
  currentStepDetails: `We're having an issue verifying your insurance information. Make sure your name and member ID matches your insurance card. Please allow some time after updating before checking back.`,
  stepLinkText: `View/change your insurance information`,
  stepLinkUrl: `#my-account-insurance`,
  stepToolTip: `We must verify that your insurance information is correct and that we are in network with your provider before we can move forward.`,
  completedStepHeading: `Insurance Information Verified`
}

export const prescriptionNeededCopy = {
  notCompletedStepHeading: `Obtain Documentation from MD`,
  currentStepDetails: `Our representatives are working to obtain all medical documentation that is required by your insurance provider.`,
  stepLinkText: `View/change your MD information`,
  stepLinkUrl: `#my-account-physician`,
  stepToolTip: `Your insurance provider requires that we obtain certain medical documentation before we can ship out your order.`,
  completedStepHeading: `Documentation from MD Received`
}

export const insurancePriorAuthCopy = {
  notCompletedStepHeading: `Prior Authorization Needed`,
  currentStepDetails: `Your insurance provider requires prior authorization before we can ship your order. Our representatives are working to obtain this. Please reference your email for important updates.`,
  stepToolTip: `Your insurance provider requires prior authorization before we can ship out your order.`,
  completedStepHeading: `Prior Authorization Obtained`
}