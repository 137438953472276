import React from 'react'
import PropTypes from 'prop-types'

const LastOrderSuppliesSelector = ({
  heading,
  name,
  options,
  onChange,
  selectedValue,
  showOnMobile
}) => {

  return (
    <>
      {showOnMobile && <div className="mt-4">
        <div className="text-center text-xl mb-4">{heading}</div>
        <div className="flex flex-col items-center justify-center mt-4 gap-3 max-w-xs mx-auto">
          {
            options.map( ( item ) => {
              return (
                <button
                  name={name}
                  value={item.value}
                  onClick={onChange}
                  className={selectedValue === item.value ? `btn-secondary-selected` : `btn-secondary`}
                  key={item.label}
                >
                  { item.label }
                </button>
              )
            })
          }
        </div>
        <div className="text-center my-4 text-sm text-graphite">
          {`Your health insurance covers the cost of supplies on a specific schedule. If it has been more than 7 months since you last got supplies, you may be eligible for fresh supplies now through insurance.`}
        </div>
      </div>}
    </>
  )
}

LastOrderSuppliesSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  defaultLabel: PropTypes.string,
  showOnMobile: PropTypes.bool
}

export default LastOrderSuppliesSelector
