import { useState } from 'react'

type StepCopyTypes = {
    notCompletedStepHeading?: string;
    currentStepDetails?: string;
    stepLinkText?: string;
    stepLinkUrl?: string;
    stepToolTip?: string;
    completedStepHeading: string;
    shippingLink?: string;
    stepErrorMsg?: string;
    completedStepDetails?: string;
    openLinkInNewTab?: boolean;
    completedStepLinkText?: string;
    completedStepLinkUrl?: string;
  };

type StepContentRendererProps = {
    stepStatus: boolean;
    isCurrentStep?: boolean;
    stepCopy: StepCopyTypes;
    StepContentRendererClasses?: string;
  };

function StepContentRenderer( props: StepContentRendererProps ) : JSX.Element {
  const {
    stepStatus,
    isCurrentStep,
    stepCopy,
    StepContentRendererClasses
  } = props

  const [ showToolTip, setShowToolTip ] = useState( false )

  //  smooth scroll when linking to account tiles
  const handleHashLink = ( event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {
    // check if the link is a hash link
    if ( stepCopy.stepLinkUrl?.charAt( 0 ) === `#` ) {
      event.preventDefault()
      const tile = document.getElementById( stepCopy.stepLinkUrl.slice( 1 ) )
      tile?.scrollIntoView({
        behavior: `smooth`
      })
    }
  }

  return (
    <div className={`pl-5 pb-9 min-h-[70px] flex-1 ${StepContentRendererClasses || ``}`}>

      {/* Active step - Header + body text + link(if applicable) */}
      { isCurrentStep && (
        <div>
          {stepCopy?.notCompletedStepHeading && (
            <p className="text-xl text-stone-800 font-medium pb-3 pt-[18px]">
              {stepCopy.notCompletedStepHeading}
            </p>
          )}

          {stepCopy?.currentStepDetails && (
            <p className="text-sm pb-2">
              {stepCopy.currentStepDetails}
            </p>
          )}

          {stepCopy?.stepLinkText && stepCopy?.stepLinkUrl && (
            <a
              href={stepCopy.stepLinkUrl}
              target={stepCopy.openLinkInNewTab ? `_blank` : `_self`}
              rel={stepCopy.openLinkInNewTab ? `noreferrer` : ``}
              className="text-sm text-[#0093C9] font-medium a"
              onClick={handleHashLink}
            >
              {stepCopy.stepLinkText}
            </a>
          )}
        </div>
      )}

      {/* Upcoming step - Heading + Tooltip */}
      {!stepStatus && !isCurrentStep && (
        <div>
          <div className="flex">
            {stepCopy?.notCompletedStepHeading && (
              <>
                <p className="text-[#868686] pt-2.5 shrink">{stepCopy.notCompletedStepHeading}</p>
                <div className="text-small cursor-pointer ml-2 self-start pt-3.5">
                  {stepCopy?.stepToolTip && (
                    <div onClick={() => setShowToolTip( !showToolTip )} className="border rounded-full h-4 w-4 flex justify-center items-center border-[#0093C9]">
                      <p className="text-sm font-serif italic text-[#0093C9]">{`i`}</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {showToolTip && stepCopy?.stepToolTip && (
            <p className="text-xs text-[#595959]">{stepCopy.stepToolTip}</p>
          )}
        </div>
      )}

      {/* Completed step - Heading + body text + link(if applicable) */}
      {stepStatus && (
        <div>
          {stepCopy?.completedStepHeading && (
            <p className="text-stone-800 pt-2.5">
              {stepCopy.completedStepHeading}
            </p>
          )}

          {stepCopy?.completedStepDetails && (
            <p className="text-sm pb-2">
              {stepCopy.completedStepDetails}
            </p>
          )}

          {stepCopy?.completedStepLinkText && stepCopy?.completedStepLinkUrl && (
            <a
              href={stepCopy.completedStepLinkUrl}
              target={stepCopy.openLinkInNewTab ? `_blank` : `_self`}
              rel={stepCopy.openLinkInNewTab ? `noreferrer` : ``}
              className="text-sm text-[#0093C9] font-medium a"
            >
              {stepCopy.completedStepLinkText}
            </a>
          )}

        </div>
      )}

      {/* Error message */}
      {isCurrentStep && stepCopy?.stepErrorMsg && (
        <p className="text-sm text-red-700">
          {stepCopy.stepErrorMsg}
        </p>
      )}
    </div>
  )
}

export default StepContentRenderer