import React, { useState, useRef } from 'react'
import { SelectInput, TextInput } from 'components/forms/components'
import { ranges } from '../utils/constants'
import { Formatters, Validators } from 'components/forms/components/utils'
import { useDoctorInput } from '../hooks/state'
import { regexErrors } from 'components/forms/constants'


type LocationChangeProps = {
  setZipDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

type EditLocation = {
  zipCode: string;
  range: number;
}

function LocationChangeForm({ setZipDisplay } : LocationChangeProps ) : JSX.Element {

  const [ doctorInput, setDoctorInput ] = useDoctorInput()
  const [ editedDoctorInput, setEditedDoctorInput ] = useState<EditLocation>({
    range: doctorInput.range,
    zipCode: doctorInput.zipCode
  })
  const [ zipError, setZipError ] = useState<string>( `` )

  const zipRef = useRef<HTMLInputElement>( null )

  const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setZipError( `` )
    setEditedDoctorInput( Object.assign({}, editedDoctorInput, {
      [e.target.name]: e.target.value
    }) )
  }

  const onSave = () => {
    if ( !editedDoctorInput?.zipCode?.length ) {
      setZipError( regexErrors.zip )

      return zipRef?.current?.focus()
    } else if ( zipError ) return zipRef?.current?.focus()
    setDoctorInput( Object.assign({}, doctorInput, editedDoctorInput ) )
    setZipDisplay( false )
  }

  const onBlur = ( e: React.FocusEvent<HTMLInputElement> ) => {
    if ( !Validators.zipCode( e.target.value ) ) return setZipError( regexErrors.zip )
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="styled-select-arrow-container">
        <SelectInput
          name="range"
          label="Location"
          type="select"
          value={editedDoctorInput.range}
          onChange={handleChange}
        >
          {
            ranges.map( range => {
              return <option key={range} value={range}>{`Within ${range} mile${ range > 1 ? `s` : ``}`}</option>
            })
          }
        </SelectInput>
      </div>
      <TextInput
        name="zipCode"
        value={editedDoctorInput.zipCode}
        label="Zip Code"
        errorMessageClassName="max-w-xs text-error text-center text-sm font-light mx-auto"
        onChange={handleChange}
        reference={zipRef}
        formatter={{
          function: Formatters.zipCode
        }}
        onBlur={onBlur}
        errorMessage={zipError}
      />
      <div className={`flex flex-col items-center gap-3 ${!zipError ? `mt-5` : `mt-2`}`}>
        <button
          className="btn-secondary"
          onClick={onSave}
        >
          {`Submit`}
        </button>
        {
          doctorInput.zipCode &&
          <button
            className="btn-primary"
            onClick={() => { setZipDisplay( false ) }}
          >
            {`Back`}
          </button>
        }
      </div>
    </div>
  )
}

export default LocationChangeForm