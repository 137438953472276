import React from 'react'
import { ColWrapper, ColHeader } from './tailwindWrappers'
import ContactBlock from './ContactBlock'


const SocialsCol = () => {
  return (
    <ColWrapper>
      <div>
        <ColHeader>{`Contact Us`}</ColHeader>
        <ContactBlock />
      </div>
    </ColWrapper>
  )
}

export default SocialsCol
