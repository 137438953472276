
import React, { useEffect, useState } from 'react'
import { useTransition, animated as a, useSpringRef } from '@react-spring/web'
import { SpringValue } from 'react-spring'

type ContentSliderProps = {
  content: React.ReactNode[],
  interval?: number,
  containerStyle?: string,
  aDivStyle?: string,
  endContent?: React.ReactNode;
  triggerHeightChange?: () => void;
}

function ContentStacker({
  content,
  interval = 8,
  containerStyle = `relative overflow-hidden p-10 w-full`,
  aDivStyle = ``,
  endContent,
  triggerHeightChange
}: ContentSliderProps ) {

  const [ contentIndex, setContentIndex ] = useState<number>( 0 )
  const [ enteredContent, setEnteredContent ] = useState<React.ReactNode[]>( [] )

  const animate = () => {
    if ( contentIndex < content.length ) {
      setContentIndex( contentIndex+1 )
      if ( triggerHeightChange ) triggerHeightChange()
    }
  }

  const transApi = useSpringRef()
  const transitions = useTransition( contentIndex, {
    ref: transApi,
    order: [ `leave`, `enter`, `update` ],
    from: {
      overflow: `hidden`,
      transform: `translate3d(100%,0,0)`
    },
    enter: {
      overflow: `hidden`,
      transform: `translate3d(0%,0,0)`,
      height: `fit-content`,
      zIndex: 1000
    },
    onRest: () => {
      if ( content[contentIndex ] ) {
        const addItem = enteredContent
        addItem.push( content[contentIndex ] )
        setEnteredContent( addItem )
      }
    }
  })

  useEffect( () => {
    transApi.start()

    // rotate messages every (interval) seconds
    const contentStackInterval = setInterval( () => {
      animate()
    }, interval * 1000 )

    return () => {
      clearInterval( contentStackInterval )
    }
  }, [ content ] )

  return (
    <div className={containerStyle}>
      {
        enteredContent.map( content => {
          return content
        })
      }
      {
        transitions( ( style: {[key: string]: SpringValue }) => {
          return (
            <a.div style={style} className={aDivStyle}>
              {
                content[contentIndex]
              }
            </a.div>
          )
        })
      }
      {
        endContent &&
        <div className={`transition-opacity duration-1000 ${content.length === enteredContent.length ? `opacity-100` : `opacity-0 pointer-events-none`}`}>
          {
            endContent
          }
        </div>
      }
    </div>
  )
}

export default ContentStacker