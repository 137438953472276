import { Modal } from 'components/modal'
import React from 'react'
import OrderItemsContainer from 'components/account/ResupplyOrderHistory/OrderItemsContainer'
import { formatDate } from '../utils'

type Order = {
  order: {
    order_date: string;
    order_number: number;
    items: {
      item_name: string;
      status: string;
      url: string;
    }[]
  }
}

type OrderDetailsProps = {
  orderDetails: Order | null;
  setOrderDetails: React.Dispatch<React.SetStateAction<Order | null>>;
}

function OrderDetails({ orderDetails, setOrderDetails } : OrderDetailsProps ) : JSX.Element{
  return (
    <Modal
      isOpen={Boolean( orderDetails )}
      onClose={() => { setOrderDetails( null ) }}
      closeOnFocusChange
      defaultCloseButton
      initialClose
    >
      <div>
        {
          orderDetails &&
            <div className="flex flex-col gap-3 mb-5 items-center flex-1">
              <p className="mb-5 font-bold">{`Order Details`}</p>
              <p>{`Order Number: ${orderDetails.order.order_number}`}</p>
              <p>{`Order Created: ${formatDate( orderDetails.order.order_date )}`}</p>
              {
                orderDetails.order?.items?.length > 0 &&
                <p className="capitalize">{`Order Status: ${orderDetails.order.items[0].status}`}</p>
              }
              <OrderItemsContainer items={orderDetails.order.items} hideExpandCollapse />
            </div>
        }
      </div>
    </Modal>
  )
}

export default OrderDetails