import { Address } from "../types"
import Highlight from "./Highlight"

/* Note: highlightedComponents is no longer used, we use the HighlightP component now - but keeping for backwards compatibility */
export default function AddressP({address, highlightedComponents, className, shouldHideSubpremise } : { address: Address, highlightedComponents?: string[], className?: string, shouldHideSubpremise?: boolean}) : JSX.Element {
  const { street, city, state, zipCode } = address

  const subpremise = shouldHideSubpremise ? `` : address.street2

  return (
    <p className={className ?? `text-left`}>
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!highlightedComponents?.includes( `address1` )}>{street}</Highlight>{`, `}
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!highlightedComponents?.includes( `address2` )}>{`${subpremise ?? ``}`}</Highlight>{subpremise ? `, ` : ``}
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!highlightedComponents?.includes( `city` )}>{city}</Highlight>{`, `}
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!highlightedComponents?.includes( `state` )}>{state}</Highlight>{` `}
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!highlightedComponents?.includes( `zip` )}>{zipCode}</Highlight>
    </p>
  )
}