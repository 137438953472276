import React from 'react'
import tailwindColors from 'config/tailwindColors'
import { select } from 'd3-selection'
import { transition } from 'd3-transition'
import { interpolate } from 'd3-interpolate'
import PropTypes from 'prop-types'

function ProgressAnimation({svgSize, progressRef, completed, arcGenerator}) {

  const g = select( progressRef.current )
    .append( `path` )
    .datum({
      endAngle: 0
    })

  const tween = ( newAngle, arcGenerator ) => {
    return ( d ) => {
      const customInterpolate = interpolate( d.endAngle, newAngle )

      return ( t ) => {
        d.endAngle = customInterpolate( t )

        return arcGenerator( d )
      }
    }
  }

  const triggerTransition = ( val ) => {
    g.transition( transition().duration( 800 ) )
      .attrTween( `d`, tween( ( val * Math.PI * 2 ) / 100, arcGenerator ) )
  }

  triggerTransition( completed )

  return (
    <g transform={`translate(${svgSize/ 2}, ${svgSize / 2})`} ref={progressRef} fill={tailwindColors.deepSleepBlue}></g>
  )
}

ProgressAnimation.propTypes = {
  svgSize: PropTypes.number,
  progressRef: PropTypes.object,
  completed: PropTypes.number,
  arcGenerator: PropTypes.func
}

export default ProgressAnimation
