import { Link } from 'react-router-dom'
import * as React from 'react'
import { LOGIN_PATH } from 'routes'
import { icoPersonWavingBlue } from 'resources/images'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'

interface EmailUnavailableDialogueProps {
  registeredEmailUrlParam?: string,
  isEmbedded?: boolean
}

export default function EmailUnavailableDialogue({registeredEmailUrlParam, isEmbedded} : EmailUnavailableDialogueProps ): JSX.Element {
  // default to base path if no param is passed
  const buildPath = ( base: string, param?: string ) => param ? `${base}${param}` : base
  const loginPath = buildPath( LOGIN_PATH, registeredEmailUrlParam )

  const mediaQueryList = React.useMemo( () => { return window.matchMedia( `(max-width: 767px)` ) }, [] )
  const [ isMobile, setIsMobile ] = React.useState( mediaQueryList.matches )

  const { formFields } = useQualifyFieldsStore()

  const handleResize = () => {
    setIsMobile( mediaQueryList.matches )
  }

  React.useEffect( () => {
    handleResize()

    mediaQueryList.addListener( handleResize )
    return () => mediaQueryList.removeListener( handleResize )
  }, [ mediaQueryList ] )

  const loginButton = (
    <button className="btn btn-primary block sm:mx-auto md:mx-0 sm:max-w-64 md:max-w-none">
      <Link to={loginPath} target={isEmbedded ? `_blank` : `_self`}>{`Log in to your account`}</Link>
    </button>
  )

  return (
    <div className="px-2 text-left mx-auto">
      <div className="flex justify-center align-middle sm:items-end md:items-stretch sm:pb-10 md:pb-0">
        <img className="sm:min-w-[75px] md:min-w-[136px]" src={icoPersonWavingBlue} alt="person waving blue" />
        <div className="sm:pl-1 md:pl-6 h-full flex flex-col justify-between">
          {formFields.username ?
            <p className="mb-auto sm:text-base md:text-[22px] text-deepSleepBlue font-medium md:pb-1 break-all">
              {`Hey ${formFields.username},`}
            </p> : null}
          <p className="mb-auto sm:text-base md:text-[22px] md:pb-4">{`You’re already in our system!`}</p>
          {!isMobile ? loginButton : null}
        </div>
      </div>
      {isMobile ? loginButton : null}
    </div>
  )
}