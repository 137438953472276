import * as React from 'react'
import PropTypes from 'prop-types'
import { ALink } from 'components/ALinks'
import { icoAssignmentTurnedIn, icoAwaitingOrder, icoCancelScheduleSend } from 'resources/images'

export default function OrderItem({item_name, status, url, ship_date}) {

  const shipDate = new Date( ship_date )
  const statusClass = status === `Shipped` ? `text-success` : status === `Cancelled` ? `text-error` : `text-deepSleepBlue`

  return (
    <div className="p-4">
      <div className="flex">
        <img width={24} src={status === `Shipped` ? icoAssignmentTurnedIn : status === `Cancelled` ? icoCancelScheduleSend : icoAwaitingOrder} />
        <p className="pl-2 font-bold text-deepSleepBlue">{item_name}</p>
      </div>
      <div className="flex justify-center gap-2">
        <span className={statusClass}>{status}</span>
        <span>{status === `Shipped` && ship_date ? ` on ${shipDate.toLocaleDateString( `en-US` )} ` : ` `}</span>
        {status === `Shipped` && url && <ALink href={url} target="blank">{`Track`}</ALink>}
      </div>
    </div>
  )
}

OrderItem.propTypes = {
  item_name: PropTypes.string,
  status: PropTypes.string,
  url: PropTypes.string,
  ship_date: PropTypes.string
}