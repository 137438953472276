import * as React from 'react'
import { useMutation } from '@apollo/client'
import { generateCustomerTokenMutation, resetPasswordMutation } from 'graphql/mutations'
import { FormPageProps } from '../types.d'
import { TextInput, PasswordInput } from 'components/forms/components'
import { Validate, ValidatePasswordMatch } from '../validation'
import AlmostDoneHeader from '../components/AlmostDoneHeader'
import LoadingSpinner from 'components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import { SNLStorage } from 'apollo'
import { SNL_BASE_PATH } from 'modules/sleepNewLeads/constants'
import { reportToSentry } from 'utils/reportToSentry'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useQualifyFormStore } from '../state'
import { useTokenStore } from 'stores/tokenStore'
import { useQualifyFieldsStore, resetQualifyForm } from 'stores/qualifyFieldsStore'

const Submit = ({ isEmbedded, ...pageProps }) => {

  const { formFields, formErrors, setFormError, setFormField } = useQualifyFieldsStore()

  const inputRefs = {
    username: React.useRef(),
    password: React.useRef(),
    passwordConfirmation: React.useRef()
  }

  const navigate = useNavigate()

  const [ displayErrors, setDisplayErrors ] = React.useState( false )
  const [ submitError, setSubmitError ] = React.useState( `` )
  const [ submitLoading, setSubmitLoading ] = React.useState( false )

  const { resetPasswordToken } = useQualifyFormStore()
  const { setCustomerToken } = useTokenStore()

  const [ resetPassword ] = useMutation( resetPasswordMutation, {
    variables: {
      resetPasswordToken: resetPasswordToken,
      email: formFields.username,
      newPassword: formFields.passwordConfirmation
    },
    onError: ( ( error ) => {
      setSubmitLoading( false )
      setSubmitError( `There was an issue setting your password. Please contact customer service. Error id RP_0913` )
      reportToSentry( new Error ( `reset password mutation on -> PasswordResetForm`, {
        cause: error
      }) )
    })
  })

  React.useEffect( () => {
    window.scrollBy( 0, submitError ? -200 : -300 )
    const firstEnabledInput = submitError ? `username` : `password`
    inputRefs[firstEnabledInput]?.current?.focus()
  }, [] )

  const [ login ] = useMutation( generateCustomerTokenMutation, {
    fetchPolicy: `no-cache`, // Prevent caching user login creds
    variables: {
      email: formFields.username,
      password: formFields.passwordConfirmation
    },
    errorPolicy: `all`,
    onError: ( error ) => {
      reportToSentry( `Qualify Form - Login Mutation Error`, {
        cause: error
      })
    }
  })

  const handleLogin = async () => {
    const loginResult = await login()
    if ( loginResult?.data?.generateCustomerToken ) {
      setCustomerToken( loginResult.data.generateCustomerToken.token )
      SNLStorage.clearSession()
      resetQualifyForm()

      if ( isEmbedded ) return window?.parent?.postMessage( `Submit Success`, `*` )
      return navigate( SNL_BASE_PATH )
    }
    setSubmitError( `Something went wrong logging you in after setting your password. Please try again or contact customer at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
  }

  const handleOfflineSubmit = () => {
    setSubmitLoading( false )
    return pageProps.onHandleOfflineSubmit()
  }

  const handleFormSubmit = async ( event ) => {
    event && event.preventDefault()

    // 1. Validate
    const fieldWithError = Object.keys( inputRefs ).find( name => {
      const errorMessage = ( name === `passwordConfirmation` ) ? (
        ValidatePasswordMatch( formFields.password, formFields.passwordConfirmation )
      ) : (
        Validate( name, formFields[name] )
      )
      setFormError( name, errorMessage )

      return Boolean( errorMessage )
    })

    if ( fieldWithError ) return inputRefs[fieldWithError]?.current.focus()

    // 2. Set up customer's new password
    setSubmitLoading( true )
    setSubmitError( `` )

    resetPassword().then( resetPasswordResult => {

      if ( resetPasswordResult?.errors ) {
        return setSubmitError( resetPasswordResult.errors.map( errorObject => { return errorObject.message }).join( ` ` ) )
      }

      if ( !resetPasswordResult?.data ) {
        return setSubmitError( `There was an issue setting your password. Please contact customer service. Error id RP_0913` )
      }

      if ( resetPasswordResult?.data && pageProps.isSiteOffline ) {
        return handleOfflineSubmit()
      }

      handleLogin().finally( () => setSubmitLoading( false ) )
    })
  }

  const handleBlur = () => { setDisplayErrors( true ) }

  const handleChange = ( event ) => {
    setDisplayErrors( false )
    const errorMessage = ( event.target.name === `passwordConfirmation` ) ? (
      ValidatePasswordMatch( event.target.value, formFields.password )
    ) : (
      Validate( event.target.name, event.target.value )
    )
    setFormError( event.target.name, errorMessage )
    setFormField( event.target.name, event.target.value )
  }

  const labelClassNames = `input-label text-left sm:font-medium md:font-normal sm:text-sm md:text-xl text-graphite !p-0 text-left sm:mb-1 md:mb-3 mr-2 block`

  return (
    <div className="md:w-96 mx-auto">
      <AlmostDoneHeader />
      <div >
        <div className="my-4">
          <TextInput
            type="text"
            name="username"
            errorMessageClassName="text-error text-center text-sm"
            value={formFields.username}
            errorMessage={displayErrors && formErrors.username}
            reference={inputRefs.username}
            placeholder="Email"
            label="Email"
            labelClassName={labelClassNames}
            onChange={handleChange}
            autoComplete="username"
            required
            onBlur={handleBlur}
            disabled={!submitError || resetPasswordToken}
          />
        </div>

        <div className="my-4">
          <PasswordInput
            name="password"
            value={formFields.password}
            errorMessage={displayErrors && formErrors.password}
            reference={inputRefs.password}
            label="Password"
            labelClassName={labelClassNames}
            autoComplete="new-password"
            required
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div className="my-4">
          <PasswordInput
            name="passwordConfirmation"
            value={formFields.passwordConfirmation}
            errorMessage={displayErrors && formErrors.passwordConfirmation}
            reference={inputRefs.passwordConfirmation}
            label="Confirm Password"
            labelClassName={labelClassNames}
            autoComplete="new-password"
            required
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div className="mx-auto basis-full sm:mt-5 md:mt-14 mb-12 text-center">
          <button
            className="btn-primary text-center sm:max-w-72 md:max-w-[420px]"
            onClick={handleFormSubmit}
            disabled={submitLoading}
          >{submitLoading ? <LoadingSpinner height="2.5rem" width="2.5rem" rings={3} /> : `Submit`}</button>
        </div>
        <div className="py-2">
          <p className="text-error">{submitError}</p>
        </div>
      </div>
    </div>
  )
}

Submit.propTypes = FormPageProps

export default Submit