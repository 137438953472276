import { useEffect, useState } from 'react'
import ProgressionNode from './ProgressNode'
import PropTypes from 'prop-types'
import { progressPageNames, SNL_MACHINE_PATH, SNL_STEPS_PATH_MACHINE, SNL_BASE_PATH, SNL_MACHINE_SHORTAGE_PATH, SNL_MASK_FITTING_PATH, SNL_THANK_YOU_PATH } from '../constants'
import { useNavigate } from 'react-router-dom'

function ProgressionContainer({ progressData, formValues }) {

  const [ fillMapping, setFillMapping ] = useState( [] )
  const [ disableLinks, setDisableLinks ] = useState( false )
  const [ screenSize, setScreenSize ] = useState( window.innerWidth < 500 ? 0 : 1 )

  const navigate = useNavigate()

  const checkResets = () => {
    const maskReset = formValues.cpapMaskType.length && ( !formValues.cpapMaskBrand.length || !formValues.cpapMaskModel.length || !formValues.cpapMaskSize.length || !formValues.satisfiedWithCurrentMask.length )
    const machineReset = formValues.cpapMachineBrand.length && ( !formValues.cpapMachineModel.length || !formValues.lastOrderCpapMachine.length )
    const accessoriesReset = formValues.tubingType.length && !formValues.lastOrderCpapSupplies.length
    const conditionalPagePath = window.location.pathname === SNL_MACHINE_SHORTAGE_PATH || window.location.pathname === SNL_MASK_FITTING_PATH
    setDisableLinks( maskReset || machineReset || accessoriesReset || conditionalPagePath )
  }

  const checkForRedirect = ( newFillMapping ) => {
    if ( window.location.pathname !== SNL_MACHINE_PATH && window.location.pathname !== SNL_STEPS_PATH_MACHINE && window.location.pathname !== SNL_THANK_YOU_PATH ) {
      // On reload we want to redirect to StepLauncher to show user progress, also to allow for the case where the formValues cache gets cleared
      if ( newFillMapping.every( ( fillMap ) => { return fillMap.completed !== fillMap.total }) ) {
        navigate( SNL_BASE_PATH, {
          replace: true
        })
      }
    }
  }

  const handleResize = () => {
    window.innerWidth < 500 ? setScreenSize( 0 ) : setScreenSize( 1 )
  }

  useEffect( () => {
    let mounted = true

    checkResets()
    const newFillMapping = Object.keys( progressData ).map( ( step ) => {
      const link = progressPageNames[step]
      const total = progressData[step].length
      const completed = progressData[step].filter( key => {
        if ( formValues[key] !== `` ) {
          return key
        }
      }).length

      return {
        completed,
        total,
        link
      }
    })

    if ( mounted ) {
      setFillMapping( newFillMapping )
      checkForRedirect( newFillMapping )
    }

    return ( () => {
      mounted = false
    })
  }, [ formValues, progressData ] )

  useEffect( () => {
    window.addEventListener( `resize`, handleResize )

    return () => {
      window.removeEventListener( `resize`, handleResize )
    }
  }, [] )

  return (
    <div className="flex justify-center items-center sticky top-0 sticky-gradient z-50">
      <div className="flex align-center justify-evenly my-10 sm:w-9/12 md:w-1/2 lg:w-4/12">
        {
          progressData &&
          fillMapping.map( ( fill, i ) => {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={`flex ${i !== fillMapping.length - 1 && `w-full`} items-center justify-center ${ fill.completed === fill.total && !disableLinks && `cursor-pointer`}`}
                onClick={() => { if ( fill.completed === fill.total && !disableLinks ) navigate( fill.link ) }}
              >
                <ProgressionNode stepNum={i+1} completed={( fill.completed/fill.total )*100} screenSize={screenSize} />
                {
                  i !== fillMapping.length - 1 &&
                  <span className={`border-2 ${fill.completed !== fill.total ? `border-secondaryBlue-tint3 bg-secondaryBlue-tint3` : `border-deepSleepBlue bg-deepSleepBlue`} rounded-lg w-full mx-1`} />
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

ProgressionContainer.propTypes = {
  progressData: PropTypes.object,
  formValues: PropTypes.object
}

export default ProgressionContainer
