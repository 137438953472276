import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { PageTracker } from '..'
import LoadingSpinner from 'components/LoadingSpinner'
import { BrandSelector, MaskTypeSelector, MaskModelSelector, MaskSizeSelector, LastFreshMaskSelector, LastHeadgearSelector, MaskSatisfiedSelector } from 'components/SNLSelectors'
import { scrollToBelowLogo } from 'components/scrollToTop'
import { useFetchBaseEquipment } from '../hooks/useFetchBaseEquipment'
import { SNLBackButton } from '../SNLBackButton'

const stepTargets = [
  `cpapMaskType`,
  `cpapMaskBrand`,
  `cpapMaskModel`,
  `cpapMaskSize`,
  `lastOrderCpapMask`,
  `lastOrderCpapMaskHeadgear`,
  `satisfiedWithCurrentMask`
]

const Mask = ({onHandleChange, formValues, getMaskFittingContent, ...pageProps}) => {
  const [ handleChangeTarget, setHandleChangeTarget ] = useState( `cpapMaskType` )

  const { isLoading, baseEquipment } = useFetchBaseEquipment()

  const handleChange = ( e ) => {
    if ( e.target.name === `cpapMaskSize` ) {
      const mask = maskSizes.find( mask => {
        return mask.dme_id == e.target.value
      })

      onHandleChange({
        [e.target.name]: mask.size,
        cpapMaskDmeID: e.target.value
      })
    } else if ( e.target.name === `cpapMaskType` ) {
      // if mask type changes reset the all mask inputs
      onHandleChange({
        [e.target.name]: e.target.value,
        cpapMaskBrand: ``,
        cpapMaskDmeID: ``,
        cpapMaskModel: ``,
        cpapMaskSize: ``
      })
    } else if ( e.target.name === `cpapMaskBrand` ) {
      onHandleChange({
        [e.target.name]: e.target.value,
        cpapMaskDmeID: ``,
        cpapMaskModel: ``,
        cpapMaskSize: ``
      })
    } else {
      onHandleChange({
        [e.target.name]: e.target.value
      })
    }

    handleNextMobile()
  }

  const handleNextMobile = () => {
    scrollToBelowLogo()
    if ( stepTargets[stepTargets.length - 1] === handleChangeTarget ) {
      if ( formValues.satisfiedWithCurrentMask === `false` ) getMaskFittingContent()

      return pageProps.nextPage()
    } else {
      const index = stepTargets.findIndex( item => { return item === handleChangeTarget }) || 0
      setHandleChangeTarget( stepTargets[index + 1] )
    }
  }

  const handleBackMobile = () => {
    scrollToBelowLogo()
    if ( handleChangeTarget && stepTargets[0] !== handleChangeTarget ) {
      const index = stepTargets.findIndex( item => { return item === handleChangeTarget }) || 0
      setHandleChangeTarget( stepTargets[index - 1] )
    } else {
      setHandleChangeTarget( stepTargets[0] )
      pageProps.prevPage()
    }
  }

  const [
    maskTypes,
    maskBrands,
    masks,
    maskSizes
  ] = useMemo( () => {
    const typeNames = baseEquipment?.map( ({sleep_mask_category}) => { return sleep_mask_category })
    const brandsByType = baseEquipment?.find( item => { return item.sleep_mask_category === formValues?.cpapMaskType })?.brandsAvailable || []
    const brandNames = brandsByType.map( ({brand}) => { return brand })
    const masksByBrand = brandsByType?.find( item => { return item.brand === formValues?.cpapMaskBrand })?.masksAvailable || []
    const maskNames = masksByBrand.map( item => { return item.mask })
    const sizes = masksByBrand?.find( item => { return item.mask === formValues?.cpapMaskModel })?.sizesAvailable || []

    return [ typeNames, brandNames, maskNames, sizes ]
  }, [ baseEquipment, formValues ] )

  if ( isLoading ) {
    return <div>
      <PageTracker
        title={`Your Mask`}
      />
      <LoadingSpinner />
    </div>
  }

  return (
    <div>
      <PageTracker
        title={`Your Mask`}
        description={handleChangeTarget === `cpapMaskType` && `We cannot complete your order until we know what equipment you currenly use`}
      />

      <MaskTypeSelector
        heading={`Select your current mask type`}
        name="cpapMaskType"
        selectedValue={formValues.cpapMaskType}
        maskTypes={maskTypes}
        onChange={handleChange}
        showOnMobile={!handleChangeTarget || handleChangeTarget === `cpapMaskType`}
      />

      <BrandSelector
        heading={`Select your current mask brand`}
        name="cpapMaskBrand"
        onChange={( name, value ) => {
          return handleChange({
            target: {
              name,
              value
            }
          })
        }}
        brandNames={maskBrands}
        selectedValue={formValues.cpapMaskBrand}
        defaultLabel="Select a Mask Manufacturer"
        showOnMobile={handleChangeTarget === `cpapMaskBrand`}
      />

      <MaskModelSelector
        heading={`Which ${formValues?.cpapMaskBrand} model mask do you currently have?`}
        name="cpapMaskModel"
        maskNames={masks}
        selectedValue={formValues?.cpapMaskModel}
        onChange={handleChange}
        showOnMobile={handleChangeTarget === `cpapMaskModel`}
      />

      <MaskSizeSelector
        heading={`What’s your mask size?`}
        name="cpapMaskSize"
        maskSizes={maskSizes}
        selectedValue={formValues?.cpapMaskDmeID}
        selectedModel={formValues?.cpapMaskModel}
        onChange={handleChange}
        showOnMobile={handleChangeTarget === `cpapMaskSize`}
      />

      <LastFreshMaskSelector
        heading={`When did you last get a fresh CPAP mask through insurance?`}
        name="lastOrderCpapMask"
        selectedValue={formValues?.lastOrderCpapMask}
        selectedModel={formValues?.cpapMaskModel}
        options={[
          {
            value: `GT3MOS`,
            label: `More than 3 months ago`
          },
          {
            value: `LT3MOS`,
            label: `Less than 3 months ago`
          },
          {
            value: `NEVER`,
            label: `I’ve never recieved a CPAP mask through insurance`
          }
        ]}
        onChange={handleChange}
        showOnMobile={handleChangeTarget === `lastOrderCpapMask`}
      />

      <LastHeadgearSelector
        heading={`When did you last receive headgear for your mask through insurance?`}
        name="lastOrderCpapMaskHeadgear"
        selectedValue={!formValues.lastOrderCpapMaskHeadgear ? `None` : formValues.lastOrderCpapMaskHeadgear}
        selectedModel={formValues?.cpapMaskModel}
        onChange={handleChange}
        options={[
          {
            value: `GT6MOS`,
            label: `More than 6 months ago`
          },
          {
            value: `LT6MOS`,
            label: `Less than 6 months ago`
          },
          {
            value: `NEVER`,
            label: `I’ve never recieved headgear through insurance`
          }
        ]}
        showOnMobile={handleChangeTarget === `lastOrderCpapMaskHeadgear`}
      />

      <MaskSatisfiedSelector
        heading={`Are you satisfied with your current CPAP mask?`}
        name="satisfiedWithCurrentMask"
        selectedValue={formValues.satisfiedWithCurrentMask}
        onChange={handleChange}
        options={[
          {
            value: true,
            label: `Yes, I want what I currently use, just newer.`
          },
          {
            value: false,
            label: `I am either unhappy with my current mask or need help finding a new mask that best fits my needs.`
          }
        ]}
        showOnMobile={handleChangeTarget === `satisfiedWithCurrentMask`}
      />
      <SNLBackButton
        hide={handleChangeTarget === stepTargets[0]}
        handleBack={handleBackMobile}
      />

    </div>
  )
}

Mask.propTypes = {
  onHandleChange: PropTypes.func,
  history: PropTypes.object,
  getMaskFittingContent: PropTypes.func,
  formValues: PropTypes.shape({
    cpapMaskType: PropTypes.string,
    cpapMaskModel: PropTypes.string,
    cpapMaskDmeID: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    cpapMaskBrand: PropTypes.string,
    cpapMaskSize: PropTypes.string,
    lastOrderCpapMask: PropTypes.string,
    lastOrderCpapMaskHeadgear: PropTypes.string,
    satisfiedWithCurrentMask: PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] )
  })
}


export default Mask