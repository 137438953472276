import React from 'react'
import PropTypes from 'prop-types'
import { maskImages } from 'modules/sleepNewLeads/constants'
import { cpapMaskSizeImg } from 'resources/images'

const sizeOrderMap = {
  "Extra Small": 0,
  "Small": 1,
  "Medium": 2,
  "Large": 3,
  "Extra Large": 4
}

const MaskSizeSelector = ({
  heading,
  name,
  maskSizes,
  selectedModel,
  onChange,
  selectedValue,
  // defaultLabel = `Select a Size`,
  showOnMobile
}) => {
  const currentMask = maskImages.find( item => { return item.mask === selectedModel })
  if ( !maskSizes || !maskSizes?.length || !currentMask ) return null

  return (
    <>
      {showOnMobile && <div className="mt-4">
        <div className="text-center text-xl mb-4">{heading}</div>
        <p className="text-sm text-graphite text-center">{`Just like any item you shop for online, we need to know your size! Reference this picture to identify where you can find your mask size. It can almost always be found on the mask itself.`}</p>
        <div>
          <img
            src={cpapMaskSizeImg}
            className="w-full h-auto mt-4"
            alt={currentMask.mask}
            width="560"
            height="370"
          />
        </div>
        <div className="flex flex-col justify-center items-center mt-4 gap-3 max-w-xs mx-auto">
          {
            maskSizes.sort( ( s1, s2 ) => { return sizeOrderMap[s1.size] - sizeOrderMap[s2.size] }).map( ( item ) => {
              return (
                <button
                  name={name}
                  value={item.dme_id}
                  onClick={onChange}
                  key={item.dme_id}
                  className={selectedValue === String( item.dme_id ) ? `btn-secondary-selected` : `btn-secondary`}
                >
                  { item.size }
                </button>
              )
            })
          }
        </div>
      </div>}
    </>
  )
}

MaskSizeSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  maskSizes: PropTypes.array,
  selectedValue: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
  selectedModel: PropTypes.string,
  onChange: PropTypes.func,
  defaultLabel: PropTypes.string,
  showOnMobile: PropTypes.bool
}

export default MaskSizeSelector
