import { useEffect, useState } from 'react'
import { PromoFooter, HeroDiv, PromoLoader } from './components'
import { useQuery } from '@apollo/client'
import ReactHtmlParser from 'react-html-parser'
import { cmsPageQuery } from 'graphql/queries'
import { promoContentTransform } from './utils'
import { useNavigate } from 'react-router-dom'
import { promoHeroImg } from 'resources/images'
import HeroContentType from './HeroContentType'
import { reportToSentry } from 'utils/reportToSentry'

function SleepPromo() : JSX.Element | null {

  const navigate = useNavigate()
  const [ heroSrc, setHeroSrc ] = useState<string>( promoHeroImg )
  const [ heroContent, setHeroContent ] = useState<HeroContentType>({
    heroHeading: null,
    qualifyHeading: null
  })

  const { data, loading} = useQuery( cmsPageQuery, {
    errorPolicy: `all`,
    fetchPolicy: `cache-and-network`,
    variables: {
      identifier: `${window.location.pathname.replace( `/sleep-promo/`, `` )}`
    },
    onError: ( error ) => {
      reportToSentry( `cmsPagesQuery Error -> SleepPromo.tsx`, {
        cause: error
      })
    }
  })

  const handleSetHeroContent = ( key: string, value: string ) => {
    if ( !heroContent[key] ) {
      setHeroContent( Object.assign({}, heroContent, {
        [key]: value
      }) )
    }
  }

  useEffect( () => {
    if ( !loading && !data?.cmsPage?.content ) {
      return navigate( `/promo-not-found`, {
        replace: true
      })
    }
  }, [ data ] )

  if ( data?.cmsPage?.content ) {
    return (
      <div className="w-full flex flex-col items-center">
        <HeroDiv heroSrc={heroSrc} heroContent={heroContent} />
        {
          !loading && data?.cmsPage?.content ?
            <>
              {
                ReactHtmlParser( data.cmsPage.content, {
                  transform: ( node ) => { return promoContentTransform( node, heroSrc, setHeroSrc, handleSetHeroContent ) }
                })
              }
            </>
            :
            <PromoLoader />
        }
        <PromoFooter />
      </div>
    )
  }

  return null
}

export default SleepPromo