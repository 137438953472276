import { useMatch } from 'react-router-dom'
import { QUALIFY_PROMOTION_PATH } from 'modules/qualify/constants'
import { DefaultHeading } from 'components/qualify'
import { Blocks as CMSBlocks } from 'components/cms'
import { QualifyHead } from 'components/head'
import BuilderContentFetch from '../../../utils/builderContentFetch'

export default function ThroughInsurance() {

  const routeMatch = useMatch( `${QUALIFY_PROMOTION_PATH}:urlKey` )

  // useMedchatWidget( `dvKo2qy0JEq1fVRJ0YZ2Rg` )

  return (
    <div className="flex flex-col items-center">
      <QualifyHead /> {/* <!-- Changes the head items for seo when this component is rendered --> */}

      <div className="mx-0 max-w-7xl">
        {routeMatch && (
          <div className="md:container mx-auto p-10">
            <CMSBlocks urlKeys={[ routeMatch?.params?.urlKey ?? `` ]} fallback={<DefaultHeading />} />
          </div>
        )}

        <BuilderContentFetch
          modelName="page"
          options={{
            url: `/qualify-through-insurance-v2` // change to `/qualify-through-insurance` prior to deploying to prod
          }}
        />
      </div>
    </div>
  )
}