import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { StyledErrorWrapper } from 'components/layoutUi'
import { useReactiveVar } from '@apollo/client'
import { blockLeadsDisplay } from 'apollo'
import { DoctorBlock } from 'components/doctorBlock'
import { SNLBackButton } from '../SNLBackButton'
import AddressBlock, { useAddressStore, overrideAddressBlockStyle } from 'components/addressBlock'

export default function DoctorAddressCollection({onSubmit, displaySubmitError, ...pageNavigatorProps}) {
  const [ isDoctorComplete, setIsDoctorComplete ] = useState( false )
  const isAddressComplete = useAddressStore( state => state.isAddressComplete )
  const leadsDisplayBlocked = useReactiveVar( blockLeadsDisplay )

  useEffect( () => {
    if ( leadsDisplayBlocked && window.location.pathname !== `/new-patient-intake/sleep/thank-you` ) pageNavigatorProps.nextPage()
  }, [] )

  useEffect( () => {
    if ( !leadsDisplayBlocked ) {
      // avoid resetting these values on the progress bar if need to display mask fitting page
      pageNavigatorProps.onHandleChange({
        doctorAcceptedFlag: isDoctorComplete ? `1` : ``
      })
    }
  }, [ isDoctorComplete ] )

  useEffect( () => {
    // avoid resetting these values on the progress bar if need to display mask fitting page
    if ( !leadsDisplayBlocked ) {
      pageNavigatorProps.onHandleChange({
        addressAcceptedFlag: isAddressComplete ? `1` : ``
      })
    }
  }, [ isAddressComplete ] )

  overrideAddressBlockStyle({
    headerStyle: `h2 font-semibold mb-3`,
    containerStyle: `border border-graphite md:rounded sm:border-r-0 sm:border-l-0 md:border-r md:border-l p-4`,
    view: {
      editActionClassName: `underline cursor-pointer text-deepSleepBlue`
    }
  })

  return (
    <>
      <DoctorBlock
        onSuccess={() => { setIsDoctorComplete( true ) }}
        onExistingDoctor={() => { setIsDoctorComplete( true ) }}
        headerStyle="h2 font-semibold mb-3"
        containerStyle="border border-graphite md:rounded sm:border-r-0 sm:border-l-0 md:border-r md:border-l p-4"
      />
      <br></br>

      <AddressBlock
        addressType=""
        shouldSkipMountFetch
        shouldSkipValidation
      />

      {
        displaySubmitError ?
          <StyledErrorWrapper>
            {`We're sorry but our servers have encountered an issue processing this submission. Please call a patient care specialist at ${AF_SUPPORT_PHONE_NUMBER} for help.`}
          </StyledErrorWrapper>
          :
          ``
      }
      <div className="mx-auto w-80 mt-6 space-y-6">
        <button
          className="btn btn-secondary" onClick={onSubmit}
          disabled={!isDoctorComplete || !isAddressComplete}
        >{`Complete Form`}</button>
        <SNLBackButton handleBack={pageNavigatorProps.prevPage} />
      </div>
    </>
  )
}

DoctorAddressCollection.propTypes = {
  onSubmit: PropTypes.func,
  displaySubmitError: PropTypes.bool,
  onHandleChange: PropTypes.func
}