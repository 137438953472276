import React, { useRef, useEffect } from 'react'
import { select } from 'd3-selection'
import styled from 'styled-components'
import tailwindColors from 'config/tailwindColors'
import PropTypes from 'prop-types'
import ProgressAnimation from './ProgressAnimation'
import { arc } from 'd3-shape'
import usePrevious from './usePrevious'

const NumDisplay = styled.div`
    position: absolute;
    left: ${props => props.left};
    top: ${props => props.top};
  `

function ProgressNode({completed, stepNum, screenSize}) {

  // constants and vars for progress animation
  const svgSize = screenSize ? 59 : 45
  const arcWidth = screenSize ? 6.5 : 5
  const arcOuterRadius = svgSize / 2
  const arcInnerRadius = svgSize / 2 - arcWidth
  const displayLeft = screenSize ? svgSize * 0.29 : svgSize * 0.37
  const displayTop = screenSize ? svgSize * 0.15 : svgSize * 0.2
  const progressRef = useRef( null )

  const arcGenerator = arc()
    .innerRadius( arcInnerRadius )
    .outerRadius( arcOuterRadius )
    .startAngle( 0 )

  const [ toggleResetAnimation, setToggleResetAnimation ] = React.useState( false )

  const previousProgress = usePrevious( completed )

  useEffect( () => {
    // Clear the progress dial if it was complete but inputs were reset
    if ( previousProgress > completed ) {
      select( progressRef.current ).selectAll( `path` )
        .remove()
      setToggleResetAnimation( !toggleResetAnimation )
    }
  }, [ completed ] )

  useEffect( () => {
    select( progressRef.current ).selectAll( `path` )
      .remove()
    setToggleResetAnimation( !toggleResetAnimation )
  }, [ screenSize ] )



  return (
    <div className={`relative rounded-full ${completed < 100 && `opacity-90`} border-none`}>
      <svg height={svgSize} width={svgSize}>
        <g transform={`translate(${svgSize / 2}, ${svgSize / 2})`}>
          <path
            fill={tailwindColors.secondaryBlue.tint3}
            d={arcGenerator({
              endAngle: 2 * Math.PI
            })}
          />
        </g>
        {
          <ProgressAnimation
            svgSize={svgSize}
            progressRef={progressRef}
            arcGenerator={arcGenerator}
            completed={completed}
            toggleResetAnimation={toggleResetAnimation}
          />
        }
      </svg>
      <NumDisplay left={displayLeft + `px`} top={displayTop + `px`}>
        <span className={`font-black text-deepSleepBlue ${ screenSize ? `text-4xl` : `text-lg` }`}>{stepNum}</span>
      </NumDisplay>
    </div>
  )
}

ProgressNode.propTypes = {
  stepNum: PropTypes.number,
  completed: PropTypes.number,
  screenSize: PropTypes.number
}

export default ProgressNode
