import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'

export const PAYMENT_PORTAL_PATH = `/payments/:page/*`
export const SUMMARY_PAGE_PATH = `/payments/summary`
export const PAYMENTS_PAGE_PATH = `/payments/pay`
export const THANK_YOU_PAGE_PATH = `/payments/thank-you`
export const CLINICAL_SCHEDULING_AOB_PATH = `/payments/scheduling`

export const AOB_PAGES = [ SUMMARY_PAGE_PATH, PAYMENTS_PAGE_PATH, THANK_YOU_PAGE_PATH ]
export const AOB_PAGES_WITH_SCHEDULING = [ SUMMARY_PAGE_PATH, PAYMENTS_PAGE_PATH, CLINICAL_SCHEDULING_AOB_PATH, THANK_YOU_PAGE_PATH ]

export const initialEmergencyContactFields = {
  emergency_contact_name: ``,
  emergency_contact_phone: ``,
  emergency_contact_relationship_to_patient: ``,
  patient_hash: ``
}

export const notInterestedLinks = [
  `The cost through insurance is higher than I expected`,
  `I have questions about my order or would like to make adjustments, please contact me`,
  `This process is more difficult than I expected`,
  `I can obtain these supplies cheaper elsewhere`
]

export const INTAKE_AOB_COPY = {
  totalCostIns: {
    header: `What Your Insurance Pays`,
    details: `Based on your deductible, out of pocket limit, and co-insurance, this is the estimated amount of money your health insurance provider will pay toward your PAP supplies. If this number is $0.00 it simply means that your deductible is unmet. Scroll down to learn more about your deductible, out of pocket limit, and co-insurance.`
  },
  totalCostYou: {
    header: `What You Owe`,
    details: `Based on your deductible, out of pocket limit, and co-insurance, this is the estimated amount of money your health insurance provider requires you pay out of your own pocket. If this number is greater than $0.00 it simply means that your deductible is unmet or your deductible is met and you are paying the co-insurance portion of the cost. Scroll down to learn more about your deductible, out of pocket limit, and co-insurance.`
  },
  deductible: {
    header: `Your Deductible`,
    details: `Deductible is the amount that you must pay out of your own pocket before your health insurance provider will begin covering a predetermined percentage of your medical costs`
  },
  oop: {
    header: `Your Out of Pocket Limit`,
    details: `Out of pocket limit is the maximum amount of money you will have to pay out of your own pocket in a 1 year time frame. Once you've met your out of pocket limit, most health insurance providers cover any new medical costs at 100%.`
  },
  coIns: {
    header: `Co-insurance`,
    details: `Co-insurance is the percentage or amount of money you must pay out of your own pocket once your deductible has been met. Similar to a copay, co-insurance is the cost share that you are responsible to pay for covered services. Ex. Once your deductible is met, your health insurance provider covers 80% of your medical costs and you pay the remaining 20%.`
  },
  paymentDisclaimer: {
    cost: `Your payment amounts displayed below are the estimated costs for your order based on how your plan usually covers supplies.`,
    shipment: `Your items will be scheduled to ship once payment is received and your clinical setup appointment is scheduled. Upon completion, your order will be scheduled to ship 7-10 days prior to the date of your clinical setup appointment.`
  }
}

export const SLEEP_NEW_LEADS_AOB_COPY = {
  totalCostIns: {
    header: `What Your Insurance Pays`,
    details: `Based on your deductible, out of pocket limit, and co-insurance, this is the estimated amount of money your health insurance provider will pay toward your PAP supplies. If this number is $0.00 it simply means that your deductible is unmet. Scroll down to learn more about your deductible, out of pocket limit, and co-insurance.`
  },
  totalCostYou: {
    header: `What You Owe`,
    details: `Based on your deductible, out of pocket limit, and co-insurance, this is the estimated amount of money your health insurance provider requires you pay out of your own pocket. If this number is greater than $0.00 it simply means that your deductible is unmet or your deductible is met and you are paying the co-insurance portion of the cost. Scroll down to learn more about your deductible, out of pocket limit, and co-insurance.`
  },
  deductible: {
    header: `Your Deductible`,
    details: `Deductible is the amount that you must pay out of your own pocket before your health insurance provider will begin covering a predetermined percentage of your medical costs`
  },
  oop: {
    header: `Your Out of Pocket Limit`,
    details: `Out of pocket limit is the maximum amount of money you will have to pay out of your own pocket in a 1 year time frame. Once you've met your out of pocket limit, most health insurance providers cover any new medical costs at 100%.`
  },
  coIns: {
    header: `Co-insurance`,
    details: `Co-insurance is the percentage or amount of money you must pay out of your own pocket once your deductible has been met. Similar to a copay, co-insurance is the cost share that you are responsible to pay for covered services. Ex. Once your deductible is met, your health insurance provider covers 80% of your medical costs and you pay the remaining 20%.`
  },
  paymentDisclaimer: {
    cost: `Your payment amounts displayed below are the estimated costs for your order based on how your plan traditionally covers supplies.`,
    shipment: `Your item(s) will be shipped upon receipt of payment and required medical documents, including your prescription (and possibly your sleep study), as mandated by your health insurance provider.`
  }
}

export const RESUPPLY_AOB_COPY = {
  totalCostIns: {
    header: `What Your Insurance Pays`,
    details: `Based on your deductible, out of pocket limit, and co-insurance, this is the estimated amount of money your health insurance provider will pay toward your PAP supplies. If this number is $0.00 it simply means that your deductible is unmet. Scroll down to learn more about your deductible, out of pocket limit, and co-insurance.`
  },
  totalCostYou: {
    header: `What You Owe`,
    details: `Based on your deductible, out of pocket limit, and co-insurance, this is the estimated amount of money your health insurance provider requires you pay out of your own pocket. If this number is greater than $0.00 it simply means that your deductible is unmet or your deductible is met and you are paying the co-insurance portion of the cost. Scroll down to learn more about your deductible, out of pocket limit, and co-insurance.`
  },
  deductible: {
    header: `Your Deductible`,
    details: `Deductible is the amount that you must pay out of your own pocket before your health insurance provider will begin covering a predetermined percentage of your medical costs`
  },
  oop: {
    header: `Your Out of Pocket Limit`,
    details: `Out of pocket limit is the maximum amount of money you will have to pay out of your own pocket in a 1 year time frame. Once you've met your out of pocket limit, most health insurance providers cover any new medical costs at 100%.`
  },
  coIns: {
    header: `Co-insurance`,
    details: `Co-insurance is the percentage or amount of money you must pay out of your own pocket once your deductible has been met. Similar to a copay, co-insurance is the cost share that you are responsible to pay for covered services. Ex. Once your deductible is met, your health insurance provider covers 80% of your medical costs and you pay the remaining 20%.`
  },
  paymentDisclaimer: {
    cost: `Your payment amounts displayed below are the estimated costs for your order based on how your plan traditionally covers supplies.`,
    shipment: `Your item(s) will be shipped upon receipt of payment and any required medical documents as mandated by your health insurance provider. What medical documents are required?`
  }
}

export const pricingEstimateTooltips = {
  totalCostIns: {
    header: `What Your Insurance Pays`,
    details: `Based on your deductible, out of pocket limit, and co-insurance, this is the estimated amount of money your health insurance provider will pay toward your PAP supplies. If this number is $0.00 it simply means that your deductible is unmet. Scroll down to learn more about your deductible, out of pocket limit, and co-insurance.`
  },
  totalCostYou: {
    header: `What You Owe`,
    details: `Based on your deductible, out of pocket limit, and co-insurance, this is the estimated amount of money your health insurance provider requires you pay out of your own pocket. If this number is greater than $0.00 it simply means that your deductible is unmet or your deductible is met and you are paying the co-insurance portion of the cost. Scroll down to learn more about your deductible, out of pocket limit, and co-insurance.`
  },
  deductible: {
    header: `Your Deductible`,
    details: `Deductible is the amount that you must pay out of your own pocket before your health insurance provider will begin covering a predetermined percentage of your medical costs`
  },
  oop: {
    header: `Your Out of Pocket Limit`,
    details: `Out of pocket limit is the maximum amount of money you will have to pay out of your own pocket in a 1 year time frame. Once you've met your out of pocket limit, most health insurance providers cover any new medical costs at 100%.`
  },
  coIns: {
    header: `Co-insurance`,
    details: `Co-insurance is the percentage or amount of money you must pay out of your own pocket once your deductible has been met. Similar to a copay, co-insurance is the cost share that you are responsible to pay for covered services. Ex. Once your deductible is met, your health insurance provider covers 80% of your medical costs and you pay the remaining 20%.`
  },
  paymentDisclaimer: {
    cost: `Your payment amounts displayed below are the estimated costs for your order based on how your plan usually covers supplies.`,
    shipment: `Your item(s) will ship upon payment below and receipt of your prescription from your doctor as required by your health insurance provider.`
  }
}

export const defaultErrorMessage = `Oops! Looks like something went wrong, please try again or call us at ${AF_SUPPORT_PHONE_NUMBER} for help.`

export const emptyCardMethod = {
  cardholderFirstName: ``,
  cardholderLastName: ``,
  cardNumber: ``,
  expMonth: ``,
  expYear: ``,
  ccv: ``,
  state: ``,
  city: ``,
  address: ``,
  zip: ``
}

export const emptyBankMethod = {
  routingNumber: ``,
  nameOnAccount: ``,
  accountNumber: ``,
  accountType: `checking`
}

export const paymentThankYouCopy = `Your payment is now being processed. Questions? Contact us at info@aeroflowsleep.com`

export const aobPayLaterCopy = `Thank you for submitting your AOB, since you opted out of making a payment to finalize your order, we will reach out to you soon!`

export const aobPatientOwes0Copy = `Thank you for submitting your AOB, we will reach out to you soon!`
