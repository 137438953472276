import PropTypes from 'prop-types'

function PricingEstimateBlockWrapper({children}) {
  return (
    <div className="shadow-lg w-full py-4 md:px-10 sm:px-4 bg-white flex flex-col items-center justify-center relative font-light">
      {children}
    </div>
  )
}

PricingEstimateBlockWrapper.propTypes = {
  children: PropTypes.node
}

export default PricingEstimateBlockWrapper