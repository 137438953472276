import React from 'react'
import PropTypes from 'prop-types'
import { useMutation, useReactiveVar } from '@apollo/client'
import { useNavigate, Link } from 'react-router-dom'
import { preLoginLocationVar } from 'apollo'
import { createPatientMutation, generateCustomerTokenMutation } from 'graphql/mutations'
import { Validators } from 'components/forms/components/utils'
import { TextInput, FormButton, ErrorField, PasswordInput } from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { getRedirectable } from 'modules/auth/redirectable'
import { reportToSentry } from 'utils/reportToSentry'
import postDemographics from 'utils/auth/demographics'
import { MY_ACCOUNT_PATH } from 'routes'
import { useTokenStore } from 'stores/tokenStore'

// This component is for the resupply-gateway
const CreateAccountForm = ({ firstName, lastName, email, dateOfBirth, sleepHash }) => {

  const navigate = useNavigate()
  const { setCustomerToken } = useTokenStore()

  const [ loading, setLoading ] = React.useState( false )
  const [ errorMessage, setErrorMessage ] = React.useState( `` )
  const [ pwdConfirmationError, setPwdConfirmationError ] = React.useState( `` )
  const [ pwdFieldsHaveError, setPwdFieldsHaveError ] = React.useState( true )
  const [ formFields, setFormFields ] = React.useState({
    firstname: firstName ?? ``,
    lastname: lastName ?? ``,
    email: email ?? ``,
    date_of_birth: dateOfBirth ?? ``,
    password: ``,
    passwordConfirmation: ``
  })

  const passwordRef = React.useRef( null )
  const passwordConfirmationRef = React.useRef( null )

  const { from } = useReactiveVar( preLoginLocationVar ) || {
    from: {
      pathname: MY_ACCOUNT_PATH,
      search: window.location.search
    }
  }

  const [ createPatient ] = useMutation( createPatientMutation, {
    errorPolicy: `all`,
    variables: {
      patientCreateInput: {
        firstname: formFields.firstname,
        lastname: formFields.lastname,
        email: formFields.email,
        date_of_birth: formFields.date_of_birth,
        password: formFields.password,
        patient_hash: sleepHash
      },
      onError: ( ( error ) => {
        reportToSentry( new Error ( `there was a error in the create patient mutation - create account form`, {
          cause: error
        }) )
      })
    }
  })

  const [ login ] = useMutation( generateCustomerTokenMutation, {
    errorPolicy: `all`,
    fetchPolicy: `no-cache`, // Prevent caching user login creds
    variables: {
      email: formFields.email,
      password: formFields.password
    },
    onError: ( ( error ) => {
      reportToSentry( new Error ( `there was an error using the generate customer token mutation - create account form`, {
        cause: error
      }) )
    })
  })

  React.useEffect( () => {
    setPwdFieldsHaveError( false )
    const pwdMatch = Validators.passwordConfirmation( formFields.password, formFields.passwordConfirmation )
    const bothPwdValid = Validators.password( formFields.password ) && Validators.password( formFields.passwordConfirmation )

    if ( !pwdMatch ){
      setPwdConfirmationError( `Your passwords don't match. Please double check your passwords.` )
    } else {
      setPwdConfirmationError( `` )
    }

    if ( !pwdMatch || !bothPwdValid ){
      setLoading( false )
      setPwdFieldsHaveError( true )
    }

  }, [ formFields ] )

  React.useEffect( () => {
    if ( errorMessage ) setLoading( false )
  }, [ errorMessage ] )

  const handleChange = event => {
    setFormFields({
      ...formFields,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading( true )

    postDemographics( `PatientHash ${sleepHash}`, {
      emailAddress: formFields.email
    }).catch( error => {
      reportToSentry( new Error( `create account form - failed to update demographics - email`, {
        cause: error
      }) )
    })

    if ( !sleepHash ){
      const { firstname: firstName, lastname:lastName, email: ffEmail, date_of_birth:dob } = formFields
      reportToSentry( new Error( `create patient: createPatient mutation no sleep hash present!` ), {
        patientEmail: email,
        firstName,
        lastName,
        dob,
        formFieldEmail: ffEmail
      })
    }


    const result = await createPatient()
    if ( result?.errors ) {

      const _errorMessage = result.errors.map( errorObject => { return errorObject.message }).join( ` ` )
      reportToSentry( new Error ( `Error Creating Account (createPatient submit) ` ), {
        errorMessage: _errorMessage,
        from
      })

      return setErrorMessage( _errorMessage )
    }

    if ( !result?.data ) {
      reportToSentry( new Error ( `Error Creating Account (createPatient response returned no data)` ) )

      return setErrorMessage( `There was an issue creating your account.` )
    }

    const loginResult = await login()

    if ( loginResult && loginResult.data && loginResult.data.generateCustomerToken ) {
      setCustomerToken( loginResult.data.generateCustomerToken.token )
      const redirectable = getRedirectable( from )

      return navigate( redirectable?.redirectLocation ?? MY_ACCOUNT_PATH, {
        replace: true
      })
    }

    if ( loginResult?.errors ) {
      const _errorMessage = loginResult.errors.map( errorObject => { return errorObject.message }).join( ` ` )
      reportToSentry( `Error logging in newly migrated user`, {
        errorMessage: _errorMessage
      })

      return setErrorMessage( _errorMessage )
    }
  }

  if ( loading ) {
    return (
      <div className="my-8">
        <p className="mb-2">{`Creating Account ...`}</p>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="my-5">
      <TextInput
        name="email" label="Email" type="email"
        value={formFields.email}
        onChange={handleChange}
        required
        disabled={Boolean ( email )}
      />

      <PasswordInput
        name="password" label="Password"
        value={formFields.password}
        onChange={handleChange}
        required
        validator={{
          function: Validators.password,
          failureMessage: `Your password must contain lower case, upper case and at least one number or special character.`
        }}
        reference={passwordRef}
      />

      <PasswordInput
        name="passwordConfirmation" label="Confirm Password"
        value={formFields.passwordConfirmation}
        onChange={handleChange}
        required
        validator={{
          function: Validators.password,
          failureMessage: `Your password must contain lower case, upper case and at least one number or special character.`
        }}
        reference={passwordConfirmationRef}
      />
      {pwdConfirmationError && <ErrorField message={pwdConfirmationError} />}

      <div className="flex sm:flex-col">
        <FormButton disabled={pwdFieldsHaveError} className="sm:mr-0 btn-secondary">{`CREATE ACCOUNT`}</FormButton>
      </div>

      {errorMessage && <ErrorField message={errorMessage} />}

      <p className="text-xs text-center text-error my-6">{`* Required Fields`}</p>
      <p className="text-xs text-center text-blue my-6">{`Already have an account? `}
        <Link className="a" to="/login">{`Click here to login`}</Link>
      </p>
    </form>
  )
}
CreateAccountForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  dateOfBirth: PropTypes.string,
  sleepHash: PropTypes.string,
  locationAfterLogin: PropTypes.string
}

export default CreateAccountForm
