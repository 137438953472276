import React, { useState, useMemo } from 'react'
import { fetchSnlData } from '../fetch'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import { StyledErrorWrapper } from 'components/layoutUi'
import { PageTracker } from '..'
import { reportToSentry } from 'utils/reportToSentry'
import LoadingSpinner from 'components/LoadingSpinner'
import { BrandSelector, ModelSelector, IntentOrderSelector, RecentPurchaseYear } from 'components/SNLSelectors'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { machineImages } from 'modules/sleepNewLeads/constants'
import { scrollToBelowLogo } from 'components/scrollToTop'
import { Validators } from 'components/forms/components'
import NewMachineIneligibleModal from './NewMachineIneligibleModal'
import { SNLBackButton } from '../SNLBackButton'

const groupedMachineImages = groupBy( machineImages, `brand` )

const stepTargets = [
  `lastOrderCpapMachine`,
  `intentOrderNewEquipment`,
  `cpapMachineBrand`,
  `cpapMachineModel`
]

const Machine = ({onHandleChange, formValues, submitLeadNoMachine, setMachineEligible, ...pageProps}) => {
  const [ baseEquipment, setBaseEquipment ] = useState( [] )
  const [ errorMessage, setErrorMessage ] = useState( `` )
  const [ handleChangeTarget, setHandleChangeTarget ] = useState( `lastOrderCpapMachine` )
  const [ isLoading, setIsLoading ] = useState( false )
  const [ displayModal, setDisplayModal ] = useState( false )

  React.useEffect( () => {
    setIsLoading( true )

    const controller = new AbortController()
    fetchSnlData( `/machine-data`, controller.signal ).then( res => {
      setIsLoading( false )
      if ( !res ) {
        reportToSentry( `Failed to load machine data`, {
          route: `/machine-data`
        })

        return setErrorMessage( `Oops! Looks like something went wrong, please try again or call us at ${AF_SUPPORT_PHONE_NUMBER} for help.` )
      }
      setBaseEquipment( res.response )
    })
      .catch( err => {
        if ( err.name !== `AbortError` ) {
          reportToSentry( err )
          setIsLoading( false )

          return setErrorMessage( `Oops! Looks like something went wrong, please try again or call us at ${AF_SUPPORT_PHONE_NUMBER} for help.` )
        }
      })

    return () => {
      controller.abort()
    }
  }, [] )


  const handleNextMobile = ( value ) => {
    scrollToBelowLogo()

    if ( handleChangeTarget === `intentOrderNewEquipment` && value === `true` ) {

      const yearLastOrderMachine = formValues.lastOrderCpapMachine

      const today = new Date()
      const currentYear = today.getFullYear()

      const yearDiff = currentYear - yearLastOrderMachine

      if ( yearLastOrderMachine === `2` || yearDiff < 5 ) {
        setMachineEligible( false )

        return setDisplayModal( true )
      } else setMachineEligible( true )
    }

    if ( handleChangeTarget === `lastOrderCpapMachine` && value === `3` ) {
      setIsLoading( true )

      return submitLeadNoMachine()
    }

    if ( stepTargets[stepTargets.length - 1] === handleChangeTarget ) return pageProps.nextPage()

    const index = stepTargets.findIndex( item => { return item === handleChangeTarget }) || 0
    setHandleChangeTarget( stepTargets[index + 1] )
  }

  const handleBackMobile = () => {
    scrollToBelowLogo()
    if ( handleChangeTarget && stepTargets[0] !== handleChangeTarget ) {
      const index = stepTargets.findIndex( item => { return item === handleChangeTarget }) || 0
      setHandleChangeTarget( stepTargets[index - 1] )
    } else {
      onHandleChange({
        ...formValues,
        cpapMachineBrand: ``
      })
      setHandleChangeTarget( stepTargets[0] )
      pageProps.prevPage()
    }
  }

  const machines = useMemo( () => {
    if ( !formValues.cpapMachineBrand ) return []

    const machineNames = baseEquipment.find( entry => {
      return entry.brand === formValues.cpapMachineBrand
    })?.machineNames || []

    return Object.entries( groupBy( machineNames, `name` ) )
      .map( ( item ) => { return item[1][0] })
      .reduce( ( result, item ) => {
        const matchedImgSrc = groupedMachineImages[formValues.cpapMachineBrand]?.find( mItem => { return mItem.machine === item.name })?.imgSrc

        return matchedImgSrc ? [ ...result, {
          ...item,
          imgSrc: matchedImgSrc
        }] : result
      }, [] )
  }, [ formValues.cpapMachineBrand, baseEquipment ] )

  const handleChange = ( e ) => {
    if ( e.target.name === `cpapMachineModel` ){
      onHandleChange({
        ...formValues,
        [e.target.name]: e.target.label,
        cpapMachineDmeID: e.target.value
      })
      handleNextMobile( e.target.value )
    } else if ( e.target.name === `cpapMachineBrand` ) {
      // Only other option is cpapMachineBrand so we want to reset other machine inputs if there is a change here
      onHandleChange({
        ...formValues,
        cpapMachineDmeID: ``,
        cpapMachineModel: ``,
        [e.target.name]: e.target.value
      })
      handleNextMobile( e.target.value )

    } else {
      onHandleChange({
        ...formValues,
        [e.target.name]: e.target.value
      })

      // handle submitting the year after click submit year button
      if ( e.target.innerText === `Submit year` ) {
        if ( Validators.dobYear( formValues.lastOrderCpapMachine ) && parseInt( formValues.lastOrderCpapMachine ) >= 2000 ) return handleNextMobile( `submit year` )
      } else if ( e.target.type !== `text` ) return handleNextMobile( e.target.value )
    }
  }

  const handleCloseModal = () => {
    setDisplayModal( false )
    pageProps.nextPage()
  }

  const manufacturers = baseEquipment.map( entry => {
    return entry.brand
  })

  if ( isLoading ) {
    return <div>
      <PageTracker
        title={`Your Machine`}
      />
      <LoadingSpinner />
    </div>
  }

  return (
    <div>
      <NewMachineIneligibleModal
        displayModal={displayModal}
        handleCloseModal={handleCloseModal}
        handleNoLongerInterested={pageProps.handleNoLongerInterested}
      />
      <PageTracker
        title={`Your Machine`}
        description={handleChangeTarget === `cpapMachineBrand` && `Your health insurance may cover various items for your machine like filters & humidifiers. We need your machine info to identify this information for you.`}
      />

      <RecentPurchaseYear
        heading={`What year did you receive your current CPAP machine?`}
        name="lastOrderCpapMachine"
        selectedValue={formValues.lastOrderCpapMachine}
        onChange={handleChange}
        showOnMobile={handleChangeTarget === `lastOrderCpapMachine`}
      />

      <IntentOrderSelector
        heading={`Did you fill out our online form with the intention of ordering a new PAP machine through insurance?`}
        name="intentOrderNewEquipment"
        options={[
          {
            label: `Yes`,
            value: true
          },
          {
            label: `No`,
            value: false
          }
        ]}
        selectedValue={formValues.intentOrderNewEquipment}
        onChange={handleChange}
        showOnMobile={handleChangeTarget === `intentOrderNewEquipment`}
      />

      <BrandSelector
        heading={`What brand is your current Machine?`}
        name="cpapMachineBrand"
        brandNames={manufacturers}
        selectedValue={formValues.cpapMachineBrand}
        onChange={( name, value ) => {
          return handleChange({
            target: {
              name,
              value
            }
          })
        }}
        showOnMobile={!handleChangeTarget || handleChangeTarget === `cpapMachineBrand`}
      />

      <ModelSelector
        heading={`What model ${formValues.cpapMachineBrand} Machine do you have?`}
        name="cpapMachineModel"
        machines={machines}
        selectedValue={formValues.cpapMachineDmeID}
        onChange={handleChange}
        showOnMobile={handleChangeTarget === `cpapMachineModel`}
      />

      <StyledErrorWrapper>
        {errorMessage}
      </StyledErrorWrapper>
      <SNLBackButton
        hide={handleChangeTarget === stepTargets[0]}
        handleBack={handleBackMobile}
      />
    </div>
  )
}

Machine.propTypes = {
  onHandleChange: PropTypes.func,
  formValues: PropTypes.shape({
    cpapMachineBrand: PropTypes.string,
    cpapMachineModel: PropTypes.string,
    cpapMachineDmeID: PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
    lastOrderCpapMachine: PropTypes.string,
    intentOrderNewEquipment: PropTypes.string
  }),
  submitLeadNoMachine: PropTypes.func,
  setMachineEligible: PropTypes.func
}

export default Machine
