import React from 'react'
import PropTypes from 'prop-types'

const IntentOrderSelector = ({
  heading,
  name,
  options,
  onChange,
  selectedValue,
  showOnMobile
}) => {

  return (
    <>
      {showOnMobile && <div className="mt-4">
        <div className="text-center text-lg mb-4">{heading}</div>
        <div className="flex justify-center mt-4">
          <div>
            {
              options.map( ( item ) => {
                return (
                  <button
                    name={name}
                    value={item.value}
                    key={item.label}
                    className={selectedValue === String( item.value ) ? `btn-secondary-selected` : `btn-secondary`}
                    onClick={onChange}
                  >
                    { item.label }
                  </button>
                )
              })
            }
          </div>

        </div>

      </div>}
    </>
  )
}

IntentOrderSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  defaultLabel: PropTypes.string,
  showOnMobile: PropTypes.bool
}

export default IntentOrderSelector
