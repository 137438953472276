import { reportToSentry } from "utils/reportToSentry"
import AddressP from "../components/AddressP"
import Alert from "../components/Alert"
import { useAddressStore } from "../state"
import { useFetch } from "hooks"
import LoadingSpinner from "components/LoadingSpinner"

export default function ConfirmAddress({ onSuccessfulConfirm } : { onSuccessfulConfirm?: () => void }) : JSX.Element {
  const { address, setCurrentView, verificationFlags, setAddressWebConfirmed } = useAddressStore()

  const { lazyFetch : submitConfirmAddress, isLoading } = useFetch( `${process.env.REACT_APP_VALIDATION_URL}/address/confirm`, {
    method: `POST`,
    body: JSON.stringify({
      place_id: verificationFlags.placeId,
      user_confirm_key: verificationFlags.userConfirmKey,
      confirmation_type: `CUSTOMER`
    })
  }, {
    onSuccess: () => {
      setAddressWebConfirmed( false ) // This lets internal users know that the address was unable to be verified by the verification api
      setCurrentView( `complete` )
      if ( onSuccessfulConfirm ) onSuccessfulConfirm()
    },
    onErrorOverride: () => {
      console.error( `Something happened submitting address/confirm` )
      reportToSentry( new Error( `Something happened submitting address/confirm` ), {
        placeId: verificationFlags.placeId,
        userConfirmKey: verificationFlags.userConfirmKey
      })
      setCurrentView( `complete` )
    }
  })

  return (
    <div className="border-4 p-3 rounded-xl flex flex-col items-center gap-6 max-w-md mx-auto">
      <Alert text={`You have chosen:`} />
      <AddressP address={address} className="text-center" />
      <p className="font-light text-[#B30000]">{`This is not a confirmed address, this could affect your delivery.`}</p>
      { isLoading && <LoadingSpinner />}
      <div className="max-w-sm">
        <button className="btn btn-secondary" onClick={() => submitConfirmAddress()} disabled={isLoading}>{`Continue with this Address`}</button>
        <button className="btn btn-primary" onClick={() => setCurrentView( `verify` )}>{`Go back and Change Address`}</button>
      </div>
    </div>
  )
}