import { TextInput, Validators } from 'components/forms/components'
import { regexErrors } from 'components/forms/constants'
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

const RecentPurchaseYear = ({
  heading,
  name,
  selectedValue,
  onChange,
  showOnMobile
}) => {
  const [ inputType, setInputType ] = useState( 0 )

  const purchaseYearRef = useRef( null )

  const lastOrderCPAPYearValidator = ( year ) => {
    // This validator is specific for when users try to put in a year with more than 4 digits
    // because the input will not allow more than 4 digits but the error was still displaying

    return ( Validators.dobYear( year ) && parseInt( year ) >= 2000 ) || !year.length || year.length === 5
  }

  const handleInputChange = ( e, type ) => {

    if ( type !== 1 ) {
      setInputType( type )
      onChange({
        target: {
          name: e.target.name,
          value: String( type )
        }
      })
    } else {
      setInputType( type )
      if ( e.target.value.length < 5 && ( ( /^[0-9]+$/ ).test( e.target.value ) || !e.target.value.length ) ) onChange( e )
    }

  }

  if ( !showOnMobile ) return null

  return (
    <>
      <div className="mt-4">
        <div className="text-center text-lg mb-4">{heading}</div>
      </div>
      <div className="mt-4 flex flex-col max-w-lg mx-auto">
        <TextInput
          name={name}
          placeholder="YYYY"
          label="Year"
          value={inputType === 1 ? selectedValue : ``}
          onChange={( e ) => { return handleInputChange( e, 1 ) }}
          validator={{
            function: lastOrderCPAPYearValidator,
            failureMessage: `${regexErrors.dob_yyyy} from 2000 to the current year`
          }}
          reference={purchaseYearRef}
        />
        {
          inputType === 1 && selectedValue.length > 0 &&
            <button
              className="btn-secondary max-w-xs mx-auto"
              onClick={( e ) => { return handleInputChange( e, 1 ) }}
            >
              {`Submit year`}
            </button>
        }
      </div>
      <div className="flex flex-col gap-4 justify-center mt-10 max-w-xs mx-auto">
        <button
          name={name}
          onClick={( e ) => { return handleInputChange( e, 2 ) }}
          className={selectedValue === `2` ? `btn-secondary-selected` : `btn-secondary`}
        >
          {`I don't know`}
        </button>
        <button
          name={name}
          onClick={( e ) => { return handleInputChange( e, 3 ) }}
          className={selectedValue === `3` ? `btn-secondary-selected` : `btn-secondary`}
        >
          {`I was recently diagnosed with sleep apnea and do not have a machine`}
        </button>
      </div>

    </>
  )
}

RecentPurchaseYear.propTypes = {
  onChange: PropTypes.func,
  heading: PropTypes.string,
  name: PropTypes.string,
  selectedValue: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
  showOnMobile: PropTypes.bool
}

export default RecentPurchaseYear
