import React from 'react'
import ReactHtmlParser from 'react-html-parser'

function SearchDoctorHeading({ textContent, headerStyle } : { textContent?: string, headerStyle?: string }) : JSX.Element {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transform = ( node: any ) => {
    if ( node?.name === `style` ) return null
    if ( node?.name === `p` ) node.attribs.class= `font-light`
    if ( node?.name === `h2` && headerStyle ) node.attribs.class = headerStyle
  }

  if ( textContent ) return (
    <>
      {
        ReactHtmlParser( textContent, {
          transform
        })
      }
    </>
  )

  return (
    <>
      <h1 className={`${headerStyle ?? `text-3xl font-light`} text-center mb-3`}>{`Search for your Doctor`}</h1>
      <p className="font-light text-center mb-10">{`Please provide your doctor's information. We will send a request to your doctor to approve your healthcare supplies.`}</p>
    </>
  )
}

export default SearchDoctorHeading