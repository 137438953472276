import React from 'react'
import { Builder } from '@builder.io/react'
import './components/utils/reviewCarousel.css'
import SlickSlider from './components/utils/SlickSlider'
import { goldStar, grayStar } from 'resources/images'

type ReviewCarouselProps = {
    slides: ReviewCarouselTypes[];
    infiniteCarouselScroll: boolean;
    showDots: boolean;
    text: string;
    linkText: string;
    url: string;
    openInNewTab: boolean;
}

type ReviewCarouselTypes = {
  stars: number;
  reviewText: string;
  reviewAuthor: string;
}

const createStars = ( stars: number ) => {
  return Array.from({ length: 5 }, ( _, i ) => (
    <img
      key={i}
      src={i < stars ? goldStar : grayStar}
      alt="star"
      className="sm:max-h-6 md:max-h-10"
    />
  ) )
}

export const ReviewCarousel = ( props: ReviewCarouselProps ) : JSX.Element => {
  return (
    <div className="sm:px-5 lg:px-7 lg:max-w-3xl mx-auto w-full sm:pt-7 sm:pb-11 md:pt-12 md:pb-16 hp-text-carousel">
      {props.slides &&
        <>
          <SlickSlider infiniteCarouselScroll={props.infiniteCarouselScroll} showDots={props.showDots}>
            {props.slides?.map( ( slide ) => (
              <div key={slide.reviewAuthor} className="text-aeroflowNavy">
                <div className="flex sm:gap-1 md:gap-3 justify-center items-center">
                  {createStars( slide.stars )}
                </div>
                <h3 className="text-center mt-4 font-normal italic sm:text-2xl md:text-4xl md:leading-[45px]">{slide.reviewText}</h3>
                <p className="text-center mt-4 mb-2 font-source-serif-4 sm:text-xl lg:text-2xl">{slide.reviewAuthor}</p>
              </div>
            ) )}
          </SlickSlider>
        </>
      }
    </div>
  )
}

Builder.registerComponent( ReviewCarousel, {
  name: `ReviewCarousel`,
  inputs: [
    {
      name: `slides`,
      type: `list`,
      required: true,
      subFields: [
        {
          name: `stars`,
          type: `number`,
          required: true
        },
        {
          name: `reviewText`,
          type: `longText`,
          required: true
        },
        {
          name: `reviewAuthor`,
          type: `text`,
          required: true
        }
      ]
    },
    {
      name: `infiniteCarouselScroll`,
      type: `boolean`,
      defaultValue: true
    },
    {
      name: `showDots`,
      type: `boolean`,
      defaultValue: true
    }
  ]
})


export default ReviewCarousel