import React from 'react'
import PropTypes from 'prop-types'
import CheckIcon from './CheckIcon'
// import { SelectInput } from 'components/forms/components'
import { maskTypeImages } from 'modules/sleepNewLeads/constants'

const MaskTypeSelector = ({
  heading,
  name,
  maskTypes,
  selectedValue,
  onChange,
  showOnMobile
}) => {
  const availableImages = maskTypeImages.filter( item => { return ( maskTypes || [] ).includes( item.type ) })
  if ( !availableImages || !availableImages.length ) return null

  return (
    <>
      {showOnMobile && <div className="mt-4">
        <div className="text-center text-xl mb-6">{heading}</div>
        <div className="grid grid-cols-2 gap-6">
          {availableImages.map( ({type, imgSrc}) => {
            return (
              <div key={type}>
                <div
                  className={`cursor-pointer border flex items-center relative ${type === selectedValue ? `border-deepSleepBlue` : `border-lightGray`}`}
                  onClick={() => {
                    return onChange({
                      target: {
                        name,
                        value: type
                      }
                    })
                  }}
                >
                  {type === selectedValue && <CheckIcon />}
                  <img
                    src={imgSrc}
                    className="w-full h-auto"
                    alt={type}
                  />
                </div>
                <p
                  onClick={() => {
                    return onChange({
                      target: {
                        name,
                        value: type
                      }
                    })
                  }}
                  className="cursor-pointer text-sm font-light"
                >
                  {type}
                </p>
              </div>
            )
          })}
        </div>
      </div>}
    </>
  )
}

MaskTypeSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  maskTypes: PropTypes.array,
  selectedValue: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
  onChange: PropTypes.func,
  showOnMobile: PropTypes.bool
}

export default MaskTypeSelector
