import React from 'react'
import PropTypes from 'prop-types'
import { maskImages } from 'modules/sleepNewLeads/constants'

const LastFreshMaskSelector = ({
  heading,
  name,
  options,
  selectedModel,
  onChange,
  selectedValue,
  // defaultLabel = `Select an Option`,
  showOnMobile
}) => {
  const currentMask = maskImages.find( item => { return item.mask === selectedModel })
  if ( !currentMask ) return null

  return (
    <>
      {
        showOnMobile && (
          <div className="mt-3">
            <div className="text-center text-xl mb-4">{heading}</div>
            <div className="flex flex-col items-center justify-center mt-4 gap-4 max-w-xs mx-auto">
              {
                options.map( ( item ) => {
                  return (
                    <button
                      name={name}
                      value={item.value}
                      onClick={onChange}
                      key={item.value}
                      className={selectedValue === item.value ? `btn-secondary-selected` : `btn-secondary`}
                    >
                      { item.label }
                    </button>
                  )
                })
              }
            </div>
          </div>
        )
      }
    </>
  )
}

LastFreshMaskSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  selectedModel: PropTypes.string,
  onChange: PropTypes.func,
  defaultLabel: PropTypes.string,
  showOnMobile: PropTypes.bool
}

export default LastFreshMaskSelector
