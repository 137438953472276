import React, { useState } from 'react'
import PricingEstimateBlockWrapper from '../PricingEstimateBlockWrapper'
import PropTypes from 'prop-types'
import { icoCaretDownAob, icoXAob } from 'resources/images'
import OrderItemsDetails from './OrderItemsDetails'

function OrderSummaryDetails({invoice, setInvoice, removedItems, setRemovedItems, blockProceed, setBlockProceed }) {

  const [ displayOrderItems, setDisplayOrderItems ] = useState( true )
  const [ editOrderError, setEditOrderError ] = useState( null )

  return (
    <PricingEstimateBlockWrapper>
      <div className="flex">
        <p className="text-xl tracking-wide font-bold text-center py-4 cursor-pointer" onClick={() => { return setDisplayOrderItems( !displayOrderItems ) }}>{`Order Summary Details`}</p>
        <img
          src={displayOrderItems ? icoXAob : icoCaretDownAob}
          alt={displayOrderItems ? `ico-x` : `ico-caret-down`}
          className="object-contain ml-2 cursor-pointer hover:opacity-80"
          width={18}
          onClick={() => { return setDisplayOrderItems( !displayOrderItems ) }}
        />
      </div>
      <OrderItemsDetails
        invoice={invoice}
        displayOrderItems={displayOrderItems}
        setEditOrderError={setEditOrderError}
        setInvoice={setInvoice}
        removedItems={removedItems}
        setRemovedItems={setRemovedItems}
        blockProceed={blockProceed}
        setBlockProceed={setBlockProceed}
      />
      {
        editOrderError &&
        <p className="text-error px-3 mb-5 text-center">{editOrderError}</p>
      }
    </PricingEstimateBlockWrapper>
  )
}

OrderSummaryDetails.propTypes = {
  invoice: PropTypes.object,
  patientId: PropTypes.string,
  setInvoice: PropTypes.func,
  removedItems: PropTypes.array,
  setRemovedItems: PropTypes.func,
  blockProceed: PropTypes.bool,
  setBlockProceed: PropTypes.func
}

export default OrderSummaryDetails