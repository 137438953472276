export type TileProps = {
  headerContent?: JSX.Element | React.ReactNode;
  children: JSX.Element | React.ReactNode;
  className?: string;
  onClick?: () => void;
  id?: string;
}
export default function Tile({ headerContent, children, className, onClick, id } : TileProps ) : JSX.Element {
  return (
    <div className="p-3 w-full" id={id}>
      <div className="tile-border max-w-sm my-3 mx-auto" onClick={onClick ? onClick : () => { return }}>
        {headerContent && (
          <div className="sleep-gradient-lightToDark text-white rounded-t-lg px-4 py-2 text-left text-lg">
            {headerContent}
          </div>
        )}
        <div className={`p-4 ${className ?? ``}`}>
          {children}
        </div>
      </div>
    </div>
  )
}