import React from 'react'
import PropTypes from 'prop-types'
// import { SelectInput } from 'components/forms/components'
import CheckIcon from './CheckIcon'

const ModelSelector = ({
  heading,
  name,
  machines,
  selectedValue,
  onChange,
  showOnMobile
}) => {
  return (
    <>
      {showOnMobile && <div className="mt-4">
        <div className="text-center text-lg mb-6">{heading}</div>
        {machines.length === 0 && (
          <p className="text-center">{`No models for current brand`}</p>
        )}
        <div className="grid grid-cols-2 gap-6">
          {machines.map( ( machine ) => {
            return (
              <div key={machine.dme_id} className="mb-6" >
                <div
                  className={`cursor-pointer flex border min-h-full relative ${machine.dme_id === selectedValue ? `border-deepSleepBlue` : `border-lightGray`}`}

                  onClick={() => {
                    return onChange({
                      target: {
                        name,
                        value: machine.dme_id,
                        label: machine.name
                      }
                    })
                  }}
                >
                  {machine.dme_id === selectedValue && <CheckIcon />}
                  <img
                    src={machine.imgSrc}
                    className="w-full self-center"
                    alt={machine.name}
                    width="306"
                  />
                </div>
                <p
                  onClick={() => {
                    return onChange({
                      target: {
                        name,
                        value: machine.dme_id,
                        label: machine.name
                      }
                    })
                  }}
                  className="cursor-pointer text-sm"
                >
                  {machine.name}
                </p>
              </div>
            )
          })}
        </div>
      </div>}
    </>
  )
}

ModelSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  machines: PropTypes.array,
  selectedValue: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
  onChange: PropTypes.func,
  showOnMobile: PropTypes.bool
}

export default ModelSelector
