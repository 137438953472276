import React from 'react'
import PropTypes from 'prop-types'
import { account_img_blue } from 'resources/images'
import { useTokenStore } from 'stores/tokenStore'
import { useQuery } from '@apollo/client'
import { customerAddressesQuery } from 'graphql/queries'
import { reportToSentry } from 'utils/reportToSentry'

const AccountMenuButton = ({isMobile, menuIsActive}) => {
  const { customerToken } = useTokenStore()

  const { data: customerData } = useQuery( customerAddressesQuery, {
    onError: ( error ) => {
      reportToSentry( `customerAddressesQuery Error -> AddressesContactInfo`, {
        error: error?.message,
        jsonError: JSON.stringify( error )
      })
    }
  })

  const getCustomerInitials = () => {
    return `${customerData?.customer?.firstname.charAt( 0 )}${customerData?.customer?.lastname.charAt( 0 )}`
  }

  if ( isMobile && menuIsActive ) return null

  if ( customerToken ) {
    return (
      <div
        className="flex items-center"
      >
        <button type="button" className="focus:outline-none cursor-pointer min-w-7">
          <img
            src={account_img_blue}
            width="27"
            height="27"
            className="object-cover" alt="Account Menu Button"
          />
        </button>
        {customerData?.customer && (
          <p className="text-[#004C97] sm:text-xs md:text-base">
            {isMobile ? (
              <>
                {`Hello,`} <br />
                {getCustomerInitials()}
              </>
            ) : (
              `Hello ${getCustomerInitials()}`
            )}
          </p>
        )}
      </div>
    )
  }
}
AccountMenuButton.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  menuIsActive: PropTypes.bool.isRequired
}

export default AccountMenuButton
