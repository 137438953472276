import React from 'react'
import PropTypes from 'prop-types'

import { phoneImg } from 'resources/images'

const PhoneNumber = ({ containerClassName, linkClassName, imgClassName}) => {
  return (
    <div className={containerClassName ?? `flex sm:hidden md:block`}>
      <a
        className={linkClassName ?? `mr-3 sm:text-xs lg:text-base whitespace-nowrap`}
        href="tel:+18004805491" type="tel"
      >
        <img
          src={phoneImg}
          height="16"
          width="16"
          className={imgClassName ?? `pr-2 inline`}
          alt="Phone Image"
        />
        {`1 (800) 480-5491`}
      </a>
    </div>
  )
}

PhoneNumber.propTypes = {
  containerClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  imgClassName: PropTypes.string
}

export default PhoneNumber
