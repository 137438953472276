import { getAuthHeader } from 'utils/auth/helpers'
import { getPatientId } from 'utils/patientInfoApi'
import { reportToSentry } from 'utils/reportToSentry'

type DataLayerEvent = {
  [key: string]: string;
};

declare global {
  interface Window {
    dataLayer: DataLayerEvent[];
  }
}

/**
 *
 * @param { string } event - this is an event you want to track in GA
 * @param {EventData} eventData - An object containing the custom data to push with the event.
 */

export const trackAnalyticEvent = ( event: string, eventData?: DataLayerEvent ) : void => {
  if ( window.location.origin === `https://aeroflowsleep.com` ) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': event,
      ...eventData
    })
  }
}

export const trackUserLoginEvent = ( ) : void => {
  getPatientId( getAuthHeader() )
    .then( ( patientId ) => {
      trackAnalyticEvent( `customer_login` )

      if ( patientId ) trackAnalyticEvent( `customer_reload`, { patient_id: patientId.toString() })
    })
    .catch( ( error ) => {
      reportToSentry( new Error( `error getting patient id for analytic event`, {
        cause: JSON.stringify( error ?? `` )
      }) )
    })
}