import React from 'react'
import PropTypes from 'prop-types'

const MaskSatisfiedSelector = ({
  heading,
  name,
  options,
  onChange,
  selectedValue,
  // defaultLabel = `Select an Option`,
  showOnMobile
}) => {

  return (
    <>
      {showOnMobile && <div className="mt-4">
        <div className="text-center text-xl mb-4">{heading}</div>
        <p className="text-center text-graphite text-sm">{`If you are currently unhappy with your current mask, we will send you a link via text message to use our mask-fitting technology which will identify the mask that would suit your face shape the best.`}</p>
        <div className="flex flex-col items-center justify-center mt-4 gap-4 max-w-xs mx-auto">
          {
            options.map( ( item ) => {
              return (
                <button
                  name={name}
                  value={item.value}
                  onClick={onChange}
                  key={item.label}
                  className={selectedValue === String( item.value ) ? `btn-secondary-selected` : `btn-secondary`}
                >
                  { item.label }
                </button>
              )
            })
          }
        </div>

      </div>}
    </>
  )
}

MaskSatisfiedSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  defaultLabel: PropTypes.string,
  showOnMobile: PropTypes.bool
}

export default MaskSatisfiedSelector
