import React, { useEffect } from 'react'
import { maskFittingEmailCopy, maskFittingHeader, maskFittingOnNextScreenCopy } from './constants'
import Frame from './Frame'
import { maskFittingImg } from 'resources/images'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'
import { SNLStorage } from 'apollo'

export const MaskFitting = ({maskFittingContent, ...pageProps}) => {

  useEffect( () => {
    const { formValues } = SNLStorage.getSession()

    if ( pageProps.formValues.satisfiedWithCurrentMask === `true` || !formValues ) pageProps.nextPage()
  }, [] )

  const transformWidget = ( node ) => {
    if ( node.type === `tag` ) node.attribs.style = ``
    if ( node?.name === `h3` ) node.attribs.class = `text-xl md:text-3xl`
    if ( node?.attribs?.class?.includes( `email-copy` ) ) node.attribs.class = emailCopyStyling
    if ( node?.attribs?.class?.includes( `onnext-copy` ) ) node.attribs.class = onNextCopyStyling
  }

  const emailCopyStyling = `text-sm md:text-base mt-3 md:mt-5 max-w-lg`
  const onNextCopyStyling = `text-sm md:text-base my-5 md:my-8 max-w-lg`

  return (
    <div className="flex flex-col items-center text-center min-w-fit">
      <div className="mb-7 relative flex justify-center">
        <Frame />
        <img
          src={maskFittingImg}
          alt="mask_fitting_image"
          width={170}
        />
      </div>
      {
        ( maskFittingContent?.data?.cmsBlocks?.items?.length > 0 && maskFittingContent.data.cmsBlocks.items[0].content ) ?
          <>
            {
              ReactHtmlParser( maskFittingContent.data.cmsBlocks.items[0].content, {
                transform: transformWidget
              })
            }
          </>
          :
          <>
            <h3 className="text-xl md:text-3xl">{maskFittingHeader}</h3>
            <p className={emailCopyStyling}>{maskFittingEmailCopy}</p>
            <p className={onNextCopyStyling}>{maskFittingOnNextScreenCopy}</p>
          </>
      }

      <button onClick={() => { return pageProps.nextPage() }} className="btn-secondary max-w-xs">
        {`Proceed`}
      </button>
    </div>
  )
}

MaskFitting.propTypes = {
  maskFittingContent: PropTypes.object
}

export default MaskFitting