import { createJSONStorage, persist } from 'zustand/middleware'
import { Address, ViewState, VerificationFlags } from './types'
import { create } from 'zustand'


type AddressStore = {
  address: Address;
  setAddress: ( _address : Address ) => void;
  currentView: ViewState;
  setCurrentView: ( _currentView : ViewState, _scrollIntoView?: boolean ) => void;
  isAddressComplete: boolean;
  setIsAddressComplete: ( _isAddressComplete : boolean ) => void;
  verifiedAddress: Address;
  addressWebConfirmed: boolean;
  addressPatientConfirmed: boolean;
  setVerifiedAddress: ( _verifiedAddress : Address ) => void;
  verificationFlags: VerificationFlags;
  setVerificationFlags: ( _verificationFlags : VerificationFlags ) => void;
  userConfirmedAddress: `original` | `verified`;
  setUserConfirmedAddress: ( _userConfirmedAddress : `original` | `verified` ) => void;
  suggestionHighlights: Record<string, {value: string, highlight: boolean}> | null;
  setSuggestionHighlights: ( _suggestionHighlights : Record<string, {value: string, highlight: boolean}> | null ) => void;
  setAddressWebConfirmed: ( _addressWebConfirmed : boolean ) => void;
  setAddressPatientConfirmed: ( _addressPatientConfirmed : boolean ) => void;
}

export const useAddressStore = create<AddressStore>()(
  persist( ( set ) => {
    return {
      address: {
        firstName: ``,
        lastName: ``,
        street: ``,
        street2: ``,
        city: ``,
        state: ``,
        zipCode: ``
      },
      currentView: `edit`,
      isAddressComplete: false,
      verifiedAddress: {
        street: ``,
        street2: ``,
        city: ``,
        state: ``,
        zipCode: ``
      },
      verificationFlags: {
        promptForSubpremise: false,
        unconfirmedComponentTypes: [],
        inferredComponentTypes: [], // We are now using suggestionHighlights instead, but this is still here for backwards compatibility
        placeId: ``,
        userConfirmKey: ``
      },
      userConfirmedAddress: `verified`,
      suggestionHighlights: null,
      addressWebConfirmed: false, // This means the address was verified by the verification API
      addressPatientConfirmed: false, // This means the address was verified by the Google Place AutoComplete API
      setAddress: ( _address ) => ( set({
        address: {
          ..._address
        }
      }) ),
      setIsAddressComplete: ( _isAddressComplete ) => set({
        isAddressComplete: _isAddressComplete
      }),
      setCurrentView: ( _currentView, scrollIntoView = true ) => {
        if ( scrollIntoView ) {
          // when changing views scroll to the top of the address block so everything is in view for the user automatically
          // without a need for scrolling
          const addressBlockElement = document.getElementById( `address-block` )
          addressBlockElement?.scrollIntoView({
            behavior: `smooth`,
            block: `start`
          })
        }
        set({
          currentView: _currentView
        })
      },
      setVerifiedAddress: ( _verifiedAddress ) => set({
        verifiedAddress: {
          ..._verifiedAddress
        }
      }),
      setVerificationFlags: ( _verificationFlags ) => set({
        verificationFlags: {
          ..._verificationFlags
        }
      }),
      setUserConfirmedAddress: ( _userConfirmedAddress ) => set({
        userConfirmedAddress: _userConfirmedAddress
      }),
      setSuggestionHighlights: ( _suggestionHighlights ) => set({
        suggestionHighlights: _suggestionHighlights
      }),
      setAddressWebConfirmed: ( _addressWebConfirmed ) => set({
        addressWebConfirmed: _addressWebConfirmed
      }),
      setAddressPatientConfirmed: ( _addressPatientConfirmed ) => set({
        addressPatientConfirmed: _addressPatientConfirmed
      })
    }
  },
  {
    name: `address-store`,
    storage: createJSONStorage( () => sessionStorage )
  })
)


