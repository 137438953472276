import { type VerificationFlags, type Address, type GoogleAddressComponent } from './types'

/* Takes in a field from the api address type and returns the field name in the format to be consumed by the ui */
export function serializeAddressFieldFromApi( apiAddressField : string, shouldBePretty? : boolean ) : string {
  if ( apiAddressField === `address1` ) return shouldBePretty ? `street address` : `street`
  if ( apiAddressField === `address2` ) return shouldBePretty ? `street address` : `street2`
  if ( apiAddressField === `zip` ) return shouldBePretty ? `zip code` : `zipCode`
  return apiAddressField
}

/** Takes in Address type and returns the original_address fields in the format to be consumed by the api  */
export function serializeToOriginalAddress( address : Address ) : Record<string, string> {
  return {
    original_address1: address.street,
    original_address2: address.street2,
    original_city: address.city,
    original_state: address.state,
    original_zip: address.zipCode
  }
}

/**  Takes in the verifiedAddress format received from the api and returns the Address type */
export function serializeFromVerifiedAddress( verifiedAddress: Record<string, any> ) : Address {
  return {
    street: verifiedAddress.validated_address1,
    street2: verifiedAddress.validated_address2,
    city: verifiedAddress.validated_city,
    state: verifiedAddress.validated_state,
    zipCode: verifiedAddress.validated_zip
  }
}

/** Takes in the verification_flags format received from the api and returns the VerificationFlags type */
export function serializeVerificationFlags( flags_from_api : Record<string, any> ) : VerificationFlags {
  return {
    promptForSubpremise: flags_from_api.prompt_for_subpremise,
    inferredComponentTypes: flags_from_api.inferred_component_types,
    unconfirmedComponentTypes: flags_from_api.unconfirmed_component_types,
    placeId: flags_from_api.place_id,
    userConfirmKey: flags_from_api.user_confirm_key
  }
}

export function serializeToPatientsUpdateAddress( address : Address ) {
  return {
    street1: address.street,
    ...( address.street2?.length ? {
      street2: address.street2
    } : {}),
    city: address.city,
    state: address.state,
    zip: address.zipCode
  }
}

export function serializeFromGoogleTypeAhead( address_components : GoogleAddressComponent[] ) {
  const streetNumber = address_components.find( component => component.types.includes( `street_number` ) )?.long_name ?? ``
  const route = address_components.find( component => component.types.includes( `route` ) )?.long_name ?? ``
  const street = `${streetNumber} ${route}`
  const street2 = address_components.find( component => component.types.includes( `subpremise` ) )?.long_name ?? ``
  const city = address_components.find( component => component.types.includes( `locality` ) )?.long_name ?? ``
  const state = address_components.find( component => component.types.includes( `administrative_area_level_1` ) )?.short_name ?? ``
  const zipCode = address_components.find( component => component.types.includes( `postal_code` ) )?.long_name ?? ``
  return {
    street,
    street2,
    city,
    state,
    zipCode
  }
}

/* There is a known issue where the Google Autocomplete will include street2 in the main street field. This function will remove street2 from street */
export function removeStreet2FromStreet( street: string, street2 : string ) {
  return street.replace( street2, `` ).trim()
}