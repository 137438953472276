import React from 'react'
import PropTypes from 'prop-types'
import { useAccountMenuIsActive, activeMobileMenuTab } from 'apollo'
import { useLocation } from 'react-router-dom'

import AccountMenuButton from './AccountMenuButton'
import AccountMenuDropdown from './AccountMenuDropdown'


const AccountMenu = ({ onlyShowLogout }) => {
  const [ isActive, setIsActive ] = useAccountMenuIsActive()
  const mediaQueryList = React.useMemo( () => { return window.matchMedia( `(max-width: 767px)` ) }, [] )
  const [ isMobile, setIsMobile ] = React.useState( mediaQueryList.matches )
  const accountMenuRef = React.useRef( null )
  const location = useLocation()
  const isPaymentsRoute = location.pathname.includes( `payments` )

  const toggleMenuIsActive = () => {
    setIsActive( !isActive )

    if ( isMobile && !isActive ) {
      activeMobileMenuTab( `Account` )
    }
  }

  React.useEffect( () => {
    const handleClickOutside = e => {
      if ( !isMobile && !accountMenuRef?.current?.contains( e.target ) ) {
        setIsActive( false )
      }
    }
    const handleMediaQueryChange = event => {
      setIsMobile( event.matches )
    }

    document.addEventListener( `click`, handleClickOutside )
    mediaQueryList.addListener( handleMediaQueryChange )

    return () => {
      document.removeEventListener( `click`, handleClickOutside )
      mediaQueryList.removeListener( handleMediaQueryChange )
    }
  }, [ isMobile ] )

  return (
    <div
      ref={accountMenuRef}
      style={{
        zIndex: 9999
      }}
      className="flex justify-end lg:mr-4 relative"
    >
      { !isPaymentsRoute &&
        <>
          <AccountMenuButton toggleMenuIsActive={toggleMenuIsActive} />
          <AccountMenuDropdown onlyShowLogout={onlyShowLogout} menuIsActive={Boolean( isActive && !isMobile ) || Boolean( isActive && onlyShowLogout )} />
        </>
      }
    </div>
  )
}

AccountMenu.propTypes = {
  onlyShowLogout: PropTypes.bool
}

export default AccountMenu
