import { useEffect, useState } from 'react'
import { SUMMARY_PAGE_PATH, aobPatientOwes0Copy, aobPayLaterCopy, paymentThankYouCopy } from '../constants'
import { icoCheckAob } from 'resources/images'
import { AobLoader } from '../components'
import { useNavigate } from 'react-router-dom'
import { finalizeInvoice } from '../fetchPromises'
import { reportToSentry } from 'utils/reportToSentry'
import LinkTabSection from 'components/linkTabs/LinkTabSection'
import { default as SNLThankYou } from '../../sleepNewLeads/ThankYou/ThankYou'

type ThankYouProps = {
  invoice: {
    total_patient_owes: number;
  };
  aobIsCompleted: boolean;
  paymentCompleted: boolean;
  payLater: boolean;
  notInterested: boolean;
}

function ThankYou({ invoice, aobIsCompleted, paymentCompleted, payLater, notInterested } : ThankYouProps ) {

  const [ loading, setLoading ] = useState<boolean>( false )

  const navigate = useNavigate()

  useEffect( () => {
    if ( ( !aobIsCompleted || ( invoice?.total_patient_owes !== 0 && !paymentCompleted && !payLater ) ) && !notInterested ) {
      return navigate( `${SUMMARY_PAGE_PATH}${window.location.search}`, {
        replace: true
      })
    }

    let mounted = true

    const urlParams = new URLSearchParams( window.location.search )
    const hash = urlParams.get( `sgh` ) ?? urlParams.get( `txt` )

    if ( !notInterested && hash ) {
      setLoading( true )

      finalizeInvoice( hash )
        .then( () => { if ( mounted ) setLoading( false ) })
        .catch( ( error ) => {
          if ( mounted ) setLoading( false )
          reportToSentry( new Error( `AOB 2.0: Error finalizing patient invoice`, {
            cause: error
          }), {
            hash
          })
        })
    }

    return () => { mounted = false }
  }, [] )

  if ( loading ) return <AobLoader withLogo={false} message="Please hold tight while we finalize your invoice..." />

  if ( notInterested ) return (
    <div className="bg-white flex flex-col gap-7 my-28 px-10 items-center">
      <div className="flex flex-col items-center gap-2">
        <p className="text-2xl md:text-3xl font-bold text-center">{`Thank You for Your Submission!`}</p>
        <p className="md:text-lg text-center font-light max-w-xl">{`If you change your mind all you need to do is refresh this page. We hope to see you again soon!`}</p>
      </div>
      <img
        src={icoCheckAob}
        alt="aob_thank_you"
        className="object-contain my-5"
      />
      <LinkTabSection />
    </div>
  )

  // this means the patient has come from SNL so we want to display SNL thank you page
  if ( sessionStorage.getItem( `patientShippingAddressCollectedPriorToPaymentPortalMount` ) === `true` ) {
    return (
      <div className="bg-white my-28 px-10">
        <SNLThankYou />
      </div>
    )
  }

  return (
    <div className="bg-white flex flex-col gap-7 my-28 px-10 items-center">
      <div className="flex flex-col items-center gap-2">
        <p className="text-2xl md:text-3xl font-bold text-center">{`Thank You for Your Submission!`}</p>
        <p className="md:text-lg text-center font-light max-w-xl">{paymentCompleted ? paymentThankYouCopy : payLater ? aobPayLaterCopy : aobPatientOwes0Copy}</p>
      </div>
      <img
        src={icoCheckAob}
        alt="aob_thank_you"
        className="object-contain my-5"
      />
      <LinkTabSection />
    </div>
  )

}

export default ThankYou