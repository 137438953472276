import { sleepLogoHorizontalBlack } from 'resources/images'
import AccountMenu from 'components/accountMenu'

const Logo = () : JSX.Element => {

  return (
    <div className="flex w-full items-center justify-center">
      <div className="w-3/12"></div>
      <div>
        <img
          className="object-contain"
          src={sleepLogoHorizontalBlack}
          alt={`Aeroflow Sleep Logo with Account Icon`}
          height={90}
          width={400}
          loading="lazy"
        />
      </div>
      <div className="w-3/12">
        <AccountMenu onlyShowLogout />
      </div>
    </div>
  )

}

export default Logo
