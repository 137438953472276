import { Tile } from 'components/tile'
import { useNavigate } from 'react-router-dom'
import { ResupplyStorage } from 'apollo/storage'
import { formatDate } from '../utils'
import { myResupplyId } from '../constants'
import { accountNavOptions } from 'apollo'
import { useReactiveVar } from '@apollo/client'
import { RESUPPLY_CLAIMS_ITEMS_PATH } from 'modules/resupply2'
import { useAccountStore } from '../state'
import { isCurrentDateOrPastDate } from '../../../utils/time'
import Error from '../components/Error'

function ProductResupply() : JSX.Element | null {
  const navigate = useNavigate()
  const alreadyPlaced = ResupplyStorage.getOrderPlaced()
  const currentOptions = useReactiveVar( accountNavOptions )
  const { equipment } = useAccountStore()
  const myResupplyNavOption = currentOptions.find( ( option ) => { return option.id === myResupplyId })
  const hasAvailable = equipment?.data?.find( item => { return isCurrentDateOrPastDate( item.eligible ) })

  // add Product Resupply to navigate dropdown
  if ( equipment.data?.length && !myResupplyNavOption && !equipment.error ) accountNavOptions( [{
    label: `Product Resupply`,
    id: myResupplyId
  }, ...currentOptions ] )

  if ( ( !hasAvailable || alreadyPlaced ) ) return null

  if ( equipment?.error ) return ( <Error title="Product Resupply" id={myResupplyId} fillInTheBlank="resupply information" /> )

  return (
    <Tile headerContent="Product Resupply" id={myResupplyId}>
      {
        <div className="mb-5 mt-2">
          <p className="mb-5">{`Available: `}
            <span className="font-bold">
              { formatDate( hasAvailable.eligible ) }
            </span>
          </p>
          <button
            className="btn-primary"
            onClick={() => { navigate( RESUPPLY_CLAIMS_ITEMS_PATH ) }}
          >
            {`Reorder Fresh PAP Supplies`}
          </button>
        </div>
      }
    </Tile>
  )
}

export default ProductResupply