import React, { useState } from 'react'
import { useDoctor, useDoctorNpiStore } from '../hooks/state'
import DoctorInfo from '../types/DoctorInfo'
import CurrentDoctor from './CurrentDoctor'
import DoctorSearch from './DoctorSearch'
import { Modal } from 'components/modal'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'

type DoctorDisplayProps = {
  textContent: string;
  onSuccess?: ( _doctor: DoctorInfo ) => void;
  forceUpdate: boolean;
  headerStyle?: string;
  containerStyle?: string;
  isMyAccount?: boolean;
}

function DoctorDisplay({ isMyAccount = false, textContent, onSuccess, forceUpdate, headerStyle, containerStyle } : DoctorDisplayProps ) : JSX.Element {
  const [ doctor ] = useDoctor()

  const [ searchActive, setSearchActive ] = useState<boolean>( !doctor || ( !doctor.firstName && !doctor.lastName ) || !doctor.phone || forceUpdate )
  const { showDoctorDisplayModal, setShowDoctorDisplayModal } = useDoctorNpiStore()

  const modalBodyText = `We were unable to verify your doctor’s information. Please try re-entering your doctor’s information or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if this issue persists.`

  if ( !searchActive ) return (
    <>
      <CurrentDoctor
        setSearchActive={setSearchActive}
        headerStyle={headerStyle}
        containerStyle={containerStyle}
        isMyAccount={isMyAccount}
      />
      {showDoctorDisplayModal &&
      <Modal
        isOpen
        onClose={() => { setShowDoctorDisplayModal( !showDoctorDisplayModal ) }}
      >
        <p className="pb-4">{modalBodyText}</p>
      </Modal>
      }
    </>
  )

  return (
    <DoctorSearch
      headerStyle={headerStyle}
      containerStyle={containerStyle}
      setSearchActive={setSearchActive}
      textContent={textContent}
      onSuccess={onSuccess}
      forceUpdate={forceUpdate}
      isMyAccount={isMyAccount}
    />
  )
}

export default DoctorDisplay