import React from 'react'
import { PhoneLink } from 'components/ALinks'
import { PHONE } from 'components/footerBar/constants'

function SchedulingError({ header, message } : { header: string, message: string }) : JSX.Element {
  return (
    <div className="max-w-5xl my-36 mx-auto px-5 bg-white">
      <h5 className="text-error text-4xl text-center mb-16">{header}</h5>
      <p className="text-center text-2xl text-error">
        {message}
        { ` Please reach out to support at `}
        <PhoneLink displayNumber={PHONE} />
        {` for assistance.`}
      </p>
    </div>
  )
}

export default SchedulingError