import { useEffect, useState } from 'react'
import PageNavigator from 'components/pageNavigator'
import { Accessories, Machine, Mask, SnlThankYou, StepLauncher } from './index'
import { fetchSubmitNotInterestedReason, submitSleepNewLead } from './fetch'
import DoctorAddressCollection from './DoctorAddressCollection/DoctorAddressCollection'
import LoadingSpinner from '../../components/LoadingSpinner'
import { blockLeadsDisplay, SNLStorage } from 'apollo'
import { reportToSentry } from 'utils/reportToSentry'
import {
  initialSNLFormValuesState,
  machineEligibleProgress,
  machineIneligibleProgress,
  pageNames,
  progressData,
  SNL_MASK_FITTING_PATH,
  SNL_THANK_YOU_PATH
} from './constants'
import { getStepIndexes } from './StepsLauncher/constants'
import { useNavigate } from 'react-router-dom'
import ProgressionContainer from './Progression/ProgressContainer'
import { useLazyQuery } from '@apollo/client'
import MaskFitting from './MaskFitting/MaskFitting'
import { cmsBlocksQuery } from 'graphql/queries/cmsBlocks'
import MachineShortage from './MachineShortage/MachineShortage'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'
import useCheckSAPRepeat from './hooks/useCheckSAPRepeat'
import { PhoneLink } from 'components/ALinks'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useQualifyFormStore } from 'modules/qualifyForm/state'
import { useTokenStore } from 'stores/tokenStore'
import { useAddressStore } from 'components/addressBlock'
import { getAuthHeader } from 'utils/auth/helpers'
import { trackAnalyticEvent } from 'utils/analytic'

// @NOTE still getting the first page of SNL to display when we block a user for a few ms
// need to likely have a finished state for the patient routing check to prevent this

const SleepNewLeadsController = () => {

  const [ loading, setLoading ] = useState( false )
  const [ highestStep, setHighestStep ] = useState( 0 )
  const [ activeStep, setActiveStep ] = useState( 0 )
  const [ displaySubmitError, setDisplaySubmitError ] = useState( false )
  const [ displayProgress, setDisplayProgress ] = useState( true )
  const [ interested, setInterested ] = useState( true )
  const [ machineEligible, setMachineEligible ] = useState( true )
  const [ progressStatus, setProgressStatus ] = useState( progressData )

  const [ formValues, setFormValues ] = useState({
    ...initialSNLFormValuesState
  })

  const navigate = useNavigate()
  const { customerToken } = useTokenStore()
  const { processId: snlProcessId, formCode: snlFormCode } = useQualifyFormStore( state => state )
  const { addressPatientConfirmed, addressWebConfirmed } = useAddressStore()
  const { repeatMessage, loading: patientRoutingLoading } = useCheckSAPRepeat()
  // useMedchatWidget( `BiebIPQTk0W_5dbXKQwGgg` )

  const pageHasLoaded = !loading && !patientRoutingLoading

  const [ getMaskFittingContent, maskFittingContent ] = useLazyQuery( cmsBlocksQuery, {
    errorPolicy: `all`,
    variables: {
      identifiers: `snl-mask-fitting-widget`
    }
  })

  /* Initialize Cache State */
  useEffect( () => {

    let isMounted = true
    const unmount = () => { return isMounted = false }

    blockLeadsDisplay( false )

    const { formValues, activeStep, highestStep } = SNLStorage.getSession()

    if ( formValues && !customerToken ) {

      SNLStorage.clearSession()

      return unmount
    }
    // If Cache is present, set state to cached values
    if ( formValues && isMounted ) {
      if ( formValues.satisfiedWithCurrentMask === `false` ) getMaskFittingContent()
      setFormValues( formValues )
      setActiveStep( activeStep || 1 )
      setHighestStep( highestStep || 1 )
    }

    return unmount
  }, [ customerToken ] )

  /* Update Cache State */
  useEffect( () => {
    if ( Object.values( formValues ).length && window.location.pathname !== SNL_THANK_YOU_PATH ) {
      SNLStorage.setSession( formValues, activeStep, highestStep )
    }
  }, [ formValues, activeStep, highestStep ] )

  useEffect( () => {
    if ( machineEligible ) {
      setProgressStatus( Object.assign({}, progressStatus, {
        machine: machineEligibleProgress
      }) )
    } else {
      setProgressStatus( Object.assign({}, progressStatus, {
        machine: machineIneligibleProgress
      }) )
    }
  }, [ machineEligible ] )

  const onHandleChange = newValues => {
    const newFormVals = Object.assign({}, formValues, newValues )
    setFormValues(
      newFormVals
    )
  }

  const handleSubmitNewLeadNoMachine = async () => {
    setLoading( true )
    const submitData = {
      lastOrderCpapMachine: formValues.lastOrderCpapMachine
    }
    const headers = {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    }

    await submitSleepNewLead( submitData, headers, snlFormCode, snlProcessId ).then( ( data ) => {

      if ( !data || data?.message !== `Submitted` || ( data?.status !== 201 && data?.status !== 200 ) ) {

        // for these leads we simply get back data.data.success && data.meta.status === `OK`
        // so here we only want to fire to sentry if the above are true and the nested if below is true

        if ( !data?.data?.success || data?.meta?.status !== `OK` ){
          // @TODO if there is an issue here we need to not progress the user to the thank you page
          // need to display the error and allow them to try again
          reportToSentry( new Error( `Sleep New Leads w/ No Machine Submission Failure` ), {
            payload: submitData
          })
        }

      }
      setLoading( false )
      blockLeadsDisplay( true )

      return navigate( SNL_THANK_YOU_PATH )

    })
      .catch( ( error ) => {
        reportToSentry( new Error( `Sleep New Leads w/ No Machine Submission Failure`, {
          cause: error
        }), {
          payload: submitData
        })
        blockLeadsDisplay( true )
        setLoading( false )
        return navigate( SNL_THANK_YOU_PATH )
      })
  }

  const handleSubmitNewLead = () => {
    setLoading( true )
    const submitData = {
      step_complete: 3,
      ...( formValues.cpapMachineDmeID ? {
        cpap_machine_dmeid: formValues.cpapMachineDmeID,
        cpap_machine_brand: formValues.cpapMachineBrand,
        cpap_machine_model: formValues.cpapMachineModel
      } : {}),
      last_order_cpapsupplies: formValues.lastOrderCpapSupplies,
      last_order_cpapmachine: formValues.lastOrderCpapMachine,
      cpap_mask_dmeid: formValues.cpapMaskDmeID,
      cpap_mask_brand: formValues.cpapMaskBrand,
      cpap_mask_model: formValues.cpapMaskModel,
      cpap_mask_size: formValues.cpapMaskSize,
      tubing_dmeid: formValues.tubingDmeID,
      last_order_cpap_mask: formValues.lastOrderCpapMask,
      last_order_cpap_mask_headgear: formValues.lastOrderCpapMaskHeadgear,
      tubing_type: formValues.tubingType,
      satisfied_with_current_mask: formValues.satisfiedWithCurrentMask,
      intent_order_new_equipment: formValues.intentOrderNewEquipment,
      web_confirmed: addressWebConfirmed,
      patient_confirmed: addressPatientConfirmed
    }

    const headers = {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    }

    submitSleepNewLead( submitData, headers, snlFormCode, snlProcessId ).then( ({ data, meta }) => {
      setLoading( false )
      if ( data?.success && meta?.status === `OK` ) {
        trackAnalyticEvent( `complete_registration` )
        blockLeadsDisplay( true )

        return navigate( SNL_MASK_FITTING_PATH )
      }

      setDisplaySubmitError( true )

    })
      .catch( ({status, message}) => {
        setLoading( false )
        reportToSentry( new Error( `Sleep New Leads Submit failed with ${message}`, {
          payload: submitData,
          error: message,
          status: status
        }) )

        return setDisplaySubmitError( true )
      })
  }

  const handleOnSubmit = () => {
    setDisplaySubmitError( false )
    handleSubmitNewLead()
  }

  const handlePageNavigatorChange = ( newPageIndex ) => {
    /* This function handles the updating of highestStep and activeStep */
    const stepLauncherIndexes = getStepIndexes()
    const currentLauncherIndex = stepLauncherIndexes.findIndex( s => { return s === newPageIndex + 1 })
    const nonStepLauncherStep = currentLauncherIndex === -1
    if ( activeStep === 0 ) setActiveStep( stepLauncherIndexes[0] )
    if ( newPageIndex >= highestStep && !nonStepLauncherStep ) {
      // If currentPage is higher than our highest and we are on the pageLauncher step
      setHighestStep( stepLauncherIndexes[currentLauncherIndex] )
      if ( currentLauncherIndex !== stepLauncherIndexes.length ) {
        // If last index - we keep same active step, else we update it
        setActiveStep( stepLauncherIndexes[currentLauncherIndex] )
      }
    }
  }

  const handleNoLongerInterested = ( reason ) => {
    setLoading( true )
    setInterested( false )
    // submit not interested reason in the background
    fetchSubmitNotInterestedReason( reason ).then( () => {
      setLoading( false )
      return navigate( SNL_THANK_YOU_PATH )
    })
      .catch( error => {
        setLoading( false )
        reportToSentry( new Error( `SNL Machine Shortage: Not Interested Submission Failure`, {
          cause: error
        }), {
          reason
        })
        return navigate( SNL_THANK_YOU_PATH )
      })

  }

  if ( !pageHasLoaded ) {
    return <div className="my-16"><LoadingSpinner /></div>
  }

  if ( repeatMessage ){
    return (
      <div className="my-16 max-w-lg mx-auto text-center px-4">
        <p className="md:text-lg">
          {repeatMessage}
          <PhoneLink displayNumber={AF_SUPPORT_PHONE_NUMBER} />
        </p>
      </div>
    )
  }

  if ( !repeatMessage ) {
    return (
      <>
        {displayProgress && <ProgressionContainer progressData={progressStatus} formValues={formValues} activeStep={activeStep} /> }
        <PageNavigator
          pageNames={pageNames}
          childSharedProps={
            {
              onHandleChange,
              formValues,
              handleNoLongerInterested,
              setDisplayProgress
            }
          }
          onChange={handlePageNavigatorChange}
          pageClassName="sm:w-10/12 md:w-1/2 mx-auto mb-32 relative"
        >
          <StepLauncher highestStep={highestStep} activeStep={activeStep} />
          <Machine setMachineEligible={setMachineEligible} submitLeadNoMachine={handleSubmitNewLeadNoMachine} />
          <MachineShortage machineEligible={machineEligible} />
          <StepLauncher highestStep={highestStep} activeStep={activeStep} />
          <Mask getMaskFittingContent={getMaskFittingContent} />
          <StepLauncher highestStep={highestStep} activeStep={activeStep} />
          <Accessories />
          <StepLauncher highestStep={highestStep} activeStep={activeStep} />
          <DoctorAddressCollection onSubmit={handleOnSubmit} displaySubmitError={displaySubmitError} />
          <MaskFitting maskFittingContent={maskFittingContent} />
          <SnlThankYou interested={interested} />
        </PageNavigator>
      </>
    )
  }
}

export default SleepNewLeadsController