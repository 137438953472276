import React from 'react'
import PropTypes from 'prop-types'

function InsurerNames({invoice}) {
  const payerNameStyling = `text-lg text-center font-light mx-auto`

  return (
    <div className="mb-6">
      {
        invoice?.payer1_name?.length > 0 &&
        <p className={`${payerNameStyling} mb-4`}>{`Insurer #1: ${invoice.payer1_name}`}</p>
      }
      {
        invoice?.payer2_name?.length > 0 &&
        <p className={`${payerNameStyling} mb-4`}>{`Insurer #2: ${invoice.payer2_name}`}</p>
      }
      {
        invoice?.payer3_name?.length > 0 &&
        <p className={payerNameStyling}>{`Insurer #3: ${invoice.payer3_name}`}</p>
      }
    </div>
  )
}

InsurerNames.propTypes = {
  invoice: PropTypes.shape({
    payer1_name: PropTypes.string,
    payer2_name: PropTypes.string,
    payer3_name: PropTypes.string
  })
}

export default InsurerNames