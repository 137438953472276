/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { reportToSentry, getStringFromUnknownError} from 'utils/reportToSentry'
import { redirect, useSearchParams } from 'react-router-dom'
import { getAuthHeader } from 'utils/auth/helpers'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'

export default function useFetch<YourResponseType>(
  url : string,
  requestInit : RequestInit = {},
  useFetchOptions? : UseFetchOptions
) {
  const [ response, setResponse ] = useState<YourResponseType | null>( null )
  const [ error, setError ] = useState<unknown>( null )
  const [ isLoading, setIsLoading ] = useState( false )

  const searchParams = useSearchParams()[0]

  useEffect( () => {
    // If the request method is GET, then we want to do the fetch immediately
    if ( requestInit.method === `GET` && !useFetchOptions?.skipOnMount ) doFetch()
  }, [] )

  const doFetch = async ( body?: any ) : Promise<YourResponseType | void> => {
    setIsLoading( true )

    try {
      const authorization = getAuthHeader()
      if ( !authorization ) throw new Error( `User not authorized - no Magento token, txt, or sgh found` ) // We may want to have the option to useFetch while not logged in, in which case we would not throw this error

      const res = await fetch( url, { // Note: we may want to be aware of mutating issues from passing in options, where the passed-in options shares a property with the default options declared here
        body: body ? JSON.stringify( body ) : requestInit.body,
        headers: {
          ...addMagentoEnvAuthHeaders(),
          'Authorization': authorization
        },
        ...requestInit
      })
      const data = await res?.json() ?? `No Response Returned`

      useFetchOptions?.onSuccess && useFetchOptions.onSuccess( data )
      setResponse( data )
      if ( useFetchOptions?.successRedirectTo?.length ) redirect( useFetchOptions.successRedirectTo )

      return data
    } catch ( error ) {
      if ( useFetchOptions?.onErrorOverride ) return useFetchOptions.onErrorOverride( error )
      const errorMessage = getStringFromUnknownError( error )
      !useFetchOptions?.skipSentry && reportToSentry( errorMessage, {
        additionalErrorOptions: useFetchOptions?.additionalErrorOptions,
        errorReturned: JSON.stringify( error ),
        sghOrTxt: searchParams.get( `sgh` ) ?? searchParams.get( `txt` )
      })
      setError( error )
    } finally {
      setIsLoading( false )
    }
  }

  return {
    data : response,
    error,
    isLoading,
    lazyFetch: doFetch
  }
}


export type ErrorOptions = {
  onErrorOverride? : ( error : any ) => void; // This allows for an override of the default behavior
  skipSentry? : boolean; // This allows for skipping Sentry reporting
  additionalErrorOptions? : any; // This allows for additional error options to be passed in to Sentry reporting
}

export type SuccessOptions = {
  onSuccess? : ( _data : any ) => void; // This allows for an override of the default behavior
  successRedirectTo? : string; // This allows for a redirect on success
  skipOnMount? : boolean; // This allows for skipping the fetch on mount if the request method is GET
}

export type UseFetchOptions = ErrorOptions & SuccessOptions