import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tailwindColors from 'config/tailwindColors'
import { TrailDrop } from 'spring'


const StyledUpArrow = styled.div`
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${tailwindColors.graphite};
`

const AccountMenuDropdownWrapper = ( props ) => {
  return (
    <>
      <StyledUpArrow className="absolute md:top-11 md:right-3 lg:top-10 xl:right-5" />
      <TrailDrop className="absolute md:top-12 md:right-0 lg:top-11 min-w-min py-4 px-8 rounded-2xl bg-white border-solid border-2 border-graphite" open={props.active}>
        <div className="whitespace-nowrap flex flex-col h-16 justify-evenly items-start">
          {props.children}
        </div>
      </TrailDrop>
    </>
  )
}
AccountMenuDropdownWrapper.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node
}

export default AccountMenuDropdownWrapper
