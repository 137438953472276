import {
  socialFacebokWhiteIco,
  socialInstagramWhiteIco,
  socialLinkedinWhiteIco,
  socialTikTokWhiteIco,
  socialYoutubeWhiteIco
} from 'resources/images'

export const QUICK_LINKS = [
  {
    display: `Qualify Through Insurance`,
    href: `/qualify-through-insurance`
  },
  {
    display: `My Account`,
    href: `/account`
  }
]
export const SUPPORT_LINKS = [
  {
    display: `Terms & Conditions`,
    href: `https://shop.aeroflowsleep.com/terms-and-conditions`
  },
  {
    display: `Returns Policy`,
    href: `https://shop.aeroflowsleep.com/returns-policy`
  },
  {
    display: `Warranty Information`,
    href: `https://shop.aeroflowsleep.com/warranty-information`
  },
  {
    display: `Shipping Policy`,
    href: `https://shop.aeroflowsleep.com/shipping`
  },
  {
    display: `Privacy Policy`,
    href: `https://shop.aeroflowsleep.com/privacy-policy`
  }
]

export const ABOUT_US_LINKS = [
  {
    display: `About Us`,
    href: `https://shop.aeroflowsleep.com/about-aeroflow-sleep`
  },
  {
    display: `Editorial Policy`,
    href: `https://shop.aeroflowsleep.com/aeroflow-sleep-editorial-policy`
  },
  {
    display: `Our Sales Team`,
    href: `/meet-our-sales-team`
  },
  {
    display: `FAQ`,
    href: `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/frequently-asked-questions`
  },
  {
    display: `Careers`,
    href: `https://aeroflowinc.com/employment-opportunities/`
  }
]

export const ADDRESS = `65 Beale Road Arden, NC 28704`
export const PHONE = `1-800-480-5491`
export const FAX = `1-800-249-1513`
export const HOURS = `9am - 5pm EST M-F`
export const PHONE_HREF = `tel:+18665606674`

export const SOCIAL_MEDIA = [
  {
    title: `Instagram`,
    imgSrc: socialInstagramWhiteIco,
    href: `https://www.instagram.com/aeroflow_sleep/`
  },
  {
    title: `LinkedIn`,
    imgSrc: socialLinkedinWhiteIco,
    href: `https://www.linkedin.com/company/aeroflow-sleep`
  },
  {
    title: `Facebook`,
    imgSrc: socialFacebokWhiteIco,
    href: `https://www.facebook.com/AeroflowSleep/`
  },
  {
    title: `YouTube`,
    imgSrc: socialYoutubeWhiteIco,
    href: `https://www.youtube.com/channel/UC_MIMtz7igh6RkEdHwhR6xg`
  },
  {
    title: `TikTok`,
    imgSrc: socialTikTokWhiteIco,
    href: `https://www.tiktok.com/@aeroflow_sleep`
  }
]