import React, { useState } from 'react'
import PropTypes from 'prop-types'
import OrderItem from './OrderItem'
import { Button } from 'components/Button'

const OrderItemsContainer = ({items, hideExpandCollapse = false }) => {
  const [ isOpen, setIsOpen ] = useState( hideExpandCollapse )

  return (
    <div className="w-80 mx-auto">
      {isOpen && items.map( item => { return <OrderItem key={item.item_name} {...item} /> })}
      {
        !hideExpandCollapse &&
        <Button onClick={() => { return setIsOpen( !isOpen ) }}>{`${isOpen ? `Hide` : `Show`} Order Details`}</Button>
      }
    </div>
  )
}


OrderItemsContainer.propTypes = {
  items: PropTypes.array.isRequired,
  hideExpandCollapse: PropTypes.bool
}

export default OrderItemsContainer