import React from 'react'
import { cmsBlocksQuery } from 'graphql/queries/cmsBlocks'
import { useQuery } from '@apollo/client'
import LoadingSpinner from 'components/LoadingSpinner'
import ReactHtmlParser from 'react-html-parser'

function NoLongerInterestedThankYou() {

  const { data, loading, error } = useQuery( cmsBlocksQuery, {
    errorPolicy: `all`,
    variables: {
      identifiers: `snl-no-longer-interested-widget`
    }
  })

  const transformWidget = ( node ) => {
    if ( node.type === `tag` ) node.attribs.style = ``
    if ( node?.name === `h3` ) node.attribs.class = `text-xl md:text-3xl my-5`
    else if ( node?.name === `h4` ) node.attribs.class = `text-lg md:text-2xl my-5`
    else if ( node?.name === `p` ) node.attribs.class = `inline`
    else if ( node?.name === `a` ) {
      node.attribs.class = `underline text-deepSleepBlue decoration-deepSleepBlue inline`
      node.attribs.href = `mailto: AeroflowPAP.Supplies@aeroflowinc.com`
    }
  }

  if ( loading ) return <div className="my-16"><LoadingSpinner /></div>

  if ( data?.cmsBlocks?.items?.length && !error ) {
    return (
      <div className="text-center max-w-2xl m-auto my-20">
        {
          ReactHtmlParser( data.cmsBlocks.items[0].content, {
            transform: transformWidget
          })
        }
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-5 items-center text-center max-w-2xl mx-auto my-20">
      <h3 className="text-xl md:text-3xl">{`No Longer Interested`}</h3>
      <div>
        <p className="inline">{`We are sorry that we couldn't fulfill your needs for a PAP Machine at this time. If you change your mind and would like to move forward with a supply order in the future, simple email us at `}</p>
        <span className="inline">
          <a href="mailto: AeroflowPAP.Supplies@aeroflowinc.com" className="a inline">
            {`AeroflowPAP.Supplies@aeroflowinc.com`}
          </a>
        </span>
        <p className="inline">{` with your name, email, and DOB or come back and fill out our online form again.`}</p>
      </div>
      <h3 className="text-lg md:text-2xl">{`Thank you!`}</h3>
    </div>
  )
}

export default NoLongerInterestedThankYou