import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { customerAddressesQuery } from 'graphql/queries'
import { reportToSentry } from 'utils/reportToSentry'
import { useDoctorInput } from './state'
import { getInternalZip } from '../utils/utils'
import { getToken } from 'stores/tokenStore'
import { useAddressStore } from 'components/addressBlock'

function useRetrieveZip(): boolean {
  const [ loading, setLoading ] = useState<boolean>( Boolean( getToken() ) )
  const [ doctorInput, setDoctorInput ] = useDoctorInput()
  const { address } = useAddressStore()

  const { data } = useQuery( customerAddressesQuery, {
    fetchPolicy: `network-only`,
    skip: !getToken(),
    onError: error => {
      reportToSentry( new Error( `customerAddressesQuery Error -> AOBPaymentsPortal`, {
        cause: error
      }),
      {
        message: error?.message
      }
      )
      setLoading( false )
    }
  })

  useEffect( () => {
    if ( address?.zipCode ) {
      setDoctorInput(
        Object.assign({}, doctorInput, {
          zipCode: address.zipCode
        })
      )
    }
    // @NOTE I can confirm we do need to keep the below code in place as of 12-14-2023 for dr block in sleep new leads
    // in that case the patient address is not yet in magento so using the deprecated login needs to happen
    // it allows us to pick up the zip code they entered in the qualify form if they are coming direct from qualify form.
    if (
      data &&
      data?.customer?.date_of_birth &&
      data?.customer?.email &&
      !address?.zipCode
    ) {
      getInternalZip( data.customer.email, data.customer.date_of_birth )
        .then( zipData => {
          if ( zipData?.meta?.status === `OK` ){
            setDoctorInput(
              Object.assign({}, doctorInput, {
                zipCode: zipData.data.zip_code
              })
            )
          } else {
            reportToSentry(
              new Error(
                `Doctor Block: Error getting patient zip from internal`
              ),
              {
                customer: JSON.stringify( data.customer ),
                response: JSON.stringify( zipData )
              }
            )
          }
          setLoading( false )
        })
        .catch( error => {
          setLoading( false )
          reportToSentry(
            new Error(
              `Doctor Block: Error getting patient zip from internal`,
              {
                cause: error
              }
            ),
            {
              customer: JSON.stringify( data.customer )
            }
          )
        })
    } else if ( data ) setLoading( false )
  }, [ data ] )

  return loading
}

export default useRetrieveZip
