import React, { useState } from 'react'
import { formatMoney, splitMoney } from 'utils/money'
import { PaymentMethod } from 'components/payments'
import { IPaymentMethod } from 'components/payments/types'
import { emptyCardMethod } from 'modules/aobPayments/constants'
import HeadingWithBackButton from '../backButton/Heading'
import { useGetAobSegmentedCopy } from 'modules/aobPayments/hooks/useGetAobSegmentedCopy'

export type PaymentMethodType = {
    cardNumber: string;
    expMonth: string;
    expYear: string;
    ccv: string;
    cardHolderFirstName: string;
    cardHolderLastName: string;
    city: string;
    state: string;
    zip: string;
    address: string;
}

type UpfrontPaymentBlockProps = {
  splitSinglePayment: boolean | null;
  setSplitSinglePayment: React.Dispatch<React.SetStateAction<boolean | null>>;
  invoice: {
    total_patient_owes: number;
  };
  selected?: string | null;
  setSinglePaymentMethod: React.Dispatch<React.SetStateAction<IPaymentMethod>>;
  setDisableSubmitPayment: React.Dispatch<React.SetStateAction<boolean>>;
  singlePaymentMethod: IPaymentMethod;
}

function UpfrontPaymentBlock({ splitSinglePayment, setSplitSinglePayment, invoice, singlePaymentMethod, setSinglePaymentMethod, setDisableSubmitPayment }: UpfrontPaymentBlockProps ) : JSX.Element {

  const [ displayPaymentMethods, setDisplayPaymentMethods ] = useState( false )
  const [ payMethod, setPayMethod ] = useState<IPaymentMethod>( singlePaymentMethod )

  const segmentedUpfrontPaymentCopy = useGetAobSegmentedCopy()

  const handleEdit = () => {
    setDisableSubmitPayment( true )
  }

  const handleSubmit = () => {
    setDisableSubmitPayment( false )
    setSinglePaymentMethod( payMethod )
  }

  const handleSplitPaymentsSelection = ( split: boolean ) => {
    setSplitSinglePayment( split )
    setDisplayPaymentMethods( true )
  }

  const upfront = invoice.total_patient_owes?.toFixed( 2 )

  if ( !displayPaymentMethods ) {
    return (
      <div className="max-w-2xl mx-auto">
        <div className="flex flex-col items-center w-full max-w-md mx-auto my-5 gap-5">
          <p className="text-lg md:text-xl font-bold text-center">{`Upfront Payment Method`}</p>
          {segmentedUpfrontPaymentCopy?.UpfrontPaymentDetails &&
            <segmentedUpfrontPaymentCopy.UpfrontPaymentDetails />
          }
          <p className="md:text-lg font-light text-center">{`Choose One`}</p>
          <button
            className="payment-btn mb-10"
            onClick={() => { handleSplitPaymentsSelection( false ) }}
          >
            <p className="pt-2 md:text-lg">{`One time payment of`}</p>
            <p className="pb-2 md:text-lg">{`$${upfront}`}</p>
          </button>
          <p>{`OR`}</p>
          <button
            className="payment-btn mt-7"
            onClick={() => { handleSplitPaymentsSelection( true ) }}
          >
            <p className="pt-2 md:text-lg">{`3 monthly payments of`}</p>
            <p className="pb-2 md:text-lg">{`${formatMoney( splitMoney( invoice.total_patient_owes, 3 ) )}`}</p>
          </button>
          <p className="-mt-3">{`($${upfront} over 3 months)`}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center my-5">

      <HeadingWithBackButton
        headingText={`${splitSinglePayment ? `Split` : `Full` } Upfront Payment`}
        headingClassName="text-lg md:text-xl font-bold text-center mb-4 px-10 md:px-5"
        onBackClick={() => setDisplayPaymentMethods( false )}
        backText="Change"
      />

      <PaymentMethod
        payMethod={payMethod}
        setPayMethod={setPayMethod}
        handleRemove={() => {
          setPayMethod( Object.assign({}, emptyCardMethod ) )
          setSinglePaymentMethod( Object.assign({}, emptyCardMethod ) )
        }}
        onSave={handleSubmit}
        onEdit={handleEdit}
      />
    </div>
  )
}

export default UpfrontPaymentBlock