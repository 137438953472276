import { getAuthHeader } from "utils/auth/helpers"
import { addMagentoEnvAuthHeaders } from "utils/magentoEnvAuthHeaders"

export const fetchSnlData = async ( route, signal ) => {
  const response = await fetch( process.env.REACT_APP_AWS_URL + route, {
    signal
  })

  if ( response?.ok ) return await response.json()
  throw new Error( `Response Failed with ${response?.status}` )
}

export const submitSleepNewLead = async ( submitData, headers, formCode, processId ) => {

  const passHeaders = !formCode || !processId
  const ENDPOINT = `${process.env.REACT_APP_SNL_URL}/new-leads/order`
  const url = !passHeaders ? `${ENDPOINT}/${formCode}/${processId}` : ENDPOINT

  const response = await fetch( url, {
    method: `POST`,
    body: JSON.stringify( submitData ),
    ...(
      passHeaders && {
        headers
      }
    )
  })

  return response.json()

}

export const getHashFromLeadInfo = async ( formCode, processId ) => {

  if ( !processId || !formCode ) {
    const response = await fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/options`, {
      headers: {
        ...addMagentoEnvAuthHeaders(),
        "Authorization": getAuthHeader()
      }
    })

    return await response.json()
  }

  if ( processId && formCode ){
    const response = await fetch( `${process.env.REACT_APP_SNL_URL}/new-leads/options/${formCode}/${processId}` )

    return response.json()
  }
}

export const fetchSubmitNotInterestedReason = async ( reason ) => {
  const response = await fetch( `${process.env.REACT_APP_AWS_URL}/not-interested`, {
    mode: `cors`,
    method: `POST`,
    headers: {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    },
    body: JSON.stringify({
      notInterestedReason: reason
    })
  })

  return await response.json()
}

export const fetchPatientRouting = async ( signal ) => {
  const response = await fetch( `${process.env.REACT_APP_SNL_URL}/patient-routing`, {
    headers: {
      ...addMagentoEnvAuthHeaders(),
      "Authorization": getAuthHeader()
    },
    signal
  })

  return await response.json()
}