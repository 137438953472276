import React from 'react'
import { CreateAccountForm } from 'components/auth'
import LoadingSpinner from 'components/LoadingSpinner'
import {
  patientEmailFromAppServer,
  patientIdFromAppServer,
  patientPhoneFromAppServer,
  preLoginLocationVar
} from 'apollo'
import { Navigate } from 'react-router-dom'
import { LOGIN_M2_PATH } from 'routes'
import { useReactiveVar } from '@apollo/client'
import { Validators } from '../../components/forms/components'

const ResupplyGateway = ({ ...pageProps }) => {

  const [ loading, setLoading ] = React.useState( true )
  const [ sleepHash, setSleepHash ] = React.useState( `` )
  const [ patientNeedsMagentoAccount, setPatientNeedsMagentoAccount ] = React.useState( false )
  const [ eventHash, setEventHash ] = React.useState( `` )

  const from = useReactiveVar( preLoginLocationVar )
  // to help bridge the delay between m2 account create and internal stamping the pt row on the m2 customer we provide a fallback here
  // the fallback is used on the resupply/claims component to fetch supply options if the m2 customer query doesn't yet have a patient_id
  React.useEffect( () => {
    const isFromSearch = from?.search

    const fromPath = isFromSearch ? from?.pathname : ``

    const getSgh = ( ) => {
      const searchQs = fromPath?.search

      const urlParsed = searchQs ? new URLSearchParams( searchQs ) : null

      if ( urlParsed ) {
        const eventHash = urlParsed.get( `sgh` ) || urlParsed.get( `txt` )
        if ( eventHash ) return eventHash

        return null
      }

      return null

    }

    const aobEventHash = ( fromPath && ( fromPath === `/payments/pay` ) ) ? getSgh() : null

    if ( aobEventHash ){
      setEventHash( aobEventHash )
    }

  }, [] )

  React.useEffect( () => {
    if ( pageProps.patientData?.patient_id ){
      patientIdFromAppServer( pageProps.patientData.patient_id )
    }
    if ( pageProps.patientData?.phone ){
      patientPhoneFromAppServer( pageProps.patientData.phone )
    }
    if ( pageProps.patientData?.email_address ){
      patientEmailFromAppServer( pageProps.patientData.email_address )
    }
  }, [ pageProps.patientData ] )

  React.useEffect( () => {

    let mounted = true
    const cleanUpFunction = () => { mounted = false }

    if ( loading ) {

      // @NOTE for a user to get to this page an mgh query param is created on a login sequence prior to this view
      // The user somehow found their way here without a valid sleep hash (UUID), send them to login
      if ( ( !pageProps.patientData?.unique_hash || !Validators.UUID( pageProps.patientData?.unique_hash ) ) && !eventHash ) window.location.href = `https://aeroflowsleep.com/login`

      if ( mounted ) {
        setSleepHash( pageProps.patientData?.unique_hash )
      }

      if ( !pageProps.patientData?.magento_customer_id ) setPatientNeedsMagentoAccount( true )
    }
    setLoading( false )

    return cleanUpFunction
  }, [ ] )

  if ( loading ) {
    return (
      <div className="my-8">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <>
      <div className="mt-8 md:mx-16 sm:border-b sm:border-solid sm:border-graphite md:border-0">
        <h3 className="sm:text-2xl md:text-4xl font-bold mb-4">{patientNeedsMagentoAccount ? `Create Account`: `Customer Login`}</h3>

        {!patientNeedsMagentoAccount && (
          <div className="mt-4 mb-2">
            <p className="sm:text-lg font-light">{`Registered Customers`}</p>
          </div>
        )}
      </div>

      <div>
        {patientNeedsMagentoAccount
          ? (
            <>
              {( !pageProps?.patientData || !pageProps?.patientData?.email_address ) &&
              <>
                <p>{`We see that we do not have an email address on file for you. Registering with Aeroflow Sleep’s new website with an email address improves the care and service we provide. The new Aeroflow Sleep website allows you to place supply orders, review previous orders, and track your CPAP supplies as they ship—providing you with insight and more information about your account.`}</p>
                <p>
                  {`Please set up your account with an email and password below.`}
                </p>
                <p>
                  {`If you do not have an email or you prefer not to provide one, please call us at 1-866-616-1687`}
                </p>
              </>
              }
              {( pageProps?.patientData && pageProps?.patientData?.email_address ) &&
                <p>{`Let’s set up your new Aeroflow Sleep account. Please set a password below. Moving forward, this will be your password to log in to your account and place your supply orders.`}</p>
              }
              <CreateAccountForm
                firstName={pageProps.patientData?.first_name}
                lastName={pageProps.patientData?.last_name}
                email={pageProps.patientData?.email_address}
                dateOfBirth={pageProps.patientData?.date_of_birth}
                sleepHash={sleepHash}
              />
            </>
          )
          : <Navigate to={`${LOGIN_M2_PATH}`} replace />
        }
      </div>
    </>
  )
}

export default ResupplyGateway
