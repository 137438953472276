import React from 'react'
import { Tile } from 'components/tile'
import StepQueueRenderer from '../StepQueueRenderer'
import { EligibleForNewMachineStep, InsuranceInformationStep, PrescriptionNeededStep, InsurancePriorAuthStep, ActionNeededStep, OrderShippedStep } from './steps'
import LoadingSpinner from 'components/LoadingSpinner'

type ReplacementCpapTrackerType = {
    eligible_flag: boolean;
    information_and_insurance_verified: boolean;
    action_needed: boolean;
    documentation_on_file: boolean;
    pa_needed: boolean;
    pa_on_file: boolean;
    order_shipped: boolean;
    tracking_number?: string;
    tracking_link?: string;
    ship_date?: string;
}

type ReplacementCpapTrackerProps = {
    replacementCpapTrackerDetails: ReplacementCpapTrackerType
}

function ReplacementCpapTracker({ replacementCpapTrackerDetails }: ReplacementCpapTrackerProps ): JSX.Element {
  return (
    <Tile headerContent="Replacement CPAP Machine" id="replacement-cpap-tracker" className="account-tracker">
      {
        !replacementCpapTrackerDetails ? <LoadingSpinner /> :
          <StepQueueRenderer dynamicStepStatus>
            <EligibleForNewMachineStep stepStatus={replacementCpapTrackerDetails?.eligible_flag} />
            <InsuranceInformationStep stepStatus={replacementCpapTrackerDetails?.information_and_insurance_verified} />
            <PrescriptionNeededStep stepStatus={replacementCpapTrackerDetails?.documentation_on_file} />
            {replacementCpapTrackerDetails?.pa_needed && <InsurancePriorAuthStep stepStatus={replacementCpapTrackerDetails?.pa_on_file} />}
            <ActionNeededStep stepStatus={!replacementCpapTrackerDetails?.action_needed} />
            <OrderShippedStep
              stepStatus={replacementCpapTrackerDetails?.order_shipped}
              trackingNumber={replacementCpapTrackerDetails?.tracking_number || ``}
              trackingLink={replacementCpapTrackerDetails?.tracking_link || ``}
              shipDate={replacementCpapTrackerDetails?.ship_date || ``}
            />
          </StepQueueRenderer>
      }
    </Tile>
  )
}

export default ReplacementCpapTracker