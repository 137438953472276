import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AccountMenuDropdownWrapper from './AccountMenuDropdownWrapper'
import { useTokenStore } from 'stores/tokenStore'

const AccountMenuDropdown = ({ menuIsActive, onlyShowLogout }) => {

  const { customerToken } = useTokenStore()

  if ( !menuIsActive ){
    return null
  }

  // for resupply pt's onlyShowLogout will be true
  if ( customerToken ) {
    return (
      <AccountMenuDropdownWrapper active={menuIsActive}>
        {!onlyShowLogout && <Link to="/account" >{`My Account`}</Link>}
        <Link to="/logout" >{`Log out`}</Link>
      </AccountMenuDropdownWrapper>
    )
  }

  if ( !customerToken ) {
    return (
      <AccountMenuDropdownWrapper active={menuIsActive}>
        <Link to="/login" >{`Log in`}</Link>
      </AccountMenuDropdownWrapper>
    )
  }
}
AccountMenuDropdown.propTypes = {
  menuIsActive: PropTypes.bool.isRequired,
  onlyShowLogout: PropTypes.bool
}

export default AccountMenuDropdown
