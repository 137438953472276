import React from 'react'
import PropTypes from 'prop-types'
import { regexErrors } from 'components/forms/constants'
import { TextInput, CheckboxInput, Validators, Formatters } from 'components/forms/components'
import { useAddressStyleStore } from 'components/addressBlock/style'
import { useAobSegmentStore } from 'stores'

const EmergencyContactBlock = ({ refs, emergencyContactFormFields, setEmergencyContactFormFields, setEmergencyContactError, fieldErrors, setFieldErrors }) => {

  const handleChange = event => {
    setEmergencyContactError( `` )
    setFieldErrors({
      ...fieldErrors,
      [event.currentTarget.name]: ``
    })
    setEmergencyContactFormFields({
      ...emergencyContactFormFields,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  const handleCheckboxChange = event => {
    setEmergencyContactFormFields({
      ...emergencyContactFormFields,
      [event.currentTarget.name]: event.currentTarget.checked
    })
  }

  // use same styling from address block
  const { styleOverrides } = useAddressStyleStore()
  const { view : styles } = styleOverrides

  const { aobSegmentType } = useAobSegmentStore()

  return (
    <form className="w-full min-w-fit mx-auto py-2 px-4">
      <p className="text-xl tracking-wide font-bold text-center my-5">{`Emergency Contact Info`}</p>
      <div className="py-4 mx-auto">
        <TextInput
          name="emergency_contact_name"
          label="Name"
          labelClassName="font-light mb-3"
          className="input w-full"
          value={emergencyContactFormFields.emergency_contact_name}
          reference={refs.emergency_contact_name}
          onChange={handleChange}
          required
          validator={{
            function: Validators.name,
            failureMessage: regexErrors.first_name
          }}
          errorMessage={fieldErrors.emergency_contact_name}
        />
        <TextInput
          name="emergency_contact_relationship_to_patient"
          label="Relationship to Patient"
          labelClassName="font-light mb-3 mt-5"
          value={emergencyContactFormFields.emergency_contact_relationship_to_patient}
          reference={refs.emergency_contact_relationship_to_patient}
          onChange={handleChange}
          required
          errorMessage={fieldErrors.emergency_contact_relationship_to_patient}
          formatter={{
            function: ( value ) => { return value.replace( /\d+/, `` ) } // Remove all digits from input
          }}
        />
        <TextInput
          name="emergency_contact_phone"
          label="Phone Number"
          labelClassName="font-light mb-3 mt-5"
          value={emergencyContactFormFields.emergency_contact_phone}
          reference={refs.emergency_contact_phone}
          onChange={handleChange}
          required
          validator={{
            function: Validators.phone,
            failureMessage: regexErrors.phone
          }}
          formatter={{
            function: Formatters.phone
          }}
          errorMessage={fieldErrors.emergency_contact_phone}
        />
        {aobSegmentType === `Intake` &&
          <CheckboxInput
            label="I also give Aeroflow permission to contact the person listed above to discuss my care, health information, and/or payment for my healthcare."
            name="permission_checked_flag"
            value={emergencyContactFormFields.permission_checked_flag}
            reference={refs.permission_checked_flag}
            className={`${styles.inputClassName} mr-2`}
            wrapperClassName={`${styles.inputWrapperClassName} !max-w-none ml-0`}
            labelClassName={`${styles.labelClassName} w-full pl-1`}
            onClick={handleCheckboxChange}
            required
          />
        }
      </div>
    </form>
  )
}

EmergencyContactBlock.propTypes = {
  aobContent: PropTypes.string,
  fieldErrors: PropTypes.object,
  setFieldErrors: PropTypes.func,
  emergencyContactFormFields: PropTypes.object,
  setEmergencyContactError: PropTypes.func,
  setEmergencyContactFormFields: PropTypes.func,
  refs: PropTypes.object
}

export default EmergencyContactBlock
