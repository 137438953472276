import { useEffect } from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import useRetrieveZip from './hooks/useRetrieveZip'
import DoctorDisplay from './components/DoctorDisplay'
import useRetrieveDoctor from './hooks/useRetrieveDoctor'
import { useQuery } from '@apollo/client'
import { cmsBlocksQuery } from 'graphql/queries'
import { reportToSentry } from 'utils/reportToSentry'
import DoctorInfo from './types/DoctorInfo'
import { useDoctorInput } from './hooks/state'
import { useTokenStore } from 'stores/tokenStore'

type DoctorBlockProps = {
  onSuccess?: ( _doctor: DoctorInfo ) => void;
  existingDoctor?: DoctorInfo | null;
  onExistingDoctor?: () => void;
  forceUpdate?: boolean;
  containerStyle?: string;
  headerStyle?: string;
  isMyAccount?: boolean;
  scrollIntoView?: boolean;
}

function DoctorBlock({ onSuccess, forceUpdate = false, isMyAccount = false, headerStyle, containerStyle, existingDoctor, onExistingDoctor, scrollIntoView = false } : DoctorBlockProps ) : JSX.Element | null {
  const zipLoading = useRetrieveZip()
  const doctorLoading = useRetrieveDoctor( existingDoctor, onExistingDoctor )

  const setDoctorInput = useDoctorInput()[1]

  useEffect( () => {
    setDoctorInput({
      zipCode: ``,
      range: 10,
      lastName: ``
    })
    if ( scrollIntoView ) {
      const doctorBlock = document.getElementById( `doctor-block` )
      if ( doctorBlock ) {
        doctorBlock.scrollIntoView({
          behavior: `smooth`
        })
      }
    }
  }, [] )

  const { data, loading } = useQuery( cmsBlocksQuery, {
    onError: ( error ) => {
      reportToSentry( `Doctor Block: Error getting doctor search text content`, {
        error: error?.message,
        jsonError: JSON.stringify( error )
      })
    },
    variables: {
      identifiers: `react-doctor-block-text`
    }
  })

  const { customerToken } = useTokenStore()

  if ( !customerToken ) return null

  return (
    <div
      id="doctor-block"
      className="my-5 flex flex-col items-center justify-center max-w-2xl mx-auto gap-5"
    >
      <div className={`${ !isMyAccount && `mt-5 mb-10`} w-full mx-auto`}>
        {
          zipLoading || doctorLoading || loading ?
            <LoadingSpinner />
            :
            <DoctorDisplay
              textContent={data?.cmsBlocks?.items?.length ? data.cmsBlocks.items[0]?.content : ``}
              onSuccess={onSuccess}
              forceUpdate={forceUpdate}
              headerStyle={headerStyle}
              containerStyle={containerStyle}
              isMyAccount={isMyAccount}
            />
        }
      </div>
    </div>
  )
}

export default DoctorBlock