import React from 'react'
import Slider from 'react-slick'
import './slick-override.css'

type ImageCarouselProps = {
    children: React.ReactNode
    infiniteCarouselScroll: boolean;
    showDots: boolean;
}

export const SlickSlider = ({ children, ...props}: ImageCarouselProps ) : JSX.Element => {
  const settings = {
    dots: props.showDots,
    infinite: props.infiniteCarouselScroll,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div className="container min-w-full">{
      Slider && (
        <Slider {...settings}>
          {children}
        </Slider>
      )
    }
    </div>
  )
}

export default SlickSlider