import React, { useEffect, useState } from 'react'
import { ladyStretchingImg } from 'resources/images'
import {
  AsSeenInBlock,
  BlockOne,
  FaqBlogBlock,
  HeroBlock,
  MostPopularBlock,
  SleepProductsThroughInsuranceBlock,
  ValuePropositionBlock,
  VideoBlock
} from 'modules/home/components'
import { builder, BuilderComponent } from '@builder.io/react'
import { reportToSentry } from 'utils/reportToSentry'

builder.init( `25b1a215ae9f430989a220ee58457f96` )

export const HomeScreen = () => {
  // useMedchatWidget( `dvKo2qy0JEq1fVRJ0YZ2Rg` )

  const [ homepage, setHomepage ] = useState( null )
  const [ isLoading, setIsLoading ] = useState( true )

  useEffect( () => {
    builder
      .get( `homepage` )
      .toPromise()
      .then( ( homepageData ) => {
        setHomepage( homepageData )
        setIsLoading( false )
      })
      .catch( ( error ) => {
        console.error( `Error fetching homepage data from builder.io:`, error )
        reportToSentry( new Error( `Failed to fetch homepage data from builder.io`, {
          cause: error
        }) )
        setIsLoading( false )
      })
  }, [] )

  return (
    <>
      {( homepage && !isLoading ) ? (
        <BuilderComponent model="homepage" content={homepage} />
      ) : ( !isLoading && !homepage ) ? (
        <div className="pb-20">
          <HeroBlock />
          <ValuePropositionBlock />
          <div className="xl:hidden lg:hidden md:hidden sm:block bg-hero-image-small">
            <img
              src={ladyStretchingImg}
              width="700"
              height="802"
              alt="Lady Stretching"
              className="object-contain"
            />
          </div>
          <div className="flex flex-col">
            <div className="mb-20">
              <BlockOne />
            </div>
            <div className="mb-12">
              <SleepProductsThroughInsuranceBlock />
            </div>
            <div className="mb-12">
              <AsSeenInBlock />
            </div>
            <div className="mb-12">
              <MostPopularBlock />
            </div>
            <div className="mb-12">
              <VideoBlock />
            </div>
            <div className="mb-12">
              <FaqBlogBlock />
            </div>
          </div>
        </div>
      ) : null }
    </>
  )
}

export default HomeScreen
