import * as React from 'react'
import PropTypes from 'prop-types'
import { SNL_THANK_YOU_PATH } from '../constants'
import { SNLStorage } from 'apollo'
import NoLongerInterestedThankYou from './NoLongerInterestedThankYou'
import LoadingSpinner from 'components/LoadingSpinner'
import { getHashFromLeadInfo } from '../fetch'
import { reportToSentry } from 'utils/reportToSentry'
import { useNavigate } from 'react-router-dom'
import { SUMMARY_PAGE_PATH } from 'modules/aobPayments/constants'
import NoMachineThankYou from './NoMachineThankYou'
import ThankYou from './ThankYou'
import { useQualifyFormStore } from 'modules/qualifyForm/state'

const SnlThankYou = ({setDisplayProgress, interested, formValues}) => {

  const [ loading, setLoading ] = React.useState( true )

  const navigate = useNavigate()

  const { formCode, setFormCode, processId, setProcessId } = useQualifyFormStore()

  React.useEffect( () => {
    const controller = new AbortController()
    if ( formValues.lastOrderCpapMachine !== `3` ) {
      getHashFromLeadInfo( formCode, processId, controller.signal )
        .then( ( data ) => {
          setLoading( false )
          if ( data?.meta?.status === `OK` ) {
            if ( data?.data?.patient_hash && data?.data?.rte_result ) {
              // if below is true we need to set a flag in state, flag needs to be inspected on the payments summary and if present do not show the shipping address input block
              sessionStorage.setItem( `patientShippingAddressCollectedPriorToPaymentPortalMount`, `true` )


              // @TODO here when we take them to the payments page need to inspect for secret url hash to trigger sending them to the payment test portal
              navigate( SUMMARY_PAGE_PATH.concat( `?sgh=${data.data.patient_hash}` ), {
                replace: true
              })
            }
            setFormCode( null )
            setProcessId( null )
          } else {
            reportToSentry( `SNL 2.1: Error getting hash from new leads process id`, {
              processId
            })
          }
        })
        .catch( ( err ) => {
          if ( err.name !== `AbortError` ) {
            setLoading( false )
            reportToSentry( `SNL 2.1: Error getting hash from new leads process id`, {
              processId
            })
          }
        })
    } else setLoading( false )

    return () => {
      controller.abort()
    }
  }, [] )


  React.useEffect( () => {
    if ( window.location.pathname === SNL_THANK_YOU_PATH ) {
      setDisplayProgress( false )
      SNLStorage.clearSession()
    }
  }, [] )

  if ( formValues.lastOrderCpapMachine === `3` ) return <NoMachineThankYou />

  if ( loading ) return <div className="my-20"><LoadingSpinner /></div>

  if ( !interested ) return <NoLongerInterestedThankYou />

  return <ThankYou />
}

SnlThankYou.propTypes = {
  setDisplayProgress: PropTypes.func,
  interested: PropTypes.bool,
  formValues: PropTypes.shape({
    lastOrderCpapMachine: PropTypes.string
  })
}

export default SnlThankYou
