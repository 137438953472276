import React from 'react'
import { useNavigate } from 'react-router-dom'

import { FormButton} from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { Modal } from 'components/modal'
import { PhoneLink } from 'components/ALinks'
import { PHONE } from 'components/footerBar/constants'
import { EmailPhoneGroup, DateOfBirthInput, useDateInputState, useValidInputs, useEmailInputState, usePhoneInputState } from './input'
import { LOGIN_PATH, LOGIN_M2_PATH } from 'routes'
import { deprecatedPatientLogin } from './utils'
import { reportToSentry } from '../../utils/reportToSentry'

import { isEmailAvailableQuery } from 'graphql/queries'
import { useLazyQuery } from '@apollo/client'

const MigrationLoginForm = ({ ...pageProps}) => {
  const navigate = useNavigate()

  const [ dateFields, setDateFields ] = useDateInputState()
  const [ email, setEmail ] = useEmailInputState()
  const [ phone, setPhone ] = usePhoneInputState()
  const { isValidEmail, isValidPhone, isValidDate } = useValidInputs()
  const [ serverErrorMessage, setServerErrorMessage ] = React.useState( `` )
  const [ isModalOpen, setIsModalOpen ] = React.useState( false )
  const [ loading, setLoading ] = React.useState( false )

  const [ checkEmailAvailability ] = useLazyQuery(
    isEmailAvailableQuery, {
      variables: {
        email: email
      },
      onError: ( ( error ) => {
        reportToSentry( new Error ( `isEmailAvailable Query: MigrationLoginForm`, {
          cause: error
        }) )
      })
    })

  const handleDeprecatedPatientLogin = () => {
    setLoading( true )

    const loginBody = {
      dob: `${dateFields.dobYear}-${dateFields.dobMonth}-${dateFields.dobDay}`,
      profitCenterPK: 5,
      ...( email ? {
        email: email
      } : {
        phone: phone.replace( ` `, `-` ).replace( /[()]/g, `` )
      })
    }

    deprecatedPatientLogin( loginBody ).then( deprecatedPatientRes => {
      // check if the email is already registered in Magento, if so, prompt the user to sign in with email or one-time passcode
      if ( deprecatedPatientRes?.data?.email ) {
        return checkEmailAvailability({
          variables: {
            email: deprecatedPatientRes.data.email
          }
        }).then( ( emailAvailabilityRes ) => {
          setLoading( false )
          if ( !emailAvailabilityRes?.data?.isEmailAvailable?.is_email_available ) {
            // when the email is not available, it means the email is already registered in Magento
            // send user to the m2 login page with the email query param
            return navigate( `${LOGIN_M2_PATH}?registeredUser=${encodeURIComponent( deprecatedPatientRes.data.email )}` )
          }
          // prompt user to create an account if the email is not registered in Magento
          if ( deprecatedPatientRes?.data?.unique_hash ) {
            pageProps.handleSetPatientData( deprecatedPatientRes.data )
            pageProps.nextPage( )
          }
        })
          .catch( error => {
            reportToSentry( `isEmailAvailable Query: Error Verifying Email`, {
              errorMessage: JSON.stringify( error ?? `` )
            })
          })
      }

      setLoading( false )

      if ( deprecatedPatientRes.errors ) {
        // if they have already tried to login with a phone or email and we know they are tying to migrate we need to give them a message to call
        // this is a good spot for GA event
        setIsModalOpen( true )
      }

      return setServerErrorMessage( `There was an error. Please try again.` )

    })
      .catch( err => {
        setLoading( false )
        setIsModalOpen( true )
        reportToSentry( new Error ( `there was an error in the deprecated patient login mutation`, {
          cause: err
        }) )
      })
  }

  const handleRetryLogin = () => {
    // we need to reset all fields
    setPhone( `` )
    setEmail( `` )
    setDateFields({
      dobDay: ``,
      dobMonth: ``,
      dobYear: ``
    })
    navigate( LOGIN_PATH, {
      replace: true
    })
  }

  if ( loading ) {
    return (
      <div className="my-8">
        <p className="mb-2">{`Checking migration status...`}</p>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <form onSubmit={handleDeprecatedPatientLogin} className="mx-auto flex flex-col items-center">

      <div className="my-10">
        <EmailPhoneGroup initialShowPhone={Boolean( phone?.length )} initialShowEmail={Boolean( email?.length )} />
        <br></br>
        <DateOfBirthInput />
      </div>

      {serverErrorMessage && <p className="text-xs text-center text-error my-4">{serverErrorMessage}</p>}

      <FormButton type="submit" className="btn-primary" disabled={loading || ( !isValidEmail && !isValidPhone || !isValidDate )}>
        {`Continue`}
      </FormButton>

      <p className="text-xs text-center text-error my-4">{`* Required Fields`}</p>

      <Modal
        isOpen={isModalOpen}
        onClose={() => { setIsModalOpen( false ); handleRetryLogin() }}
        closeBtnTitle={`Try a different email or phone number`}
      >
        <p>{`We are having trouble locating your resupply account. You can call customer service at `}
          <PhoneLink displayNumber={PHONE} />{` or try to login with a different email or phone number.`}
        </p>
      </Modal>
    </form>
  )
}

export default MigrationLoginForm
