import { CMSContent } from 'components/cms'
import LoadingSpinner from 'components/LoadingSpinner'
import { useTermsAndConditions } from './hooks/useTermsAndConditions'


export const Terms = () => {

  const { termsAndConditions, loading, requestError } = useTermsAndConditions()

  return (
    <div className="border border-lightGray max-w-6xl mx-auto p-8 mb-16">
      <h3 className="pb-6">{`Terms and Conditions`}</h3>
      {requestError && <p className="text-error">{`Error fetching terms and conditions: ${requestError}`}</p>}
      {loading ? <LoadingSpinner /> : <CMSContent content={termsAndConditions} />}
    </div>
  )
}
