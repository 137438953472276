import { useEffect, useState } from 'react'
import { reportToSentry } from 'utils/reportToSentry'

export const useTermsAndConditions = () => {
  const [ loading, setLoading ] = useState( true )
  const [ requestError, setRequestError ] = useState( `` )
  const [ termsAndConditions, setTermsAndConditions ] = useState()

  useEffect( () => {
    const controller = new AbortController()
    fetch( `${process.env.REACT_APP_LAMBDA_ACCEPTANCE_OF_BENEFITS_URL}/?profit_center_pk=5`, {
      signal: controller.signal
    }).then( res => {
      return res.json()
    })
      .then( data => {
        const aobContent = data?.data?.aob_content ?? data[0]?.aob_content
        if ( !aobContent ) throw new Error( `Error fetching Terms & Conditions` )
        setTermsAndConditions( aobContent )
      })
      .catch( error => {
        if ( error.name !== `AbortError` ) {
          setRequestError( JSON.stringify( error ) )
          setTermsAndConditions( `Failed to fetch terms and conditions.` )
          console.error( error )
          reportToSentry( error )
        }

      })
      .finally( () => {
        setLoading( false )
      })

    return () => {
      controller.abort()
    }
  }, [] )

  return {
    termsAndConditions,
    loading,
    requestError
  }
}