import React from 'react'
import { PhoneLink } from 'components/ALinks'
import Logo from 'components/headerBar/Logo'

function NoEligibleInvoice() : JSX.Element {
  return (
    <div className="w-full mx-auto text-center mt-24 bg-white px-2">
      <div className="flex justify-center mb-8">
        <Logo withoutLink />
      </div>
      <p className="sm:text-lg text-2xl mb-4">{`Oops, looks like there’s been a change with your invoice or you’ve already made a payment.`}</p>
      <p className="sm:text-lg text-2xl">{`If you have any questions, please give us a call at`} <PhoneLink displayNumber={`(800) 480-5491`} /> </p>
    </div>
  )
}

export default NoEligibleInvoice