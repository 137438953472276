import { getAuthHeader } from 'utils/auth/helpers'
import { addMagentoEnvAuthHeaders } from 'utils/magentoEnvAuthHeaders'

export const fetchAwsResupplyOrderHistory = async ( ) => {
  const response = await fetch( `${process.env.REACT_APP_LAMBDA_RESUPPLY_URL}/order-history`, {
    headers: {
      ...addMagentoEnvAuthHeaders(),
      'Authorization': getAuthHeader()
    }
  })

  if ( response?.ok ) return await response.json()

  throw new Error( `Response Failed with ${response?.status}` )

}