import { getUniqueIdPrefix } from 'components/forms/utils'
import { RadioInputProps } from '../types'

const RadioInput = (
  {
    label,
    id,
    name,
    required = false,
    labelClassName = ``,
    ...inputProps
  } : RadioInputProps ) => {
  const _id = id ? id : `${getUniqueIdPrefix()}-${name ?? `radio_input`}`

  return (
    <div>
      <input
        id={_id}
        name={name}
        required={required}
        type="radio"
        {...inputProps}
      />

      {required && <span className="text-error font-normal">{`*`}</span>}

      <label htmlFor={_id} className={`text-sm ml-2 cursor-pointer ${labelClassName}`}>
        {label}
      </label>
    </div>
  )
}

export default RadioInput