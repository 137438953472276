import { useLocation } from "react-router-dom"
import DetailsBlock from "./DetailsBlock"
import { ThankYouDetailsFromAOB, ThankYouDetailsFromSNL } from "./ThankYouDetailsCopy"

export default function ThankYou() {

  const location = useLocation()

  return (
    <div className="max-w-xl md:mx-auto sm:mx-4 mb-20">
      <h1 className="text-left text-deepSleepBlue text-3xl md:w-full sm:w-60 font-semibold leading-10 my-6 mx-4">{`Thank you for completing your profile!`}</h1>
      <h3 className="text-2xl text-deepSleepBlue font-semibold m-4 mb-8">{`What's next?`}</h3>
      <div className="max-w-lg>">
        {
          ( location.pathname.includes( `payments` ) ? ThankYouDetailsFromAOB : ThankYouDetailsFromSNL ).map( detail => {
            return (
              <DetailsBlock
                key={detail.title}
                {...detail}
              />
            )
          })
        }

        <div className="flex sm:max-w-md md:max-w-lg gap-2">
          <div className="w-1/4"></div>
          <p className="sm:ml-4 md:ml-0 mt-0 w-3/4 text-deepSleepBlue">{`Be on the lookout for communication from us within 5 business days.`}</p>
        </div>

      </div>
    </div>
  )
}