import { useEffect, useState } from 'react'
import { useClaimedState } from '../hooks'
import Header from './Header'
import { CommerceDetailBlock, CommerceDetailCheckbox } from 'components/resupply2'
import { ResupplyOption } from '../types'
import { PagePropsAbstract } from 'components/pageNavigator'
import { resupplyShippingAddress } from 'apollo'
import { isCurrentDateOrPastDate } from 'utils/time'
import { serializeOptions } from 'utils/serializeResupplyOptions'
import { useNavigate } from 'react-router-dom'
import Tooltip from 'components/tooltip'
import { icoInfoAob } from 'resources/images'
import { MY_ACCOUNT_PATH } from 'routes'

type ClaimsProps = PagePropsAbstract
export default function Claims({ ...pageProps} : ClaimsProps ) : JSX.Element {

  const [ error, setError ] = useState<string>( `` )

  const [ claimedItems, setClaimedItems ] = useClaimedState()
  const { error: optionsError, resupplyOptions, loading: resupplyLoading, hasAddOns, shippingAddress, replaceMaskOptions } = pageProps.resupplyOptionResponse
  const navigate = useNavigate()
  useEffect( () => {
    if ( !resupplyLoading && resupplyOptions.length < 1 ) {
      return navigate( MY_ACCOUNT_PATH, {
        replace: true
      })
    }
  }, [] )

  const hasAvailable = Boolean( resupplyOptions.find( ( item: ResupplyOption ) => { return isCurrentDateOrPastDate( item.eligible ) }) )

  resupplyShippingAddress( shippingAddress )

  const getIsClaimed = ( item : ResupplyOption ) => {
    const claimedItem = claimedItems.find( ( option ) => {

      return option.magento_sku == item.magento_sku
    })

    return Boolean( claimedItem )
  }

  const getClaimedIndex = ( item : ResupplyOption ) => {
    return claimedItems.findIndex( ( option ) => {
      return item.magento_sku == option.magento_sku
    })
  }

  /** Removes claimedItem if present, adds or "claims" if not present */
  const handleClaimItem = ( item : ResupplyOption ) => {
    setError( `` )
    // need to see if item is already in cart, if it is remove it
    // we also need to get the index of the item in the array to remove the empty object
    const isClaimed = getIsClaimed( item )
    const _cart = [ ...claimedItems ]
    if ( isClaimed ) {
      const claimedIndex = getClaimedIndex( item )
      _cart.splice( claimedIndex, 1 )
    } else {
      _cart.push( item )
    }

    setClaimedItems( serializeOptions( _cart ) ?? [] )
  }

  const handleNextPage = () => {
    if ( claimedItems?.length === 1 && !claimedItems[0].allow_alone ) return setError( `This item must be claimed with additional items. Please select at least one more item to continue.` )

    pageProps.nextPage()
  }

  if ( !resupplyLoading && resupplyOptions.length === 0 && !optionsError ) return <p className="text-center my-10">{`There are currently no resupply options available`}</p>

  if ( !resupplyLoading && optionsError ){
    return (
      <p className="text-error">{optionsError}</p>
    )
  }

  return (
    <div className="flex justify-center mt-10 mb-16">
      <div>
        <Header />
        {hasAvailable &&
          <p className="text-center mb-8 text-xl">{`Supply items available now`}</p>
        }
        <div data-testid="resupply-claim-items-container">
          {resupplyOptions.map( ( item: ResupplyOption ) => {
            const isChecked = getIsClaimed( item )
            if ( isCurrentDateOrPastDate( item.eligible ) && item.magento_sku && !item.additional_item_flag ) {
              return (
                <CommerceDetailCheckbox
                  key={item.hcpc_id}
                  item={item}
                  isChecked={isChecked}
                  onChange={() => { handleClaimItem( item ) }}
                />
              )
            }
          })}
        </div>
        { hasAddOns &&
        <div className="my-16">
          <p className="text-center mb-8 text-xl">{`Additional supply items available through your insurance`}</p>
          {
            resupplyOptions.map( ( item: ResupplyOption ) => {
              const isChecked = getIsClaimed( item )

              if ( item.additional_item_flag )
                return (
                  <CommerceDetailCheckbox
                    key={item.hcpc_id}
                    item={item}
                    isChecked={isChecked}
                    onChange={() => { return handleClaimItem( item ) }}
                  />
                )
            })
          }
        </div>
        }

        {Boolean( resupplyOptions.find( ( item: ResupplyOption ) => { return !isCurrentDateOrPastDate( item.eligible ) }) ) &&
          <div className="my-16">
            <div className="flex justify-center items-start mb-8 md:gap-2">

              <Tooltip
                id="claim-info"
                tooltipContent={<span className="w-10/12">{`Due to insurance guidelines, these items are not available for order until the associated Next Order Date.`}</span>}
              >
                <img
                  src={icoInfoAob}
                  alt="ico-info"
                  className="px-2 mt-1 hover:opacity-80 cursor-pointer object-contain"
                  width={37}
                />
              </Tooltip>
              <p className="text-center text-xl">{`Supply items that are covered through insurance on a future date`}</p>
            </div>
            {
              resupplyOptions.map( ( item: ResupplyOption ) => {
                if ( !isCurrentDateOrPastDate( item.eligible ) )
                  return (
                    <div key={item.hcpc_id} className="flex mb-8 w-8/12 mx-auto">
                      <CommerceDetailBlock
                        productName={item.description}
                        shipDate={item.Can_Ship_Date}
                        productQty={item.max_qty}
                        imgSrc={item.imageUrl}
                        nextOrderDate={item.eligible}
                      />
                    </div>
                  )
              })
            }
          </div>
        }
        <div className="max-w-xs w-full mx-auto mt-16 mb-5 flex flex-col items-center gap-4">
          <button
            disabled={!claimedItems.length}
            className="btn-secondary"
            onClick={handleNextPage}
          >
            {`Next`}
          </button>
          {
            replaceMaskOptions?.length > 0 &&
            <button className="btn btn-primary" onClick={pageProps.prevPage} >{`Back`}</button>
          }
        </div>
        {
          error &&
          <p className="my-5 text-error font-light max-w-md text-center mx-auto">{error}</p>
        }
      </div>
    </div>
  )
}