import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PageTracker } from '..'
import { TubingSelector, LastOrderSuppliesSelector } from 'components/SNLSelectors'
import { scrollToBelowLogo } from 'components/scrollToTop'
import { SNLBackButton } from '../SNLBackButton'

const stepTargets = [
  `tubingType`,
  `lastOrderCpapSupplies`
]

const Tubing = ({ onHandleChange, formValues, ...pageProps }) => {
  const [ handleChangeTarget, setHandleChangeTarget ] = useState( `tubingType` )

  const handleChange = ( e ) => {
    if ( e.target.name === `tubingType` ){
      onHandleChange({
        [e.target.name]: e.target.label,
        tubingDmeID: e.target.value
      })
    } else {
      onHandleChange({
        [e.target.name]: e.target.value
      })
    }

    handleNextMobile()
  }

  const handleNextMobile = () => {
    scrollToBelowLogo()
    if ( stepTargets[stepTargets.length - 1] === handleChangeTarget ) {
      return pageProps.nextPage()
    }
    const index = stepTargets.findIndex( item => { return item === handleChangeTarget }) || 0
    setHandleChangeTarget( stepTargets[index + 1] )
  }

  const handleBackMobile = () => {
    scrollToBelowLogo()
    if ( handleChangeTarget && stepTargets[0] !== handleChangeTarget ) {
      const index = stepTargets.findIndex( item => { return item === handleChangeTarget }) || 0

      return setHandleChangeTarget( stepTargets[index - 1] )
    }
    setHandleChangeTarget( stepTargets[0] )
    pageProps.prevPage()
  }

  return (
    <div>
      <PageTracker
        title={`Your Accessories`}
      />

      <TubingSelector
        heading={`Do you use heated or standard tubing?`}
        selectedValue={formValues.tubingDmeID}
        onChange={handleChange}
        showOnMobile={!handleChangeTarget || handleChangeTarget === `tubingType`}
      />

      <LastOrderSuppliesSelector
        heading={`Has it been more than 7 months since you last got your CPAP supplies through insurance?`}
        name="lastOrderCpapSupplies"
        options={[
          {
            value: `GT7MOS`,
            label: `Yes`
          },
          {
            value: `LT7MOS`,
            label: `No`
          },
          {
            value: `NEVER`,
            label: `I’ve never recieved supplies through insurance`
          }
        ]}
        selectedValue={formValues.lastOrderCpapSupplies}
        onChange={handleChange}
        showOnMobile={handleChangeTarget === `lastOrderCpapSupplies`}
      />

      <SNLBackButton
        hide={handleChangeTarget === stepTargets[0]}
        handleBack={handleBackMobile}
      />
    </div>
  )
}

Tubing.propTypes = {
  onHandleChange: PropTypes.func,
  formValues: PropTypes.shape({
    tubingType: PropTypes.string,
    tubingDmeID: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    lastOrderCpapSupplies: PropTypes.string
  })
}

export default Tubing
