import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { ColWrapper, ColHeader } from './tailwindWrappers'

const LinksCol = ({ panelHeader, links }) => {
  return (
    <ColWrapper>
      <ColHeader>{panelHeader}</ColHeader>
      <ul className="p-0 m-0">
        {links.map( ( link, i ) => {

          const { display, href, CMSPage } = link

          return (
          // eslint-disable-next-line react/no-array-index-key
            <li key={i} className="my-3">
              {CMSPage ? <Link to={href}><span className="text-white text-sm xl:text-base">{display}</span></Link> : <a href={href} rel="noreferrer"><span className="text-white text-sm lg:text-base">{display}</span></a>}
            </li>
          )
        })}
      </ul>
    </ColWrapper>
  )
}
LinksCol.propTypes = {
  panelHeader: PropTypes.string,
  links: PropTypes.array.isRequired
}

export default LinksCol
