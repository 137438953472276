import { Formatters, TextInput, Validators } from 'components/forms/components'
import { regexErrors } from 'components/forms/constants'
import LoadingSpinner from 'components/LoadingSpinner'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import React, { useState, useRef } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import DoctorInfo from '../types/DoctorInfo'
import { updateDoctor } from '../utils/utils'
import { getAuthHeader } from 'utils/auth/helpers'
import { useDoctorNpiStore } from '../hooks/state'

type ManualDoctorFormProps = {
  setManualDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  handleSuccess: ( _newDoctor: DoctorInfo ) => void;
  currentDoctor: DoctorInfo | null;
}

type DoctorInputErrors = {
  [key: string]: string;
}

function ManualDoctorForm({ setManualDisplay, handleSuccess, currentDoctor } : ManualDoctorFormProps ) : JSX.Element {

  const [ doctorInput, setDoctorInput ] = useState<DoctorInfo>({
    lastName: ``,
    firstName: ``,
    phone: ``
  })
  const [ doctorInputErrors, setDoctorInputErrors ] = useState<DoctorInputErrors>({})
  const [ submitLoading, setSubmitLoading ] = useState<boolean>( false )
  const [ error, setError ] = useState<string>( `` )

  const firstNameRef = useRef<HTMLInputElement>( null )
  const lastNameRef = useRef<HTMLInputElement>( null )
  const phoneRef = useRef<HTMLInputElement>( null )

  const defaultError = `Oops... we encountered an error attempting to update your doctor. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if this issue persists.`

  const { setShowDoctorDisplayModal } = useDoctorNpiStore()

  const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setError( `` )
    const updateDoctorErrors = doctorInputErrors
    delete updateDoctorErrors[e.target.name]
    setDoctorInputErrors( Object.assign({}, doctorInputErrors, updateDoctorErrors ) )
    setDoctorInput( Object.assign({}, doctorInput, {
      [e.target.name]: e.target.value
    }) )
  }

  const handleBlur = ( e: React.FocusEvent<HTMLInputElement> ) => {
    if ( e.target.name?.toLowerCase()?.includes( `name` ) && !Validators.name( e.target.value ) ) {
      return setDoctorInputErrors( Object.assign({}, doctorInputErrors, {
        [e.target.name]: regexErrors.name
      }) )
    }

    if ( e.target.name === `phone` && !Validators.phone( e.target.value ) ) {
      return setDoctorInputErrors( Object.assign({}, doctorInputErrors, {
        phone: regexErrors.phone
      }) )
    }
  }

  const submitUpdateDoctor = async () => {
    if ( !doctorInput.firstName.length || !doctorInput.lastName.length || !doctorInput.phone.length ) {
      setDoctorInputErrors( Object.assign({}, doctorInputErrors, {
        ...( !doctorInput.firstName.length ? {
          firstName: regexErrors.name
        } : {}),
        ...( !doctorInput.lastName.length ? {
          lastName: regexErrors.name
        } : {}),
        ...( !doctorInput.phone.length ? {
          phone: regexErrors.phone
        } : {})
      }) )

      if ( !doctorInput.firstName.length ) return firstNameRef?.current?.focus()
      if ( !doctorInput.lastName.length ) return lastNameRef?.current?.focus()
      if ( !doctorInput.phone.length ) return phoneRef?.current?.focus()
    }

    if ( Object.keys( doctorInputErrors ).length ) setError( `Please resolve input errors` )

    setSubmitLoading( true )
    const updateDoctorResponse = await updateDoctor({
      doctorFirstName: doctorInput.firstName,
      doctorLastName: doctorInput.lastName,
      doctorPhone: doctorInput.phone,
      profitCenterPK: `5`
    }, getAuthHeader() )
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `Update Doctor Failed`, {
          cause: error
        }) )
        setSubmitLoading( false )

        return setError( defaultError )
      })

    setSubmitLoading( false )

    if ( updateDoctorResponse?.meta?.status === `OK` ) {
      // notify patient if manual MD entry does NOT match with a known Npi
      if ( !updateDoctorResponse?.data?.npi ) {
        setShowDoctorDisplayModal( !currentDoctor?.npi )
      }

      return handleSuccess( doctorInput )
    }

    setError( defaultError )
  }

  if ( submitLoading ) return (
    <div className="my-10">
      <LoadingSpinner />
    </div>
  )

  return (
    <div className="flex flex-col w-full max-w-md mx-auto px-5 gap-3">
      {
        currentDoctor &&
        <>
          <h1
            className="text-2xl font-light text-center mb-1"
          >
            {`Current Doctor`}
          </h1>
          <div className="flex flex-col items-center">
            <p className="text-xl capitalize mb-2">{`${currentDoctor?.firstName?.toLowerCase()} ${currentDoctor?.lastName?.toLowerCase()}`}</p>
            <p className="text-lg font-light">{Formatters.phone( currentDoctor?.phone )}</p>
          </div>
        </>
      }
      <h1 className="text-2xl font-light text-center mt-3 mb-1">{`Add your Doctor Manually`}</h1>
      <TextInput
        name="firstName"
        label="First Name"
        required
        errorMessageClassName="max-w-xs text-error text-center text-sm font-light mx-auto"
        reference={firstNameRef}
        value={doctorInput.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMessage={doctorInputErrors.firstName}
      />
      <TextInput
        name="lastName"
        label="Last Name"
        required
        reference={lastNameRef}
        errorMessageClassName="max-w-xs text-error text-center text-sm font-light mx-auto"
        value={doctorInput.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMessage={doctorInputErrors.lastName}
      />
      <TextInput
        name="phone"
        label="Phone Number"
        type="tel"
        required
        reference={phoneRef}
        errorMessageClassName="max-w-xs text-error text-center text-sm font-light mx-auto"
        value={doctorInput.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        formatter={{
          function: Formatters.phone
        }}
        errorMessage={doctorInputErrors.phone}
      />
      <div className="mt-5 flex flex-col items-center gap-3">
        <button
          className="btn-secondary"
          disabled={submitLoading}
          onClick={submitUpdateDoctor}
        >
          {`Add Doctor`}
        </button>
        <button
          className="btn-primary"
          disabled={submitLoading}
          onClick={() => { return setManualDisplay( false ) }}
        >
          {`Back`}
        </button>
      </div>
      {
        error &&
        <p className="text-error font-light text-center">{error}</p>
      }
    </div>
  )
}

export default ManualDoctorForm