import React from 'react'
import AccountTrackerDisplay from './components/AccountTrackerDisplay'
import { useAccountStore } from '../../modules/accountV2/state'

function AccountTracker() : JSX.Element {
  const { accountTracker } = useAccountStore()

  return (
    <>
      {accountTracker.data && <AccountTrackerDisplay trackerDetails={accountTracker.data} />}
    </>
  )
}

export default AccountTracker