import { makeVar } from '@apollo/client'
import { buildCustomState } from 'apollo'
import DoctorInfo from '../types/DoctorInfo'
import DoctorInput from '../types/DoctorInput'
import { create } from 'zustand'

const doctorInputVar = makeVar<DoctorInput>({
  zipCode: ``,
  range: 10,
  lastName: ``
})

const doctorVar = makeVar<DoctorInfo | null>( null )

export function useDoctorInput(): [DoctorInput, ( _d: DoctorInput ) => void] {
  return buildCustomState<DoctorInput>( doctorInputVar )
}

export function useDoctor(): [DoctorInfo | null, ( _d: DoctorInfo | null ) => void] {
  return buildCustomState<DoctorInfo | null>( doctorVar )
}

type DoctorNpiState = {
  showDoctorDisplayModal: boolean;
  setShowDoctorDisplayModal: ( _show: boolean ) => void;
}

export const useDoctorNpiStore = create<DoctorNpiState>( ( set ) => ({
  showDoctorDisplayModal: false,
  setShowDoctorDisplayModal: ( _show: boolean ) => set({ showDoctorDisplayModal: _show })
}) )