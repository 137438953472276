import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { apolloClient } from 'apollo/apolloClient'
import { Header as HeaderV2 } from 'components/headerBarV2'
import { Footer as FooterV2 } from 'components/footerBarV2'
import { HomeHead } from 'components/head'
import ErrorHandler from 'components/errorBoundary/ErrorHandler'
import LocationChangeFactory from 'components/events/LocationChangeFactory'
import ScrollToTop from 'components/scrollToTop'
import { PageMapping } from 'modules/pageMapping'
import MaintenanceListener from 'modules/maintenanceListener'
import 'react-tooltip/dist/react-tooltip.css'

/* Keep this file lean - want a quick first page load -> then load async */
const App = () => {
  // eslint-disable-next-line no-console
  console.log({
    envVariable: process.env.NODE_ENV,
    m2Endpoint: process.env.REACT_APP_MAGENTO_2_BASE_URL
  })

  return (
    <ErrorHandler>
      <ApolloProvider client={apolloClient}>
        <Router>
          <>
            <HomeHead />
            <LocationChangeFactory />
            <ScrollToTop />
            <MaintenanceListener />
            <Routes>
              <Route path="/*" element={<HeaderV2 />} />
            </Routes>
            <PageMapping />
            <Routes>
              <Route path="/*" element={<FooterV2 />} />
            </Routes>
          </>
        </Router>
      </ApolloProvider>
    </ErrorHandler>
  )
}

export default App
