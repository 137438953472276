import ContentStacker from "components/contentStacker/ContentStacker"
import PropTypes from "prop-types"
import { replacementMachineBlock, additionalInfoBlock } from "./constants"

function MachineShortageStacker({triggerHeightChange, endContent}) {

  const contentArr = [
    ...replacementMachineBlock,
    additionalInfoBlock
  ]

  return (
    <ContentStacker
      content={contentArr}
      containerStyle="relative overflow-hidden w-full"
      triggerHeightChange={triggerHeightChange}
      interval={2}
      endContent={endContent}
    />
  )
}

MachineShortageStacker.propTypes = {
  triggerHeightChange: PropTypes.func,
  endContent: PropTypes.node
}

export default MachineShortageStacker