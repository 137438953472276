import { useAddressStore } from "../state"
import Highlight from "./Highlight"

export default function HighlightP() {
  const { suggestionHighlights } = useAddressStore()

  if ( !suggestionHighlights ) return null

  const subpremise = suggestionHighlights?.subpremise // This may be undefined
  return (
    <p className="text-left">
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!suggestionHighlights.street_number.highlight}>{suggestionHighlights.street_number.value}</Highlight>{` `}
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!suggestionHighlights.route.highlight}>{suggestionHighlights.route.value}</Highlight>{`, `}
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!subpremise?.highlight}>{subpremise?.value ?? ``}</Highlight>{subpremise && `, `}
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!suggestionHighlights.locality.highlight}>{suggestionHighlights.locality.value}</Highlight>{`, `}
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!suggestionHighlights.administrative_area_level_1.highlight}>{suggestionHighlights.administrative_area_level_1.value}</Highlight>{` `}
      <Highlight highlightClassName="text-white bg-success" shouldNotHighlight={!suggestionHighlights.postal_code.highlight}>{suggestionHighlights.postal_code.value}</Highlight>
    </p>
  )
}