import * as React from 'react'
import { getPatientAddress } from 'utils/patientInfoApi'
import { customerQuery } from 'graphql/queries'
import { useQuery } from "@apollo/client"
import { useAddressStore } from '../state'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'
import { getAuthHeader } from 'utils/auth/helpers'
import { reportToSentry } from 'utils/reportToSentry'

/* This component syncs our AddressBlock with the customer's address in the database.
This is useful for when we have the customer's address saved in the database, but we want to use the autocomplete api to verify the address.
This component will fetch the customer's address on mount and set the address in state.
This will also set the current view to `complete` if the customer's address is found in the database.
We also sync the customer's first and last name from the qualify form to the address block.
*/
export default function useExternalSync({ shouldSkipMountFetch = false } : { shouldSkipMountFetch: boolean }) {

  const [ initialLoading, setInitialLoading ] = React.useState<boolean>( true )
  const {setCurrentView, address, setAddress} = useAddressStore()

  const { formFields } = useQualifyFieldsStore()
  const { data: customerData } = useQuery( customerQuery, {
    skip: shouldSkipMountFetch
  })

  React.useEffect( () => {
    let mounted = true
    // If we have the formFields from the qualify form, we import those if this is the first time the user is filling out the address block
    const missingAddressFields = Object.values( address ).some( value => !value )
    const qualifyFormComplete = Object.values( formFields ).some( value => value ) // Means we have some data from the qualify form
    if ( qualifyFormComplete && missingAddressFields ) {
      setAddress({
        ...address,
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        state: formFields.state,
        zipCode: formFields.zipCode
      })
    }
    if ( shouldSkipMountFetch ) return setInitialLoading( false )
    getPatientAddress( getAuthHeader() ).then( _address => {
      if ( !mounted ) return
      const hasAddressSaved = _address?.street?.length
      if ( hasAddressSaved ) {
        setAddress({
          zipCode: _address.zip,
          firstName: customerData?.customer?.firstname,
          lastName: customerData?.customer?.lastname,
          ..._address
        })
      }
      setCurrentView( hasAddressSaved ? `complete` : `edit`, false )
    })
      .catch( () => reportToSentry( `AddressBlock2.0 - Failed to fetch address on mount ` ) )
      .finally( () => mounted && setInitialLoading( false ) )
    return () => { mounted = false }
  }, [] )

  return {
    initialLoading
  }
}