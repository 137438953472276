import * as React from 'react'

export default function AlmostDoneHeader(): JSX.Element {
  return (
    <div className="py-4 my-4 text-left">
      <h3 className="text-deepSleepBlue">{`Almost Done!`}</h3>
      <p className="p-lg my-4">{`Simply set your password and complete your sleep apnea profile in order to move forward`}</p>
      <p className="italic">{`This information helps us identify which CPAP supplies you are eligible to receive through insurance and is a required step in this process.`}</p>
    </div>
  )
}