import React, { useEffect } from 'react'
import { useTokenStore } from 'stores/tokenStore'
import { useDoctorInput } from '../../../components/doctorBlock/hooks/state'
import DoctorDisplay from '../../../components/doctorBlock/components/DoctorDisplay'
import { useAccountStore } from '../state'
import { PhoneLink } from '../../../components/ALinks'
import { PHONE } from '../../../components/footerBar/constants'

type PhysicianBlockProps = {
  isMyAccount?: boolean;
  scrollIntoView?: boolean;
}

function PhysicianBlock({ isMyAccount = false, scrollIntoView = false } : PhysicianBlockProps ) : JSX.Element | null {
  const setDoctorInput = useDoctorInput()[1]
  const { physician } = useAccountStore()
  const { customerToken } = useTokenStore()

  useEffect( () => {
    setDoctorInput({
      zipCode: ``,
      range: 10,
      lastName: ``
    })
    if ( scrollIntoView ) {
      const doctorBlock = document.getElementById( `doctor-block` )
      if ( doctorBlock ) {
        doctorBlock.scrollIntoView({
          behavior: `smooth`
        })
      }
    }
  }, [] )

  if ( physician?.error || !physician?.data || !customerToken ) return ( <p className="text-error">
    {`There was an error retrieving your physician details... Please give customer support a call at `}
    <PhoneLink displayNumber={PHONE} textClassName="inline-block whitespace-nowrap" />
    {`.`}
  </p> )

  return (
    <div
      id="doctor-block"
      className="my-5 flex flex-col items-center justify-center max-w-2xl mx-auto gap-5"
    >
      <div className={`${!isMyAccount && `mt-5 mb-10`} w-full mx-auto`}>
        {
          <DoctorDisplay
            textContent={physician?.data?.cmsBlocks?.items?.length ? physician?.data.cmsBlocks.items[0]?.content : ``}
            isMyAccount={isMyAccount}
            forceUpdate={false}
          />
        }
      </div>
    </div>
  )
}

export default PhysicianBlock