import React, { useState } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
// import { SelectInput } from 'components/forms/components'
import { brandImages, machineImages } from 'modules/sleepNewLeads/constants'
import CheckIcon from './CheckIcon'

const groupedMachineImages = groupBy( machineImages, `brand` )

const BrandSelector = ({
  heading,
  name,
  brandNames,
  selectedValue,
  onChange,
  // defaultLabel = `Select a Machine Manufacturer`,
  showOnMobile
}) => {
  const [ errorMsg, setErrorMsg ] = useState( `` )
  const availableImages = brandImages.filter( ({ brand }) => { return ( brandNames || [] ).includes( brand ) })

  if ( !availableImages || !availableImages.length ) return null

  const handleChange = ( name, brand ) => {
    if ( groupedMachineImages[brand]?.length > 0 || brand === `Bleep LLC` ) {
      setErrorMsg( `` )
      onChange( name, brand )
    } else {
      setErrorMsg( `No machines for that brand` )
      onChange( name, `` )
    }
  }

  return (
    <>
      {showOnMobile && <div className="mt-4">
        <div className="text-center text-lg mb-6">{heading}</div>
        <div className="grid grid-cols-3 gap-3">
          {availableImages.map( ({ brand, imgSrc }) => {
            return (
              <div key={brand} className="h-28 lg:h-32 xl:h-48 flex justify-center">
                <div
                  className={`min-h-full w-28 lg:w-32 xl:w-48 cursor-pointer border flex overflow-hidden relative ${brand === selectedValue ? `border-deepSleepBlue` : `border-lightGray`}`}
                  onClick={() => { return handleChange( name, brand ) }}
                >
                  {brand === selectedValue && <CheckIcon />}
                  <img
                    src={imgSrc}
                    className="object-contain mx-auto"
                    alt={brand}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className="text-error">{errorMsg}</div>
      </div>}
    </>
  )
}

BrandSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  brandNames: PropTypes.array,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  defaultLabel: PropTypes.string,
  showOnMobile: PropTypes.bool
}

export default BrandSelector
