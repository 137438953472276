import * as React from 'react'
import { startCase } from 'lodash'
import { useAddressStore } from './state'
import useExternalSync from './hooks/useExternalSync'
import { reportToSentry } from 'utils/reportToSentry'
import { useAddressStyleStore } from './style'
import { Confirm, Edit, ErrorConfirmPrompt, SubpremisePrompt, Verify, View } from './views'


type AddressProps = {
  addressType?: `shipping` | `billing` | ``, // Note: `billing` is not currently supported, but is here for future use, ideally we would want some kind of key system, so we could have two address blocks and not have state bleed between them
  headerText?: string;
  shouldSkipMountFetch?: boolean; // If true, this will skip the internal address lookup on mount. This is useful for when we already have the address saved in state
  hideAddressConfirm?: boolean; // If true, this will hide the confirm address checkbox in the view component
  shouldSkipValidation?: boolean; // If true, this will skip the validation API,
  onSuccessfulConfirm?: () => void; // This is a callback that will be called when the address is saved
  // Note: To style any part of the address block, please check out the style.ts file. This is where you can hook in to any part of the address block and style it
}

const AddressBlock : React.FC<AddressProps> = ({
  addressType = `shipping`,
  headerText,
  onSuccessfulConfirm,
  shouldSkipMountFetch = false,
  hideAddressConfirm = false,
  shouldSkipValidation = false
}) => {

  const { currentView } = useAddressStore()

  useExternalSync({ // This will fetch the customer's address from internal
    shouldSkipMountFetch // If true, this will skip the internal address lookup on mount. This is useful for when we know a user is filling out the address block for the first time, like in SNL
  })

  const { styleOverrides } = useAddressStyleStore()

  const views = {
    'complete': <View hideAddressConfirm={hideAddressConfirm} />,
    'verify': <Verify />,
    'confirm': <Confirm onSuccessfulConfirm={onSuccessfulConfirm} />,
    'subpremise': <SubpremisePrompt />,
    'invalid': <ErrorConfirmPrompt />,
    'edit': <Edit shouldSkipValidation={shouldSkipValidation} shouldSaveOnSubmit={hideAddressConfirm} onSuccessfulConfirm={onSuccessfulConfirm} />
  }

  if ( !Object.keys( views ).includes( currentView ) ) {
    // This means a view was passed in that doesn't exist - This should only happen by dev typo/mistake
    reportToSentry( new Error( `AddressBlock2.0 - Invalid view: ${currentView}` ) )
    return <p className="text-error">{`A Dev Error Occurred`}</p>
  }

  return (
    <div
      id="address-block"
      className={styleOverrides.wrapperStyle}
    >
      <p className={styleOverrides.headerStyle}>{headerText ? headerText : `${( currentView === `complete` ) ? `Confirm ` : `Enter Your `} ${startCase( addressType )} Address`}</p>
      <div className={styleOverrides.containerStyle}>
        { views[ currentView ] }
      </div>
    </div>
  )
}

export default AddressBlock