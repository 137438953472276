import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Header = styled.p`
  font-size: 34px;
  line-height: 46px;
  font-weight: 600;
`
const Description = styled.p`
  font-size: 14px;
  line-height: 19px;
`

const PageTracker = ({title, description }) => {
  return (
    <div className="flex flex-col justify-center items-center mb-8">
      <Header>{title}</Header>
      { description && <Description className="text-graphite mt-3 text-center">{description}</Description>}
    </div>
  )
}

PageTracker.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] )
}

export default PageTracker
