import { Tile } from 'components/tile'
import React from 'react'
import { myPhysicianId } from '../constants'
import PhysicianBlock from '../components/PhysicianBlock'

export type CmsBlocksQueryResponse = {
  cmsBlocks: {
    items: {
      content: string
      identifier: string
      title: string
    }[]
  }
}

function Physician() : JSX.Element {
  return (
    <Tile headerContent="My Physician" id={myPhysicianId}>
      <PhysicianBlock isMyAccount />
    </Tile>
  )
}

export default Physician