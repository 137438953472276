import React from 'react'
import QualifyForm from 'modules/qualifyForm'
import styled from 'styled-components'
import HeroContentType from '../HeroContentType'

function HeroDiv({ heroSrc, heroContent } : { heroSrc: string, heroContent: HeroContentType }) : JSX.Element {

  const HeroDiv = styled.div`
    background: url(${heroSrc});
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    .background-cover {
      background: rgba(0, 87, 168, 0.7);
    }
    .fit {
      width: fit-content;
    }
  `

  return (
    <HeroDiv>
      <div className="background-cover w-full">
        <div className="flex flex-col items-center gap-5 lg:gap-10 py-20 px-10 mx-auto justify-center items-left">
          <h3 className="text-white justify-center text-4xl lg:text-7xl font-normal leading-snug lg:leading-tight text-center my-auto md:border-0 border-b-2 border-white pb-5">
            {heroContent?.heroHeading ?? `PAP Supplies up to 100% coverage through Medicare`}
          </h3>
          <div className="flex flex-col items-left lg:items-center justify-center">
            <p className="text-white font-light text-xl lg:text-2xl text-left lg:text-center border-0 md:border-b-2 md:border-white pb-5">{heroContent?.qualifyHeading ?? `We proudly work with Medicare plans!`}</p>
            <div className="flex-2 text-center bg-secondaryBlue-tint2 text-black flex-col flex items-center mt-5">
              <QualifyForm classNames="sm:px-6 md:px-10 pt-9" />
            </div>
          </div>
        </div>
      </div>
    </HeroDiv>
  )
}

export default HeroDiv