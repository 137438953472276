import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { parseSnowdogMenu } from 'components/megaMenu/util'
import SubmenuList from './SubmenuList'


/* If you are confused about this menu structure or naming, please reference the types outlined in ../../util.js */
const MenuItems = ({ snowdogQueryData }) => {

  const [ menuItems, setMenuItems ] = useState( [] )
  const [ activeSubmenu, setActiveSubmenu ] = useState( `` )

  useEffect( () => {
    let mounted = true

    if ( snowdogQueryData ) {
      const menuItems = parseSnowdogMenu( snowdogQueryData )

      if ( mounted ) setMenuItems( menuItems )
    }

    return () => {
      mounted = false
    }
  }, [ snowdogQueryData ] )

  const handleSetActiveSubmenu = ( menu ) => {
    setActiveSubmenu( menu )
  }

  const getActiveMenuItem = () => {
    return menuItems.find( item => { return item.title === activeSubmenu })
  }

  const activeMenuItem = getActiveMenuItem()

  return (
    <>
      {menuItems.map( submenu => {

        return (
          <SubmenuList
            url={submenu?.subMenus?.length > 0 ? null : submenu?.url}
            submenuStaticLength={submenu?.submenus?.length}
            key={submenu.title}
            title={submenu.title}
            activeSubmenu={activeSubmenu}
            setActiveSubmenu={handleSetActiveSubmenu}
            subMenus={activeMenuItem && activeMenuItem.submenus ? activeMenuItem.submenus : []}
          />
        )
      })}
    </>
  )
}
MenuItems.propTypes = {
  snowdogQueryData: PropTypes.object
}

export default MenuItems
