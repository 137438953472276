import React from 'react'
import Tooltip from 'components/tooltip'
import TermsAndConditionsTooltip from '../../tooltips/TermsAndConditionsTooltip'
import NotInterestedTooltip from '../../tooltips/NotInterestedTooltip'
import PropTypes from 'prop-types'

function Footer({aobContent, setNotInterested}) {

  const openEmail = ( e ) => {
    e.preventDefault()

    window.open( `mailto: info@aeroflowsleep.com`, `_blank` )
  }

  return (
    <>
      <div className="flex flex-col items-center max-w-md mx-auto gap-2 relative">
        <Tooltip
          id="terms-and-conditions"
          tooltipContent={<TermsAndConditionsTooltip aobContent={aobContent} />}
          offset={{
            top: 10
          }}
        >
          <p className="text-sm text-center font-light mb-10 mt-2 max-w-md">
            <span className="pointer-events-none">
              {`By continuing, I authorize Aeroflow Healthcare to contact me by email, phone, or SMS. Aeroflow will not share or distribute this information. I also agree to `}
            </span>
            <span className="a">
              {`Aeroflow Terms and Conditions.`}
            </span>
          </p>
        </Tooltip>
      </div>

      <p className="text-lg text-center mb-10 max-w-lg mx-auto">
        {`If you have questions or would like to adjust your supply order, please email us at: `}
        <a className="a" onClick={openEmail}>{`info@aeroflowsleep.com`}</a>
      </p>
      <div className="relative pb-24">
        <Tooltip
          id="not-interested"
          tooltipContent={<NotInterestedTooltip setNotInterested={setNotInterested} />}
        >
          <div className="w-1/2 mx-auto mb-28">
            <p className="text-center a text-lg max-w-lg">
              {`Not interested in receiving supplies through insurance at this time? Click here.`}
            </p>
          </div>
        </Tooltip>
      </div>
    </>
  )
}

Footer.propTypes = {
  aobContent: PropTypes.string,
  setNotInterested: PropTypes.func
}

export default Footer